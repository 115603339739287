// Header.tsx
import { CircleUser, Cpu, CreditCard, Group, LogOut, User } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@/context/AuthContext";
import Logo from "../layout/Logo";
import { useModal } from "@/context/ModalContext";
import { CreateGroupForm } from "../form/CreateGroupForm";
import getLink, { LINK_TYPE } from "@/lib/links";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "@/store/userSlice";
import { SearchAll } from "../SearchAll/SearchAll";
import LanguageSelector from "../ui/language-selector";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useCallback, useEffect } from "react";
import { useRbac } from "@/hooks/rbac";
import { Badge } from "../ui/badge";

interface HeaderProps {
  showNavMenu?: boolean;
}

export default function Header({ showNavMenu = false }: HeaderProps) {
  const { logout } = useAuthContext();
  const { showModal } = useModal();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthContext();
  const { isSuperAdmin } = useRbac();

  // SET PAGE TITLE
  const { setTitle, homeLink } = useSectionTitle();
  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  const goToTheCorrectHome = useCallback(() => {
    if (isSuperAdmin) {
      if (homeLink) {
        navigate(homeLink);
      } else {
        navigate(getLink(LINK_TYPE.ADMIN_HOME));
      }
    } else {
      navigate(getLink(LINK_TYPE.HOME));
    }
  }, [homeLink]);

  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-black px-4 md:px-6 z-10">
      <nav
        className={`flex items-center content-between w-full gap-6 text-lg font-medium md:flex md:flex-row md:gap-5 md:text-sm lg:gap-6`}
      >
        <Logo onClick={goToTheCorrectHome} />

        {isSuperAdmin && (
          <Badge
            variant="secondary"
            className="hidden md:block cursor-pointer"
            onClick={() => navigate(getLink(LINK_TYPE.ADMIN_HOME))}
          >
            superadmin
          </Badge>
        )}

        <div className="flex items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          {showNavMenu && (
            <div className="relative">
              <SearchAll />
            </div>
          )}
          <LanguageSelector></LanguageSelector>
          {isAuthenticated && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full text-white"
                >
                  <CircleUser className="h-5 w-5" />
                  <span className="sr-only">{t("nav.toggle")}</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>{user?.email}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => navigate(getLink(LINK_TYPE.ACCOUNT))}
                  className="cursor-pointer flex gap-2"
                >
                  <User size={20} /> {t("nav.account")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => navigate(getLink(LINK_TYPE.PAYMENT_METHODS))}
                  className="cursor-pointer flex gap-2"
                >
                  <CreditCard size={20} />
                  {t("nav.payment_methods")}
                </DropdownMenuItem>

                {isSuperAdmin && (
                  <DropdownMenuItem
                    className="cursor-pointer flex gap-2"
                    onClick={() => {
                      showModal(
                        <CreateGroupForm
                          onSuccess={() => {
                            navigate(getLink(LINK_TYPE.HOME));
                            window.location.reload(); // TODO find another way
                          }}
                        />,
                        {
                          title: t("group.create"),
                          description: "",
                        }
                      );
                    }}
                  >
                    <Group size={20} />
                    {t("nav.new_group")}
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  className="cursor-pointer flex gap-2"
                  onClick={() => navigate(getLink(LINK_TYPE.DEVELOPER))}
                >
                  <Cpu size={20} /> {t("nav.developer")}
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="cursor-pointer flex gap-2"
                  onClick={() => logout()}
                >
                  <LogOut size={20} />
                  {t("nav.logout")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </nav>
    </header>
  );
}
