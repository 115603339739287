import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { CalendarDays } from "lucide-react";
import { useTranslation } from "react-i18next";
import ronnyImage from "@/assets/img/accounts/ronny.jpg";

const TITLE = "analytics.contact.title";
const SUBTITLE = "analytics.contact.subtitle";
const ACCOUNT_MANAGER_NAME = "Ronny";
const EMAIL_ADDRESS = "r.penna@takyon.io";
const HUBSPOT_MEETING_URL = "https://meetings-eu1.hubspot.com/ronny-penna";

export default function SidebarContactOurExpert({}) {
  const { t } = useTranslation();

  const bookAppointment = () => {
    window.open(HUBSPOT_MEETING_URL, "_blank");
  };

  return (
    <Card className="p-5 rounded-xl">
      <div className="flex flex-col">
        <div>
          <p className="text-xl font-medium">{t(TITLE)}</p>
          <p className="text-xs text-gray-400">{t(SUBTITLE)}</p>
        </div>
        <Separator className="my-2 w-full h-[1px]" />
        <div>
          <Accordion type="single" collapsible defaultValue="item-1">
            <AccordionItem value="item-1" className="border-none">
              <AccordionTrigger className="flex gap-3 pb-0">
                <div className="flex gap-2">
                  <Avatar>
                    <AvatarImage src={ronnyImage} />
                    <AvatarFallback>RN</AvatarFallback>
                  </Avatar>
                  <div className="text-left">
                    <p className="text-base font-medium leading-5">
                      {ACCOUNT_MANAGER_NAME}
                    </p>
                    <p className="text-sm text-gray-400 leading-3">
                      {t("analytics.contact.account_manager")}
                    </p>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex gap-4 flex-col mt-4 py-0">
                <Separator className=" w-full h-[1px]" />
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">
                    {t("analytics.contact.mail")}
                  </p>
                  <p
                    className=" text-sm font-medium underline cursor-pointer"
                    onClick={() =>
                      (window.location.href = `mailto:${EMAIL_ADDRESS}`)
                    }
                  >
                    {EMAIL_ADDRESS}
                  </p>
                </div>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={bookAppointment}
                >
                  <CalendarDays className="mr-2 h-4 w-4" />
                  {t("analytics.contact.book")}
                </Button>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </Card>
  );
}
