import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import { deleteAnalysisMask } from "@/services/dataanalysismask";
import iDataAnalysisMask from "@/services/dataanalysismask";
import { useModal } from "@/context/ModalContext";
import DataAnalysisDeleteModal from "./DataAnalysisDeleteModal";

const DataAnalysisMaskCard = ({
  mask,
  load,
  setMaskData,
}: {
  mask: iDataAnalysisMask;
  load: Function;
  setMaskData: (mask: iDataAnalysisMask) => void;
}) => {
  const { showModal, hideModal } = useModal();
  const deleteMask = async (id: string) => {
    await deleteAnalysisMask({ dataAnalysisMaskId: id });
    hideModal();
    await load();
  };

  return (
    <div
      id="DataAnalysisMaskCard"
      className="flex justify-between items-center"
    >
      <div className="flex flex-col gap-4">
        <span className="font-semibold">{mask.name}</span>
        <span className="">
          Ultima modifica:{" "}
          {mask._updatedAt
            ? new Date(mask._updatedAt).toLocaleDateString()
            : ""}
        </span>
      </div>
      <div className="flex items-center gap-2">
        <Button
          className="bg-gray-800 text-white hover:text-white hover:bg-gray-500"
          onClick={() => {
            setMaskData(mask);
            hideModal();
          }}
        >
          Modifica
        </Button>
        <div
          className="border rounded-md p-3 cursor-pointer hover:bg-red-600 hover:text-white"
          onClick={() => {
            showModal(
              <DataAnalysisDeleteModal
                deleteMask={() => deleteMask(mask._id)}
                hideModal={hideModal}
              />,
              {
                title: `Sei sicuro di voler cancellare questa analysis mask?`,
                description: `La maschera "${
                  mask.name || ""
                }" verrà rimossa da tutte le collezioni a cui era stata applicata`,
              }
            );
          }}
        >
          <Trash2 className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
};

export default DataAnalysisMaskCard;
