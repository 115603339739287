import { t } from "i18next";
import { useState, useMemo } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { LANG } from "@/services/i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { createUser, iUser } from "@/services/auth";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { makeid } from "@/utils/generic";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

export default function UserForm({
  user,
  onSuccess,
}: {
  user?: iUser;
  onSuccess?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const i18langs = useMemo(() => {
    return Object.keys(LANG).map((lang) => {
      return {
        text: lang,
        value: lang,
      };
    });
  }, [LANG]);

  const formSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6).optional(),
    firstName: z.string(),
    secondName: z.string(),
    emailVerified: z.boolean().optional(),
    marketingEmails: z.boolean().optional(),
    isAdmin: z.boolean().optional(),
    isStealth: z.boolean().optional(),
    lang: z.string(),
    stripeId: z.string().optional(),
    stripeAccountDetailSubmitted: z.boolean().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lang: user?.lang,
      email: user?.email,
      isAdmin: user?.isAdmin,
      isStealth: user?.isStealth,
      firstName: user?.firstName,
      secondName: user?.secondName,
      marketingEmails: user?.marketingEmails,
      emailVerified: user?.emailVerified,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    // e.preventDefault();
    setIsLoading(true);

    try {
      // createUser is used either for creating or updating a user
      await createUser({
        ...data,
        isAdmin: data.isAdmin ? "on" : "off",
        isStealth: data.isStealth ? "on" : "off",
        marketingEmails: data.marketingEmails ? "on" : "off",
        emailVerified: data.emailVerified ? "on" : "off",
      });
      onSuccess?.();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader2 />;

  return (
    <Form {...form}>
      <form
        id="UserForm"
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t("users.email")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("users.email")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-start items-end gap-3">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  {t("users.password")} {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input placeholder={t("users.password")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            variant="takyon"
            onClick={() => {
              form.setValue("password", makeid(8));
            }}
            //   text="Random Pass"
            //   small
          >
            Random Pass
          </Button>
        </div>

        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t("users.firstName")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("users.firstName")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="secondName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t("users.secondName")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder={t("users.secondName")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="emailVerified"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormLabel>{t("users.emailVerified")}</FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  // value={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="marketingEmails"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormLabel>{t("users.marketingEmails")}</FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  // value={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="isAdmin"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormLabel>{t("users.isAdmin")}</FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  // onCheckedChange={(value) => {setMarketingEmail(value); field.onChange(value)}}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="isStealth"
          render={({ field }) => (
            <FormItem className="flex gap-4">
              <FormLabel>{t("users.isStealth")}</FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  // onCheckedChange={(value) => {setMarketingEmail(value); field.onChange(value)}}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lang"
          render={({ field }) => (
            <FormItem className="flex-grow-0">
              <FormLabel>{t("nft.lang")}</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("form.select")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {i18langs.map((lang) => (
                    <SelectItem key={lang.value} value={lang.value}>
                      {lang.text}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="stripeId"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>
                {t("users.stripeId")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder="" {...field} value={user?.stripeId} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="stripeAccountDetailSubmitted"
          render={({ field }) => (
            <FormItem className="flex items-end gap-4">
              <FormLabel>{t("users.stripeAccountDetailSubmitted")}</FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  // onCheckedChange={(value) => {setMarketingEmail(value); field.onChange(value)}}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between flex-row-reverse gap-4">
          <LoadingSubmitButton
            type="submit"
            isLoading={isLoading}
            variant="takyon"
          >
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
