import { use } from "i18next";
import { useEffect, useState } from "react";

export default function ImagePreview({
  imageSrc,
  isSelected = false,
  deletingAction = false,
  onSelect,
}: {
  imageSrc: string;
  isSelected?: boolean;
  deletingAction?: boolean;
  onSelect?: (imageUrl: string) => void;
}) {
  const handleOnSelectedImage = (imageUrl: string) => {
    console.log(
      "ImagePreview handleOnSelectedImage - new image selected",
      imageUrl
    );
    onSelect?.(imageUrl);
  };

  return (
    <div key={"img_" + Math.random().toString()} className="img-preview mx-2">
      <input readOnly type="text" hidden value={imageSrc} name="images[]" />
      {deletingAction === true && (
        <div
          onClick={() => {
            console.log("image clicked imageSrc", imageSrc);
          }}
          className="flex delete cursor-pointer justify-end"
        >
          <span className="m-0 flex justify-center items-center w-7 h-7 border-r-gray-200 rounded-full pb-1.5 rotate-45 text-2xl border bg-white -mr-3.5 -mb-4">
            +
          </span>
        </div>
      )}
      <img
        src={imageSrc}
        // style={{
        //   backgroundImage: `url(${i})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center center",
        // }}
        className={`image cursor-pointer rounded-md w-24 mb-4 min-w-24 ${
          isSelected && "outline outline-4 outline-primary outline-offset-2"
        }`}
        onClick={() => {
          console.log("image clicked imageSrc", imageSrc);
          handleOnSelectedImage(imageSrc);
        }}
      />
    </div>
  );
}
