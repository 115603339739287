import React, { useEffect } from "react";
import { useModal } from "@/context/ModalContext";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
const Modal: React.FC = () => {
  const { modalContent, isModalOpen, hideModal, header } = useModal();
  // force remove pointer-events prop on body when modal is closed
  // for the case when modal is open from dropdownlist
  // that cause a race condition with the modal on setting pointer-events
  useEffect(() => {
    if (!isModalOpen) {
      document.body.style.removeProperty("pointer-events");
    }
  }, [isModalOpen]);
  if (!isModalOpen) return null;
  return (
    <Dialog open={isModalOpen} onOpenChange={hideModal}>
      {/* <DialogTrigger>Open</DialogTrigger> */}
      <DialogContent>
        {header && (
          <DialogHeader>
            {header.title && <DialogTitle>{header.title}</DialogTitle>}
            {header.description && (
              <DialogDescription>{header.description}</DialogDescription>
            )}
          </DialogHeader>
        )}
        <div className="max-h-[80vh] overflow-y-auto ps-1 pe-3">
          {modalContent}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default Modal;
