import { useAuthContext } from "@/context/AuthContext";
import getLink, { LINK_TYPE } from "@/lib/links";
import { selectUser } from "@/store/userSlice";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

interface AuthRouteProps {
  children: React.ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const user = useSelector(selectUser);
  const { isAuthenticated } = useAuthContext();
  const { pathname } = useLocation();

  if (isAuthenticated === undefined) return null;

  if (
    isAuthenticated &&
    user &&
    !user.emailVerified &&
    pathname !== getLink(LINK_TYPE.VERIFY_EMAIL)
  ) {
    console.log("Email is not verified, redirecting to verify email");
    return <Navigate to={getLink(LINK_TYPE.VERIFY_EMAIL)} />;
  }

  return <>{children}</>;
};

export default AuthRoute;
