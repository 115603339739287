import axios from "axios";
import { LANG } from "./i18next";
import { Pagination } from "../utils/Pagination";
import { hotelCensus, iCollection } from "./collection";
// import { LocationType } from "@googlemaps/google-maps-services-js";

export enum NFT_BLOCKCHAIN_STATUS {
  TO_MINT = "TO_MINT",
  MINTING = "MINTING",
  MINTED = "MINTED",

  TO_BURN = "TO_BURN",
  BURNING = "BURNING",
  BURNED = "BURNED",

  TO_LOCK = "TO_LOCK",
  LOCKING = "LOCKING",
  LOCKED = "LOCKED",

  TO_UNLOCK = "TO_UNLOCK",
  UNLOCKING = "UNLOCKING",
  UNLOCKED = "MINTED", // use MINTED
}

export enum NFT_ACCESS_STATUS {
  REDEEMED = "REDEMEED",
  OPEN = "OPEN",
}

export enum NFT_SELL_STATUS {
  NOT_FOR_SALE = "NOT_FOR_SALE",
  FOR_SALE = "FOR_SALE",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PENDING_SALE = "PENDING_SALE", // we check if the reservation is ok, if so, we put it for sale
}

export enum NFT_OWNER_TYPE {
  ORIGINAL = "ORIGINAL",
  RESOLD = "RESOLD",
}

type Guest = {
  firstName?: string;
  secondName?: string;
  email?: string;
};

type BlockchainTransaction = {
  type: "mint" | "lock" | "burn" | "unlock";
  id: string;
  date: Date;
};

export type payloadHotelType = {
  type?: string;

  checkin?: string;
  checkout?: string;
  reference?: string;
  board?: string;
  extra?: string;

  rooms?: Array<{
    name?: string;
    guestsAdults?: number;
    guestsKids?: number;
    amenities?: string;
  }>;

  note?: string;
  hotel?: string;
  stars?: string;
  website?: string;
  email?: string;

  location?: {
    label: string;
    value?: object;
    address_components?: object;
    coordinates?: object;
    gmt?: any;
  };
  fakeOwnerName?: string;
};

export type payloadExperienceType = {
  type?: string;

  reference?: string;
  category?: string;

  date?: string;
  duration?: number;
  participants?: number;

  description?: string;

  tour?: {
    step?: number;
    name?: string;
    location?: {
      label: string;
      value?: object;
      address_components?: object;
      coordinates?: object;
      gmt?: any;
    };
    date?: string;
  }[];

  hostName?: string;
  hostDescription?: string;

  extra?: string;
  fakeOwnerName?: string;
};

export interface iNft<
  nftPayloadType extends payloadHotelType | payloadExperienceType = any
> {
  shortId?: string;
  _id?: string;

  realCreationDate?: string;

  payload: nftPayloadType;
  owner: string; // email of owner

  lang?: LANG | string;
  collectionId: string;

  sellStatus?: NFT_SELL_STATUS;
  accessStatus?: NFT_ACCESS_STATUS;
  ownerType?: NFT_OWNER_TYPE;

  lockDate: string;

  images?: Array<string>;

  originalPrice: number;
  currentPrice?: number;
  marketPrice?: number;

  guests?: Array<Guest>;

  gold?: boolean;

  // web3
  blockchainStatus?: NFT_BLOCKCHAIN_STATUS;
  contractAddress?: string;
  solidityId?: number;
  blockchainTransactions?: Array<BlockchainTransaction>;
  errors?: Array<any>;

  _createdAt: any;

  _owner?: {
    phone?: string;
    phonePrefix?: string;
    firstName?: string;
    secondName?: string;
  };

  _collectionCensus?: hotelCensus;
  _ownerName?: string;
  _fake?: boolean;
  fakeOwnerName?: string;
  _collection?: iCollection; // only admin/nfts page
}

export enum BID_STATUS {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
  FULFILLED = "FULFILLED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
}

export enum BID_MODE {
  MONEY = "MONEY",
  CREDITS = "CREDITS",
}

export const START_BID_HOURS_BEFORE_NFT_EXPIRE_DEFAULT = 24; // 24 hours
export const END_BID_HOURS_BEFORE_NFT_EXPIRE_DEFAULT = 12; // 12 hours

export interface iBid<
  nftPayloadType extends payloadHotelType | payloadExperienceType = any
> {
  _id: string;

  nftId: string;
  amount: number;

  sellerEmail: string;
  buyerEmail: string;

  expireDate: Date;

  status: BID_STATUS;

  mode?: BID_MODE;

  _nft: iNft<nftPayloadType>;
}

export interface loadBidsProps {
  page?: number;
  size?: number;
  collectionId?: string;
}

export async function createNft(payload: any): Promise<iNft> {
  const response = await axios.post("/nft", payload);
  return response.data;
}

export async function patchNft(payload: any): Promise<iNft> {
  const response = await axios.patch("/nft", payload);
  return response.data;
}

export async function getNftsOfCollectionPaginated({
  collectionId = "",
  page = 1,
  size = 25,
  query = {},
}): Promise<Pagination<iNft>> {
  let url = `/nft/paginate?page=${page}&size=${size}&collectionId=${collectionId}&cache=false`;
  if (query) url += `&query=${JSON.stringify(query)}`;

  const response = await axios.get(url);
  return response.data;
}

export async function getExport({
  start,
  end,
  collectionId,
}: {
  start: string;
  end: string;
  collectionId: string;
}): Promise<iNft[]> {
  let url = `/nft/export?collectionId=${collectionId}&start=${start}&end=${end}`;

  const response = await axios.get(url);
  return response.data;
}

export async function getNft(id: string) {
  const response = await axios.get("/nft/" + id);
  return response.data;
}

export async function deleteNft(payload: any) {
  const response = await axios.delete("/nft", { data: payload });
  return response.data;
}

export async function buyback(payload: { nftId: string; delete?: boolean }) {
  const response = await axios.post("/nft/buyback", payload);
  return response.data;
}

export async function dropshipBuy(payload: {
  dropshipId: string;
  nftId: string;
  newOwner: string;
}) {
  const response = await axios.post("/nft/buyback", payload);
  return response.data;
}

export async function buybackOffer(payload: {
  nftId: string;
  amount: number;
  expireDate: Date;
  delete?: boolean;
}) {
  const response = await axios.post("/nft/bid/buyback", payload);
  return response.data;
}

export async function getBidsPaginated({
  page = 1,
  size = 25,
  collectionId = "",
}): Promise<Pagination<iBid>> {
  const response = await axios.get(
    `/nft/bid/buyback/paginate?page=${page}&size=${size}&collectionId=${collectionId}`
  );
  return response.data;
}

export async function cancelBid(payload: any): Promise<iBid> {
  const response = await axios.delete("/nft/bid/buyback", { data: payload });
  return response.data;
}

export async function getNftsPaginated({
  page = 1,
  size = 25,
  query = {},
  sort = {},
  cache = true,
  onlyMinted = false,
}): Promise<Pagination<iNft>> {
  const url = `/nft/paginate`;

  const params: any = {
    query: JSON.stringify(query),
    cache,
    onlyMinted,
    page,
    size,
  };

  if (Object.keys(sort).length) {
    params.sort = JSON.stringify(sort);
  }

  const response = await axios.get(url, {
    params: params,
  });

  return response.data;
}

export async function putNftForSalePending(nftId: string): Promise<iNft> {
  const response = await axios.post("/nft/sale/pending", { nftId });
  return response.data;
}

export async function putNftForSale(payload: {
  nftId: string;
  price: number;
}): Promise<iNft> {
  const response = await axios.post("/nft/sale", payload);
  return response.data;
}

export async function changeNftPrice(payload: {
  nftId: string;
  price: number;
}): Promise<iNft> {
  const response = await axios.post("/nft/change-price", payload);
  return response.data;
}

export async function removeNftFromSale(payload: {
  nftId: string;
}): Promise<iNft> {
  const response = await axios.delete("/nft/sale", { data: payload });
  return response.data;
}
