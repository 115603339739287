import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createGroup } from "@/services/groups";
import { useModal } from "@/context/ModalContext";

export function CreateGroupForm({ onSuccess }: { onSuccess(): void }) {
  const { hideModal } = useModal();
  const { t } = useTranslation(); // For translations, if applicable
  const [isLoading, setIsLoading] = useState(false);

  // 1. Define the Zod schema for form validation
  const formSchema = z.object({
    groupName: z.string().min(1, t("group.create_name_required")),
  });

  // 2. Initialize form using `useForm` and zod validation
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      groupName: "",
    },
  });

  // 3. Handle form submission
  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    try {
      await createGroup({ name: values.groupName });
      onSuccess();
      hideModal();
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <div className="grid gap-2">
            {/* Group Name Field */}
            <FormField
              control={form.control}
              name="groupName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("group.create_name")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("group.create_nameplaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <Button variant={"takyon"} type="submit" disabled={isLoading}>
              {isLoading ? t("buttons.loading") : t("buttons.create")}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
