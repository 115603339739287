import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./hotels-integrated-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";

import {
  getHotelPaginated,
  iHotelIntegrationConfig,
} from "@/services/hotelregistration";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import HotelIntegrationForm from "./forms/HotelIntegrationForm";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function HotelIntegrationDatatable({}: {}) {
  // const { collectionId } = useParams<{ collectionId: string }>();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("hotelIntegration.page_title")}`);
  }, [setTitle]);

  const { showModal, hideModal } = useModal();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iHotelIntegrationConfig[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      /*

      // let textQ = {};
      // if (searchTerm) {
      //   textQ = {
      //     $or: [
      //       { _id: { $regex: searchTerm, $options: "i" } },
      //       { shortId: { $regex: searchTerm, $options: "i" } },
      //       { owner: { $regex: searchTerm, $options: "i" } },
      //       { "payload.reference": { $regex: searchTerm, $options: "i" } },
      //     ],
      //   };
      // }

      const payload = {
        page: page + 1,
        size: pageSize,
        query: {},
      };
      */

      const result = await getHotelPaginated();

      const pageCount = Math.ceil(result.total / pageSize);
      setPageCount(pageCount);
      setData(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sorting, searchTerm]);

  useEffect(() => {
    console.log("fetchData hook start");
    fetchData();
    console.log("fetchData hook end");
  }, [fetchData]);

  const columns = useMemo(() => getColumns(t, fetchData), [t, fetchData]);

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={false}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
        actions={[
          <Button
            onClick={() =>
              showModal(<HotelIntegrationForm onSuccess={hideModal} />, {
                title: t("hotelIntegration.add_hotel"),
                description: t("hotelIntegration.add_hotel_description"),
              })
            }
            variant={"takyon"}
            className="gap-2"
          >
            {t("hotelIntegration.add_hotel")}
          </Button>,
        ]}
      />
    </div>
  );
}
