import { Info, Star } from "lucide-react";
import { useTranslation } from "react-i18next";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";

type StarRatingProps = {
  rating: number; // Example: 4.6
  maxStars?: number; // Default: 5
};

const StarRating: React.FC<StarRatingProps> = ({ rating, maxStars = 5 }) => {
  const fullStars = Math.floor(rating); // Number of fully filled stars
  const hasHalfStar = rating % 1 !== 0; // Check if there's a fractional part
  const emptyStars = maxStars - Math.ceil(rating); // Remaining empty stars
  const starSize = 40;

  return (
    <div className="flex">
      {/* Render full stars */}
      {Array.from({ length: fullStars }).map((_, index) => (
        <Star
          key={`full-${index}`}
          className="text-takyon"
          fill="#43F3AE"
          stroke="none"
          size={starSize}
        />
      ))}

      {/* Render half star */}
      {hasHalfStar && (
        <div
          style={{
            position: "relative",
            width: `${starSize}px`,
            height: `${starSize}px`,
          }}
        >
          <Star
            className="text-white"
            fill="white"
            stroke="none"
            size={starSize}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: `${(rating % 1) * 100}%`, // Percentage fill based on fractional part
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Star
              className="text-white"
              fill="white"
              stroke="none"
              size={starSize}
            />
          </div>
        </div>
      )}

      {/* Render empty stars */}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <Star
          key={`empty-${index}`}
          className="text-white"
          fill="white"
          stroke="none"
          size={starSize}
        />
      ))}
    </div>
  );
};

export default function AppreciationStars({
  value,
  delta,
}: {
  value: string;
  delta: string;
}) {
  const { t } = useTranslation();

  const title = t("analytics.appreciations.title");
  const valueMessage = `${value}`;
  // const deltaMessage = `+ ${delta}% ${t(
  //   "analytics.appreciations.delta_message"
  // )}`;

  return (
    <div className="w-[calc(50%-0.5rem)] h-40 flex flex-col justify-between bg-black p-5 rounded-xl">
      <div className="flex items-center justify-start gap-1 text-white w-full">
        <p className="text-xl font-normal">{title}</p>
        <Popover>
          <PopoverTrigger asChild>
            <Info className="cursor-pointer" size={16} />
          </PopoverTrigger>
          <PopoverContent className="w-80">
            <p>{t("analytics.appreciations.popover")}</p>
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex justify-between">
        <div className="w-1/2"></div>
        <div className="flex w-1/2 relative justify-end">
          <div className="flex flex-col items-center justify-between relative gap-1">
            <div className="text-5xl leading-none text-white text-center">
              {valueMessage}
            </div>
            <div className="">
              <StarRating rating={Number(value)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
