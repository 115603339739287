import { Progress } from "@/components/ui/progress";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function InfoStepper({ loading }: { loading: boolean }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  const [backBtn, setBackBtn] = useState(false);
  const [titleCollectionPage, setTitleCollectionPage] = useState(
    "Completa le informazioni"
  );
  const [infoTip, setInfoTip] = useState("info.info_tip");
  const infoSteps = [
    {
      title: "Dettagli",
      url: "/details",
    },
    {
      title: "Prezzi",
      url: "/prices",
    },
    {
      title: "Immagini",
      url: "/images",
    },
    {
      title: "Team",
      url: "/team",
    },
    {
      title: "Metodi di pagamento",
      url: "/payments",
    },
  ];

  const onBack = () => {
    const currentStep = infoSteps.findIndex(
      (step) => step.url === window.location.pathname
    );
    const previousStep = infoSteps[currentStep - 1];
    if (previousStep) {
      navigate(previousStep.url);
    }
  };

  const formRef = useRef();

  const onContinueHandler = () => {
    console.log("onContinueHandler...");
    const currentStep = infoSteps.findIndex(
      (step) => step.url === window.location.pathname
    );
    const nextStep = infoSteps[currentStep + 1];
    if (nextStep) {
      navigate(nextStep.url);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl">{titleCollectionPage}</h1>
      <p>{t(infoTip)}</p>
      <Progress value={progress} />
      <Outlet
        context={{
          onContinueHandler,
          formRef,
          setBackBtn,
          setProgress,
          setTitleCollectionPage,
          setInfoTip,
        }}
      />
      {/* <div className="flex">
        <Button onClick={onBack}>Indietro</Button>
        <Button onClick={handleNextClick}>Salva e continua</Button>
      </div> */}
    </div>
  );
}
