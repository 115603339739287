import { Group, Hotel, Search } from "lucide-react";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { useEffect, useRef, useState } from "react";
import { Input } from "../ui/input";
import { getGroups, iGroup } from "@/services/groups"; // Importing types
import { getCollections, iCollection } from "@/services/collection"; // Importing types
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import getLink, { getDefaultLink, LINK_TYPE } from "@/lib/links";
import { debounce } from "lodash";

export function SearchAll() {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(""); // To capture user input
  const [groupResults, setGroupResults] = useState<iGroup[]>([]); // To store group search results
  const [collectionResults, setCollectionResults] = useState<iCollection[]>([]); // To store collection search results

  const [isLoadingGroup, setIsLoadingGroup] = useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  // Handle the keyboard shortcut (Ctrl + S or Cmd + S)
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "s" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  // Function to search groups
  const searchGroups = async (input: string) => {
    setIsLoadingGroup(true);
    try {
      const groups = await getGroups({
        page: 1,
        size: 500,
        query: JSON.stringify({
          $or: [
            { _id: { $regex: input, $options: "i" } },
            { name: { $regex: input, $options: "i" } },
          ],
        }),
      });
      setGroupResults(groups.data);
    } catch (error) {
      console.error("Failed to search groups", error);
    }
    setIsLoadingGroup(false);
  };

  // Function to search collections
  const searchCollections = async (input: string) => {
    setIsLoadingCollections(true);
    try {
      const collections = await getCollections({
        page: 1,
        size: 500,
        query: JSON.stringify({
          $or: [
            { _id: { $regex: input, $options: "i" } },
            { name: { $regex: input, $options: "i" } },
          ],
        }),
      });
      setCollectionResults(collections.data);
    } catch (error) {
      console.error("Failed to search collections", error);
    }
    setIsLoadingCollections(false);
  };

  const searchCallbackDebounced = useRef(
    debounce((value) => {
      if (value && value.length >= 3) {
        searchGroups(value); // Trigger group search
        searchCollections(value); // Trigger collection search
      }
    }, 1000)
  );

  // Handle input change, trigger both searches
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInput(value);
    setIsLoadingGroup(true);
    setIsLoadingCollections(true);
    setGroupResults([]); // Clear results if input is cleared
    setCollectionResults([]); // Clear results if input is cleared

    searchCallbackDebounced.current(value);
  };

  return (
    <>
      <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        onClick={() => setOpen(true)}
        onChange={() => {}} // Capture input changes
        value={""}
        type="search"
        placeholder={t("search.placeholder")}
        className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
      />
      <CommandDialog open={open} onOpenChange={setOpen}>
        <Input
          className="h-12"
          value={input}
          onChange={handleInputChange}
          placeholder={t("search.placeholder")}
        />
        <CommandList>
          {input &&
            input.length >= 3 &&
            !isLoadingGroup &&
            !groupResults.length &&
            !isLoadingCollections &&
            !collectionResults.length && (
              <CommandEmpty>{t("search.no_results")}</CommandEmpty>
            )}

          {input &&
            input.length >= 3 &&
            (isLoadingGroup || isLoadingCollections) && (
              <CommandEmpty>{t("search.loading")}</CommandEmpty>
            )}

          {groupResults.length > 0 && (
            <>
              <CommandGroup heading={t("search.groups_heading")}>
                {groupResults.map((group) => (
                  <CommandItem className="cursor-pointer" key={group._id}>
                    <Group className="mr-2 h-4 w-4" />
                    <span
                      className="w-full"
                      onClick={() => {
                        setOpen(false);
                        navigate(
                          getLink(LINK_TYPE.GROUP, {
                            __GROUPID__: group._id,
                          })
                        );
                      }}
                    >
                      {group.name}
                    </span>
                  </CommandItem>
                ))}

                {isLoadingGroup && (
                  <CommandEmpty>{t("search.loading")}</CommandEmpty>
                )}
              </CommandGroup>
            </>
          )}

          <CommandSeparator />

          {collectionResults.length > 0 && (
            <>
              <CommandGroup heading={t("search.collections_heading")}>
                {collectionResults.map((collection) => (
                  <CommandItem className="cursor-pointer" key={collection._id}>
                    <Hotel className="mr-2 h-4 w-4" />
                    <span
                      className="w-full"
                      onClick={() => {
                        setOpen(false);
                        const link = getDefaultLink(collection);
                        const encodedLink = encodeURIComponent(link);
                        document.cookie = `lastVisitedCollection=${encodedLink}; path=/; max-age=86400`;
                        navigate(link);
                      }}
                    >
                      {collection.name}
                    </span>
                  </CommandItem>
                ))}

                {isLoadingCollections && (
                  <CommandEmpty>{t("search.loading")}</CommandEmpty>
                )}
              </CommandGroup>
            </>
          )}
        </CommandList>
      </CommandDialog>
    </>
  );
}
