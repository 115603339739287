import React from "react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { Button, ButtonProps } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

interface TooltipButtonProps extends ButtonProps {
  tooltipMessage: string;
}

const TooltipButton: React.FC<TooltipButtonProps> = ({
  tooltipMessage,
  disabled,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            {...props}
            className={cn(
              `${disabled ? "cursor-not-allowed" : ""}`,
              props.className
            )}
            onClick={(ev) => {
              if (disabled) {
                return;
              }
              props.onClick?.(ev);
            }}
          >
            {children}
          </Button>
        </TooltipTrigger>
        {disabled && (
          <TooltipContent>
            <p>{tooltipMessage}</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
};

export default TooltipButton;
