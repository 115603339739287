import {
  iNft,
  NFT_SELL_STATUS,
  putNftForSale,
  putNftForSalePending,
} from "@/services/nft";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { useTranslation } from "react-i18next";
import { iCollection } from "@/services/collection";
import { Button } from "../ui/button";
import { useState } from "react";
import { useModal } from "@/context/ModalContext";

const putOnsaleSchema = z.object({
  sellPrice: z
    .string()
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "Price must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, { message: "Price must be non-negative" }), // Additional validation
});

export default function PutOnSaleForm({
  nft,
  collection,
  onSuccess,
}: {
  nft: iNft;
  collection: iCollection;
  onSuccess(): void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const form = useForm<z.infer<typeof putOnsaleSchema>>({
    resolver: zodResolver(putOnsaleSchema),
  });

  const { t } = useTranslation();

  const onSubmit = async (data: z.infer<typeof putOnsaleSchema>) => {
    setIsLoading(true);
    try {
      const resNft = await putNftForSale({
        nftId: nft._id!,
        price: data.sellPrice,
      });

      if (resNft.sellStatus === NFT_SELL_STATUS.PENDING_SALE) {
        await putNftForSalePending(resNft._id!);
      }

      onSuccess();
      hideModal();
    } catch (error) {
      console.error("Error putting NFT on sale", error);
    }
    setIsLoading(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="sellPrice"
          render={({ field }) => (
            <FormItem className="flex items-center justify-between">
              <FormLabel className="">{t("nft.put_on_sale_label")}</FormLabel>
              <div className="w-1/3">
                <FormControl>
                  <Input className="text-right" {...field} />
                </FormControl>
                <FormDescription></FormDescription>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <div className="flex mt-10 flex-row-reverse">
          <Button disabled={isLoading} variant={"takyon"} type="submit">
            {t("nft.put_on_sale_confirm")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
