import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "@/components/ui/input";

import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../ui/button";
import { DialogClose } from "../ui/dialog";
import { COLLECTION_TYPE, iCollection } from "@/services/collection";
import { useEffect, useMemo, useState } from "react";
import { Separator } from "../ui/separator";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { LargeText } from "../typography/Typography";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { t } from "i18next";
import FormNft from "./FormNft";
import FormExperience from "./FormExperience";
import { useFeedback } from "@/context/FeedbackContext";
import {
  FormSchemaExperience,
  FormSchemaHotel,
} from "./forms/validationSchema";
import {
  createNft,
  iNft,
  patchNft,
  payloadExperienceType,
  payloadHotelType,
} from "@/services/nft";
import { LANG } from "@/services/i18next";
import { prepareLockDate } from "@/utils/nft";

const fakeSupplySchema = z
  .object({
    type_owner: z.enum(["private", "hotel"]),
    private_email: z.string().optional(),
    hotel_email: z.string().optional(),
    fakeOwnerName: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.type_owner === "private") {
        return !!data.private_email;
      } else if (data.type_owner === "hotel") {
        return !!data.hotel_email;
      }
      return true;
    },
    {
      message: "Invalid contact information based on type_owner",
      path: ["private_contact", "hotel_contact"],
    }
  )
  .refine(
    (data) => {
      // is filled manually
      if (data.private_email) {
        return z.string().email().safeParse(data.private_email).success;
      }
      return true;
    },
    {
      message: "Invalid email format",
      path: ["private_email"],
    }
  );

const _FakeSupplyOwnerForm = ({
  submit,
  collection,
}: {
  submit: (data: z.infer<typeof fakeSupplySchema>) => void;
  collection: iCollection;
}) => {
  const form = useForm<z.infer<typeof fakeSupplySchema>>({
    resolver: zodResolver(fakeSupplySchema),
    defaultValues: {
      type_owner: "private",
      private_email: "",
      hotel_email: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const typeOwner = form.watch("type_owner"); // Monitor the value of type_owner

  useEffect(() => {
    form.reset({
      type_owner: typeOwner,
      private_email: "",
      hotel_email: "",
    });
  }, [typeOwner, form]);

  const onSubmit = (data: z.infer<typeof fakeSupplySchema>) => {
    console.log("handleSubmit - _FakeSupplyOwnerForm - data : ", data);

    setIsSubmitting(true);
    submit(data);
  };

  const teamEmails = useMemo(
    () => collection.members.map((m) => m.email),
    [collection.members]
  );

  const isPaymentValid = collection.sellerPaymentStatus;

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
          <LargeText>{t("nft.fakesupply_title")}</LargeText>
          <p className="text-sm text-muted-foreground">
            {t("nft.fakesupply_description")}
          </p>
          <div className="pt-8">
            <FormField
              control={form.control}
              name="type_owner"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("nft.fakesupply_owner_type")} {t("form.required")}
                  </FormLabel>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-col space-y-1"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="private" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t("nft.fakesupply_owner_private")}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem
                          disabled={!isPaymentValid}
                          value="hotel"
                        />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t("nft.fakesupply_owner_hotel")}{" "}
                        {!isPaymentValid && (
                          <span className="text-gray-500 mt-3 italic text-xs">
                            - {t("nft.fakesupply_payment_disabled")}
                          </span>
                        )}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Separator className="my-5" />

          {/*  fakesupply - cliente */}
          {typeOwner === "private" && (
            <FormField
              control={form.control}
              name="private_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("nft.fakesupply_private_email")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("form.email")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          {/*  fakesupply - hotel */}
          {typeOwner === "hotel" && (
            <>
              <FormField
                control={form.control}
                name="hotel_email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("nft.fakesupply_hotel_email")}</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t("form.select")} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {
                          /* TODO - load contact from ??? */
                          teamEmails.map((contact) => (
                            <SelectItem key={contact} value={contact}>
                              {contact}
                            </SelectItem>
                          ))
                        }
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="fakeOwnerName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("nft.fakeOwnerName")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("nft.fakeOwnerName_placeholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}

          <div className="flex mt-10 justify-between">
            <DialogClose asChild>
              <Button type="button" variant="outline">
                {t("buttons.close")}
              </Button>
            </DialogClose>
            <LoadingSubmitButton
              type="submit"
              isLoading={isSubmitting}
              variant="takyon"
            >
              {t("buttons.next")}
            </LoadingSubmitButton>
          </div>
        </form>
      </Form>
    </div>
  );
};

// const combinedSchema = z.union([fakeSupplySchema, formHotelSchema]);

export default function FormCreateNftWrapper({
  collection,
  onSuccess,
}: {
  collection: iCollection;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();

  const { showFeedback } = useFeedback();

  const [step, setStep] = useState<"nft" | "ownership">("ownership");

  const [formOwnershipData, setFormOwnershipData] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOwnershipSubmit = (data: z.infer<typeof fakeSupplySchema>) => {
    setFormOwnershipData({
      ...data,
      owner:
        data.type_owner === "private" ? data.private_email : data.hotel_email,
      gold: data.type_owner === "hotel",
    });
    setStep("nft");
  };

  const handleFormNftSubmit = async (
    data: FormSchemaHotel | FormSchemaExperience
  ) => {
    const combinedData = {
      ...formOwnershipData,
      ...data,
    };

    if (collection.type === COLLECTION_TYPE.experience) {
      await handleFormExperienceSubmit(combinedData as FormSchemaExperience);
    } else {
      await handleFormHotelSubmit(combinedData as FormSchemaHotel);
    }
  };

  const handleFormExperienceSubmit = async (data: FormSchemaExperience) => {
    if (!collection) {
      return;
    }

    setIsSubmitting(true);

    // map form field to iNft
    const nft: iNft<payloadExperienceType> = {
      collectionId: collection._id,
      gold: data.gold,
      _createdAt: data._createdAt,
      owner: data.owner!,
      lang: data.lang as LANG,
      originalPrice: data.originalPrice,
      // price: data.price,
      lockDate: prepareLockDate(
        data.lockDate,
        collection.census?.location?.gmt
      ),
      images: data.images,
      payload: {
        reference: data.reference,
        date: data.start_date.toISOString(), // TODO - will be moved to nft root?
        participants: Number(data.partecipants),
        duration: Number(data.duration),
        type: data.type,
        category: data.category,
        extra: data.extra,
      },
      fakeOwnerName: data.fakeOwnerName || "",
    };

    try {
      await createNft(nft);
      showFeedback({
        title: t("nft.create_feedback_title"),
        message: t("nft.create_feedback_message"),
        onClose: onSuccess,
      });

      // await refreshStoredCollection(collection._id);
      // navigate("/collection/" + collection._id);
    } catch (error: Error | any) {
      // TODO  - improve error handlin and translations
      showFeedback({
        title: t("error.tryagain"),
        message: error.response.data.message,
      });
    }

    setIsSubmitting(false);
  };

  const handleFormHotelSubmit = async (data: FormSchemaHotel) => {
    if (!collection) {
      showFeedback({
        title: t("error.error"),
        message: t("error.collection_not_found"),
      });
      return;
    }

    setIsSubmitting(true);

    // map form field to iNft
    const nft: iNft<payloadHotelType> = {
      collectionId: collection._id,
      gold: data.gold,
      _createdAt: data._createdAt,
      owner: data.owner!,
      lang: data.lang as LANG,
      originalPrice: data.price,
      lockDate: prepareLockDate(
        data.lockDate,
        collection.census?.location?.gmt
      ),
      images: data.images,
      payload: {
        reference: data.reference,
        checkin: data.checkin.from.toISOString(), // old version: dateToGMT(e,collection.census?.location?.gmt).toISOString();
        checkout: data.checkin.to.toISOString(),
        board: data.board,
        rooms: data.rooms.map((room) => ({
          name: room.name,
          guestsAdults: Number(room.guestsAdults),
          guestsKids: Number(room.guestsKids),
        })),
        extra: data.extra,
      },
      fakeOwnerName: data.fakeOwnerName || "",
    };

    try {
      await createNft(nft);
      showFeedback({
        title: t("nft.create_feedback_title"),
        message: t("nft.create_feedback_message"),
        onClose: onSuccess,
      });

      // await refreshStoredCollection(collection._id);
      // navigate("/collection/" + collection._id);
    } catch (error: Error | any) {
      // TODO  - improve error handlin and translations
      showFeedback({
        title: t("error.tryagain"),
        message: error.response.data.message,
      });
    }

    setIsSubmitting(false);
  };

  if (step === "ownership")
    return (
      <_FakeSupplyOwnerForm
        collection={collection}
        submit={handleOwnershipSubmit}
      />
    );

  if (step === "nft") {
    if (collection.type === COLLECTION_TYPE.experience)
      return (
        <FormExperience
          collection={collection}
          onSubmit={handleFormNftSubmit}
          submitBtnLabel={t("buttons.next")}
        />
      );

    if (collection.type === COLLECTION_TYPE.hotel || !collection.type)
      return (
        <FormNft
          collection={collection}
          onSubmit={handleFormNftSubmit}
          submitBtnLabel={t("buttons.next")}
        />
      );
  }

  return <></>;
}
