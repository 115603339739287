import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "@/context/ModalContext";
import { DatePicker } from "@/components/form_fields/DatePicker";
import { createSingleSend } from "@/services/emails";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

export default function SingleSend() {
  const [isLoading, setIsLoading] = useState(false);

  const { hideModal } = useModal();

  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string().min(1),
    date: z.date().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    const { name, date } = data;
    try {
      await createSingleSend({ name, date });
      hideModal();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("emails.name")} *</FormLabel>
                <FormControl>
                  <Input placeholder={t("emails.placeholder")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <label htmlFor="">Date</label> */}
          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t("emails.single_send.date")}</FormLabel>
                <DatePicker {...field} />
                <FormMessage />
              </FormItem>
            )}
          />

          <LoadingSubmitButton variant="takyon" isLoading={isLoading}>
            {t("emails.single_send.button")}
          </LoadingSubmitButton>
        </form>
      </Form>
    </div>
  );
}
