import { useEffect, useState } from "react";
import ImageUploader from "./ImageUploader";
import ImagePreview from "../images/ImagePreview";
import { useTranslation } from "react-i18next";
import Upload from "./Upload";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { use } from "i18next";
import { Skeleton } from "../ui/skeleton";

export default function SelectOrUpdateImages({
  existingImages,
  onSelectedImages,
}: {
  existingImages: string[];
  onSelectedImages: (selectedImages: string[]) => void;
}) {
  const { t } = useTranslation();
  const [images, setImages] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setImages([...existingImages]);
  }, [existingImages]);

  useEffect(() => {
    onSelectedImages(images);
  }, [images]);

  const onNewImagesUploaded = (uploadedImages: string[]) => {
    const newImages = [...images, ...uploadedImages];
    setImages(newImages);
  };

  const handleOnSelectedImage = (imageUrl: string) => {
    setImages((imgs) => {
      const newImgs = imgs.filter((img) => img !== imageUrl);
      newImgs.unshift(imageUrl);
      return newImgs;
    });
  };

  return (
    <div className="space-y-3">
      <div className="flex mt-3">
        {uploading && (
          <p>
            <span>
              <Skeleton className="bg-gray-200 h-fit w-fit rounded-lg" />
            </span>
          </p>
        )}

        {!uploading && images.length === 0 && (
          <div className="w-full h-24 border-2 border-dashed border-gray-400 rounded-lg"></div>
        )}

        {!uploading &&
          images.length > 0 &&
          // <Label>{t("images.no_images_to_be_selected")}</Label>
          images.map((image, key) => {
            return (
              <ImagePreview
                key={key}
                imageSrc={image}
                deletingAction={false}
                isSelected={key === 0}
                onSelect={handleOnSelectedImage}
              />
            );
          })}
      </div>
      <div className="flex justify-end">
        <Upload
          onUpload={onNewImagesUploaded}
          onStartLoading={() => setUploading(true)}
          onEndLoading={() => setUploading(false)}
        />
      </div>
    </div>
  );
}
