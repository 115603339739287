import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { title } from "process";
import { useTranslation } from "react-i18next";

export default function SidebarLatestNews() {
  const { t, i18n } = useTranslation();
  const { showModal } = useModal();

  const selectedLanguage: string = i18n.language;

  const news: NewsItem[] = [
    {
      date: "04.12.2024",
      title: {
        en: "DirectSearch is Coming",
        it: "DirectSearch Sta Arrivando",
        es: "DirectSearch Está Llegando",
      },
      // text: {
      //   en: "Siamo entusiasti di annunciare che presto lanceremo il nostro nuovo DirectSearch, uno strumento pensato per semplificare la ricerca e la prenotazione degli hotel da canale diretto.<br/>🪨	Confrontare facilmente le tariffe sui siti ufficiali degli hotel.Con DirectSearch gli utenti potranno confrontare le tariffe direttamente sui siti ufficiali degli hotel, eliminando la necessità di intermediari e facilitando prenotazioni dirette.<br>Il nostro obiettivo è quello di incrementare la visibilità e attrarre più prenotazioni dirette, riducendo la dipendenza dalle OTA e le relative commissioni. Il contatto diretto con gli ospiti favorirà una relazione più trasparente e duratura, migliorando l’esperienza complessiva per entrambe le parti.<br/>Puntiamo a rendere la ricerca di hotel più semplice, diretta e vantaggiosa per tutti. Restate con noi per scoprire come questa funzionalità potrà contribuire a far crescere il vostro business.",
      //   it: "Siamo entusiasti di annunciare che presto lanceremo il nostro nuovo DirectSearch, uno strumento pensato per semplificare la ricerca e la prenotazione degli hotel da canale diretto.<br/>Con DirectSearch gli utenti potranno confrontare le tariffe direttamente sui siti ufficiali degli hotel, eliminando la necessità di intermediari e facilitando prenotazioni dirette.<br>Il nostro obiettivo è quello di incrementare la visibilità e attrarre più prenotazioni dirette, riducendo la dipendenza dalle OTA e le relative commissioni. Il contatto diretto con gli ospiti favorirà una relazione più trasparente e duratura, migliorando l’esperienza complessiva per entrambe le parti.<br/>Puntiamo a rendere la ricerca di hotel più semplice, diretta e vantaggiosa per tutti. Restate con noi per scoprire come questa funzionalità potrà contribuire a far crescere il vostro business.",
      //   es: "Siamo entusiasti di annunciare che presto lanceremo il nostro nuovo DirectSearch, uno strumento pensato per semplificare la ricerca e la prenotazione degli hotel da canale diretto.<br/>Con DirectSearch gli utenti potranno confrontare le tariffe direttamente sui siti ufficiali degli hotel, eliminando la necessità di intermediari e facilitando prenotazioni dirette.<br>Il nostro obiettivo è quello di incrementare la visibilità e attrarre più prenotazioni dirette, riducendo la dipendenza dalle OTA e le relative commissioni. Il contatto diretto con gli ospiti favorirà una relazione più trasparente e duratura, migliorando l’esperienza complessiva per entrambe le parti.<br/>Puntiamo a rendere la ricerca di hotel più semplice, diretta e vantaggiosa per tutti. Restate con noi per scoprire come questa funzionalità potrà contribuire a far crescere il vostro business.",
      // },
      text: {
        it: `<i>La rivoluzione per le prenotazioni dirette è alle porte.</i><br/>
            Presto lanceremo <b>DirectSearch</b>, il nostro nuovo strumento progettato per trasformare il modo in cui gli utenti cercano e prenotano direttamente dal vostro sito ufficiale.<br/>
            Con DirectSearch, i vostri ospiti potranno:<br/>
            ✅ Confrontare facilmente le tariffe sui siti ufficiali degli hotel.<br/>
            ✅ Evitare intermediari, scegliendo la trasparenza e il contatto diretto.<br/>
            ✅ Prenotare in modo più semplice e vantaggioso.<br/>
            <b>Perché è una svolta per il vostro business?</b><br/>
            ✔️ Maggiore visibilità per il vostro hotel.<br/>
            ✔️ Più prenotazioni dirette, meno dipendenza dalle OTA e commissioni.<br/>
            ✔️ Una relazione più autentica e diretta con i vostri ospiti.<br/>
            Stiamo aprendo la strada a un’esperienza di prenotazione più chiara, equa e conveniente per tutti.<br/>
            📢 <b>Rimanete sintonizzati:</b> presto vi mostreremo come DirectSearch potrà accelerare la crescita del vostro business!
          `,
        en: `<i>The revolution for direct bookings is at the doorstep.</i><br/>
            We will soon launch <b>DirectSearch</b>, our new tool designed to transform the way users search and book directly from your official website.<br/>
            With DirectSearch, your guests will be able to:<br/>
            ✅ Easily compare rates on official hotel websites.<br/>
            ✅ Avoid intermediaries, choosing transparency and direct contact.<br/>
            ✅ Book more simply and advantageously.<br/>
            <b>Why is it a game-changer for your business?</b><br/>
            ✔️ Greater visibility for your hotel.<br/>
            ✔️ More direct bookings, less dependence on OTAs and commissions.<br/>
            ✔️ A more authentic and direct relationship with your guests.<br/>
            We are paving the way for a clearer, fairer, and more convenient booking experience for everyone.<br/>
            📢 <b>Stay tuned:</b> we'll soon show you how DirectSearch can accelerate your business growth!
          `,
        es: `<i>La revolución para las reservas directas está a la vuelta de la esquina.</i><br/>
            Pronto lanzaremos <b>DirectSearch</b>, nuestra nueva herramienta diseñada para transformar la forma en que los usuarios buscan y reservan directamente desde su sitio web oficial.<br/>
            Con DirectSearch, sus huéspedes podrán:<br/>
            ✅ Comparar fácilmente las tarifas en los sitios web oficiales de los hoteles.<br/>
            ✅ Evitar intermediarios, eligiendo la transparencia y el contacto directo.<br/>
            ✅ Reservar de manera más sencilla y ventajosa.<br/>
            <b>¿Por qué es un cambio revolucionario para su negocio?</b><br/>
            ✔️ Mayor visibilidad para su hotel.<br/>
            ✔️ Más reservas directas, menos dependencia de las OTAs y comisiones.<br/>
            ✔️ Una relación más auténtica y directa con sus huéspedes.<br/>
            Estamos allanando el camino para una experiencia de reserva más clara, justa y conveniente para todos.<br/>
            📢 <b>Manténganse atentos:</b> ¡pronto les mostraremos cómo DirectSearch puede acelerar el crecimiento de su negocio!
          `,
      },
      isNew: true,
    },
  ];

  return (
    <div className="border rounded-xl p-5">
      <p className="pb-4 text-xl font-medium">
        {t("analytics.news.section_title")}
      </p>
      <div className="flex flex-col gap-2.5">
        {news.map((n, index) => (
          <div
            key={index}
            className="border rounded-md p-4 flex flex-col gap-1 cursor-pointer"
            onClick={() =>
              showModal(
                <div
                  dangerouslySetInnerHTML={{
                    __html: n.text[selectedLanguage as keyof typeof n.text],
                  }}
                />,
                {
                  title: n.title[selectedLanguage as keyof typeof n.title],
                  description: "",
                }
              )
            }
          >
            <div className="flex justify-between items-center">
              <span className="text-xs text-gray-400">{n.date}</span>
              {n.isNew && (
                <span className="bg-takyon text-xs rounded-sm py-1 px-2">
                  {t("analytics.news.isNew")}
                </span>
              )}
            </div>
            <p className="text-sm font-medium">
              {n.title[selectedLanguage as keyof typeof n.title]}
            </p>
            <p
              className="text-xs line-clamp-3"
              dangerouslySetInnerHTML={{
                __html: n.text[selectedLanguage as keyof typeof n.text],
              }}
            />
          </div>
        ))}
        {/* <Button variant="link" className="underline">
          <p>{t("analytics.news.see_all")}</p>
        </Button> */}
      </div>
    </div>
  );
}
