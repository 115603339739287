import { ColumnDef } from "@tanstack/react-table";

import { TFunction } from "i18next";
import { datetimeToString } from "@/utils/generic";

export const getColumns = (t: TFunction): ColumnDef<any>[] => {
  return [
    {
      header: `${t("payment.charge_reference")}`,
      accessorKey: "nftReference",
    },
    {
      header: `${t("payment.charge_amount")}`,
      accessorKey: "amount",
      accessorFn: (log) => `${Number(log.amount).toFixed(2)} €`,
    },
    {
      header: `${t("payment.charge_scope")}`,
      accessorKey: "scope",
    },
    {
      header: `${t("payment.charge_date")}`,
      accessorKey: "_createdAt",
      accessorFn: (date) => datetimeToString(date._createdAt),
    },
  ];
};
