import { useEffect, useMemo, useState } from "react";
import { getColumns } from "./logs-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
// import { H3 } from "@/components/typography/Typography";
// import { iCollection } from "@/services/collection";
import { iUser } from "@/services/auth";
import { useModal } from "@/context/ModalContext";
import { useNavigate } from "react-router-dom";
import { iSubscriptionChargeLog } from "@/services/payment";

export default function ChargesLogDatatable({
  logs,
}: {
  logs: iSubscriptionChargeLog[];
}) {
  //   const { collectionId } = useParams<{ collectionId: string }>();

  //   useEffect(() => {
  //     console.log("Load collection data", collectionId);
  //   }, [collectionId]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const columns = useMemo(() => getColumns(t), []);

  const [loading, setLoading] = useState(false); //ToDO: Hanlde loading

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  //   const [searchTerm, setSearchTerm] = useState("");

  //   const [data, setData] = useState<iCollection[]>([]);

  //   const fetchData = useCallback(async () => {
  //     console.log("fetchData  inside start");
  //     setLoading(true);
  //     try {
  //       // console.log("getBidsPaginated", payload);
  //       // const result = await getBidsPaginated(payload);
  //       const data = await getPendingPayments(
  //         paymentMethodId,
  //         page,
  //         collectionId
  //       );
  //       const pageCount = Math.ceil(data.length / pageSize);
  //       setData(data);
  //       setPageCount(pageCount);
  //       console.log("fetchData inside result.data", data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }, [collectionId, page, pageSize, filters, sorting, searchTerm]);

  //   useEffect(() => {
  //     console.log("fetchData hook start");
  //     fetchData();
  //     console.log("fetchData hook end");
  //   }, [fetchData]);

  useEffect(() => {
    if (Array.isArray(logs)) {
      setPageCount(Math.ceil(logs.length / pageSize));
    }
  }, [page]);

  useEffect(() => {
    if (Array.isArray(logs)) {
      setPageCount(Math.ceil(logs.length / pageSize));
    }
  }, [pageSize, logs]);

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={logs.slice(page * pageSize, page * pageSize + pageSize)}
        columns={columns}
        pageCount={pageCount}
        globalSearch={false}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
      />
    </div>
  );
}
