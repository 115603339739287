import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { LucideStar } from "lucide-react";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  hotelCensusLocationType,
  iCollection,
  patchCollection,
} from "@/services/collection";
import MapsAutocomplete from "@/components/mapsautocomplete/MapsAutocomplete";
import { useModal } from "@/context/ModalContext";
import { useRbac } from "@/hooks/rbac";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

const DetailsForm = forwardRef(function Details(
  {
    collection,
    nextPage,
    onRefresh,
  }: { collection: iCollection; nextPage?: string; onRefresh: Function },
  ref
) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z.string(),
    stars: z.string(),
    locationType: z.string(),
    locationField: z.string(),
    email: z.string().email(),
    website: z.string(),
    payment: z.boolean(),
    averageBookingPrice: z
      .preprocess(
        (val) => (typeof val === "number" ? String(val) : val),
        z.string()
      )
      .transform((val) => Number(val)) // Convert string input to number
      .refine((val) => !isNaN(val), {
        message: "AverageBookingPrice must be a number",
      }) // Check if it's a valid number
      .refine((val) => val >= 0, {
        message: "AverageBookingPrice must be non-negative",
      }), // Additional validation,
    cin: z.string().optional(),
    cir: z.string().optional(),
    feeOnCreation: z.number(),
    feeMonthly: z.number(),
    feeFixedOnCreation: z.number(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const experienceSchema = z.object({
    type: z.string(),
    name: z.string(),
    email: z.string().email(),
    website: z.string(),
  });
  const experienceForm = useForm<z.infer<typeof experienceSchema>>({
    resolver: zodResolver(experienceSchema),
    values: {
      type: collection?.census?.type || "",
      name: collection?.census?.name || "",
      email: collection?.census?.email || "",
      website: collection?.census?.website || "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [censusLocation, setCensusLocation] = useState<any>(null);
  const { hideModal } = useModal();

  const hotelCensusLocationTypeOptions: {
    text: string;
    value?: hotelCensusLocationType;
  }[] = useMemo(
    () => [
      { text: t("collection.city"), value: "city" },
      { text: t("collection.lake"), value: "lake" },
      { text: t("collection.mountain"), value: "mountain" },
      { text: t("collection.sea"), value: "sea" },
      { text: t("collection.wellness"), value: "wellness" },
    ],
    [t]
  );

  const handleAddressSelection = (selectedAddress: any) => {
    if (!selectedAddress) {
      form.resetField("locationField");
    } else {
      setCensusLocation(selectedAddress);
      form.setValue("locationField", selectedAddress.label);
      form.clearErrors("locationField");
    }
  };

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    try {
      // pick dei fields da gestire manualmente
      const { payment, locationField, stars, averageBookingPrice, ...census } =
        data;

      await patchCollection({
        ...collection,
        checkBeforeSale: !payment,
        census: {
          stars: stars == "na" ? "" : stars,
          location: censusLocation,
          ...census,
        },
        censusStats: {
          ...collection.censusStats,
          averageBookingPrice: averageBookingPrice,
        },
        collectionId: collection._id,
      });
      await onRefresh();
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    if (nextPage && !isLoading) {
      navigate(nextPage);
    }
  };

  const onSubmitExperience = async (data: z.infer<typeof experienceSchema>) => {
    setIsLoading(true);

    try {
      await patchCollection({
        ...collection,
        census: { ...data },
        collectionId: collection._id,
      });
      await onRefresh();
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    if (nextPage && !isLoading) {
      navigate(nextPage);
    }
  };

  useEffect(() => {
    const { location, stars, ...census } = collection.census || {};

    // prefill form with collection data
    const formFromCollection = {
      ...census,
      name: collection.name,
      stars: stars || "na",
      locationField: location?.label, // prefill location field with label
      payment: !collection.checkBeforeSale,
      averageBookingPrice: collection.censusStats?.averageBookingPrice,
      feeOnCreation: collection.census?.feeOnCreation || 0,
      feeMonthly: collection.census?.feeMonthly || 0,
      feeFixedOnCreation: collection.census?.feeFixedOnCreation || 0,
    };

    form.reset(formFromCollection);
    setCensusLocation(location);
  }, [collection]);

  return collection.type === "experience" ? (
    <Form {...experienceForm}>
      <form
        className="flex flex-col gap-4"
        onSubmit={experienceForm.handleSubmit(onSubmitExperience)}
      >
        <FormField
          control={experienceForm.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("experience.type")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Seleziona la tipologia di esperienza"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={experienceForm.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("experience.provider")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Inserisci il nome del provider di esperienze"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={experienceForm.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("experience.email")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Inserisci l’email di contatto del provider esperienze"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={experienceForm.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("experience.website")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder="Inserisci il sito web del provider di esperienze"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="border-b h-8 mb-8"></div>
        <div className="flex flex-row gap-4">
          <LoadingSubmitButton
            className="ml-auto"
            variant="takyon"
            type="submit"
            isLoading={isLoading}
          >
            {t("buttons.save_and_continue")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  ) : (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_name")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input placeholder="Inserisci il nome dell'hotel" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="stars"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_stars")} {t("form.required")}
              </FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={t("collection.census_stars_placeholder")}
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem key={0} value="na">
                    N/A
                  </SelectItem>
                  {[1, 2, 3, 4, 5, 6].map((value) => (
                    <SelectItem key={value} value={String(value)}>
                      <div className="flex items-center">
                        {value === 6 ? (
                          <>
                            {[...Array(5)].map((_, i) => (
                              <LucideStar key={i} className="w-3 h-3" />
                            ))}
                            <span className="ml-2">L</span>
                          </>
                        ) : (
                          [...Array(value)].map((_, i) => (
                            <LucideStar key={i} className="w-3 h-3" />
                          ))
                        )}
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="locationType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_location_type")} {t("form.required")}
              </FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue
                      placeholder={t(
                        "collection.census_location_type_placeholder"
                      )}
                    />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {hotelCensusLocationTypeOptions.map((s, index) => (
                    <SelectItem
                      key={"item" + index}
                      value={s.value ?? t("form.select")}
                    >
                      {s.text}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="locationField"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_location")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <MapsAutocomplete
                  defaultValue={censusLocation}
                  onSelect={handleAddressSelection}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_email")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t("collection.census_email_placeholder")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_website")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t("collection.census_website_placeholder")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="payment"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>
                {t("collection.census_payment")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  value={field.value as any}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value={true as any} />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t("collection.census_yes")}
                      {", "}
                      {t("collection.census_yes_description")}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value={false as any} />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t("collection.census_no")}
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormDescription>
                {t("collection.census_payment_tip")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="averageBookingPrice"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("collection.census_average_price")} € {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t("collection.census_average_price_placeholder")}
                  type="number"
                  step="0.01"
                  {...field}
                />
              </FormControl>
              <FormDescription className="text-muted-foreground text-xs">
                {t("collection.census_average_price_tip")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cin"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("collection.cin")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("collection.cin_placeholder")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cir"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("collection.cir")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("collection.cir_placeholder")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="border-b h-8 mb-8"></div>
        <div className="flex flex-row gap-4">
          <LoadingSubmitButton
            className="ml-auto"
            variant="takyon"
            type="submit"
            isLoading={isLoading}
          >
            {t("buttons.save_and_continue")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
});

export default DetailsForm;
