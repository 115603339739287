import { useModal } from "@/context/ModalContext";
import {
  deleteNft,
  iNft,
  NFT_ACCESS_STATUS,
  NFT_OWNER_TYPE,
  NFT_SELL_STATUS,
} from "@/services/nft";
import { datetimeToString } from "@/utils/generic";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { LucideEdit, LucideEye, MoreVertical, Repeat2 } from "lucide-react";
import DetailHotel from "@/components/collection/DetailHotel";
import { DataTableColumnHeader } from "@/components/datatable/data-table-column-header";
import { Badge } from "@/components/ui/badge";
import {
  COLLECTION_PERMISSIONS,
  COLLECTION_TYPE,
  iCollection,
} from "@/services/collection";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import BuybackActionForm from "@/components/offer/BuybackActionForm";
import FormEditNftWrapper from "@/components/collection/FormEditNftWrapper";
import DeleteModal from "@/components/ui/deleteModal";
import { useState } from "react";
import i18n from "i18next";
import CopyId from "@/components/helpers/CopyId";
import PutOnSaleForm from "@/components/collection/PutOnSaleForm";
import { useRbac } from "@/hooks/rbac";
import RemoveFromSaleForm from "@/components/collection/RemoveFromSaleForm";
import helpers from "@/components/helpers/helpers";
import DropdownMenuItemWithTooltip from "@/components/ui-custom/DropdownMenuItemWithTooltip";
import EmailOrBlur from "@/components/datatable/EmailOrBlur";
import { get } from "lodash";

const t = i18n.t.bind(i18n);

function canEnablePutOnSale(
  nft: iNft,
  collection: iCollection,
  sellStatus: NFT_SELL_STATUS
) {
  const { can } = useRbac();
  // quando è  nft+gold + nft.accessStatus open e l'owner è uno del team della collezione e ha il permesso collection put on sale
  const isNftOwnerInTeam = collection.members.some(
    (m) => m.email === nft.owner
  );

  if (nft.sellStatus !== sellStatus) return false;

  return (
    nft.accessStatus == NFT_ACCESS_STATUS.OPEN &&
    nft.gold &&
    isNftOwnerInTeam &&
    can(COLLECTION_PERMISSIONS.sale, collection)
  );
}

function ActionCell({
  row,
  collection,
  onSuccess,
}: {
  row: any;
  collection: iCollection;
  onSuccess: () => void;
}) {
  const { showModal, hideModal } = useModal();
  const { can } = useRbac();

  const onRemoveNft = async (nftId: string) => {
    try {
      await deleteNft({ nftId: nftId });
      hideModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowModal = (
    component: JSX.Element,
    title: string,
    description: string
  ) => {
    showModal(component, {
      title,
      description,
    });
  };

  return (
    <div className="flex flex-row flex-nowrap gap-0">
      {can(COLLECTION_PERMISSIONS.update_nft, collection) && (
        <>
          <Button
            variant="ghost"
            size="icon"
            onClick={() =>
              handleShowModal(
                <FormEditNftWrapper
                  nftId={row.original._id!}
                  collection={collection}
                  onSuccess={onSuccess}
                />,
                t("nft.update") + " " +
                <CopyId resourceId={row.original._id!} />,
                ""
              )
            }
          >
            <LucideEdit size={16} />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button aria-haspopup="true" size="icon" variant="ghost">
                <MoreVertical className="h-4 w-4" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItemWithTooltip
                disabled={row.original.sellStatus !== NFT_SELL_STATUS.FOR_SALE}
                disabledMessage={t("nft.buyback_disabled")}
                onClick={() =>
                  row.original.sellStatus === NFT_SELL_STATUS.FOR_SALE &&
                  handleShowModal(
                    <BuybackActionForm nft={row.original} />,
                    t("nft.buyback"),
                    t("nft.buyback_text")
                  )
                }
              >
                {t("nft.buyback")}
              </DropdownMenuItemWithTooltip>
              {canEnablePutOnSale(
                row.original,
                collection,
                NFT_SELL_STATUS.NOT_FOR_SALE
              ) && (
                <DropdownMenuItem
                  onClick={() =>
                    handleShowModal(
                      <PutOnSaleForm
                        onSuccess={onSuccess}
                        nft={row.original}
                        collection={collection}
                      />,
                      t("nft.put_on_sale_modal_title"),
                      t("nft.put_on_sale_modal_text")
                    )
                  }
                >
                  {t("nft.put_on_sale")}
                </DropdownMenuItem>
              )}
              {canEnablePutOnSale(
                row.original,
                collection,
                NFT_SELL_STATUS.FOR_SALE
              ) && (
                <DropdownMenuItem
                  onClick={() =>
                    handleShowModal(
                      <RemoveFromSaleForm
                        onSuccess={onSuccess}
                        nft={row.original}
                        collection={collection}
                      />,
                      t("nft.remove_from_sale_modal_title"),
                      t("nft.remove_from_sale_modal_text")
                    )
                  }
                >
                  {t("nft.remove_from_sale")}
                </DropdownMenuItem>
              )}
              {can(COLLECTION_PERMISSIONS.delete_nft, collection) && (
                <DropdownMenuItem
                  onClick={() =>
                    handleShowModal(
                      <DeleteModal
                        onRemove={() => onRemoveNft(row.original._id!)}
                      />,
                      t("nft.remove_modal"),
                      t("nft.remove_modal_text")
                    )
                  }
                >
                  {t("nft.delete")}
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      )}
    </div>
  );
}

export const getColumns = (
  collection: iCollection,
  showModal: Function,
  onSuccess: () => void
): ColumnDef<iNft>[] => {
  // returns different columns for the NFTs table based on collection.type
  // collection.type is a string that can be "experience" or "hotel"

  const collectionType = collection.type;

  const commonColumns: ColumnDef<iNft>[] = [
    // {
    //   header: "ID",
    //   accessorKey: "_id",
    //   cell: ({ getValue }) => <CopyId resourceId={getValue() as string} />,
    // },
    {
      id: "view",
      cell: ({ row }) => (
        <Button
          variant="ghost"
          size="icon"
          onClick={() =>
            showModal(<DetailHotel nft={row.original} />, {
              title:
                t("nft.details_title") + " - " + row.original.payload.reference,
              description: null,
            })
          }
        >
          <LucideEye size={16} />
        </Button>
      ),
    },
    {
      id: "creationDate",
      // header: ({ column }) => (
      //   <DataTableColumnHeader
      //     column={column}
      //     title={t("nft.taks_creation_date")}
      //   />
      // ),
      header: t("nft.taks_creation_date"),
      accessorFn: (nft) => datetimeToString(nft._createdAt, undefined, true),
    },
    {
      id: "reference",
      header: t("nft.taks_reference"),
      accessorKey: "payload.reference",
    },
    {
      header: t("nft.taks_status"),
      accessorKey: "sellStatus",
      cell: ({ row }) => {
        const statuses = [
          {
            value: NFT_SELL_STATUS.FOR_SALE,
            label: t("nft.taks_forsale"),
            icon: null,
          },
          {
            value: NFT_SELL_STATUS.NOT_FOR_SALE,
            label: t("nft.taks_notforsale"),
            icon: null,
          },
          {
            value: NFT_SELL_STATUS.WAITING_PAYMENT,
            label: t("nft.taks_waitingpayment"),
            icon: null,
          },
          {
            value: NFT_SELL_STATUS.PENDING_SALE,
            label: t("nft.taks_pendingsale"),
            icon: null,
          },
        ];

        const status = statuses.find(
          (status) => status.value === row.getValue("sellStatus")
        );

        if (!status) {
          return null;
        }

        return (
          <Badge
            variant={
              status.value !== NFT_SELL_STATUS.NOT_FOR_SALE
                ? "takyon"
                : "outline"
            }
            className="whitespace-nowrap"
          >
            {status.label}
          </Badge>
        );
      },
      // accessorFn: (nft) =>
      //   nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? "" : "",
      //
    },

    // {
    //   header: t("nft.taks_collection"),
    //   accessorKey: "_collectionCensus.name",
    // },
    {
      id: "owner",
      header: t("nft.taks_owner"),
      accessorFn: (nft) => {
        const owner = nft.owner;
        return owner;
      },
      cell: ({ getValue, row }) => {
        const resold = row.original.ownerType === NFT_OWNER_TYPE.RESOLD;
        return (
          <div className="flex gap-1 items-center">
            {resold && <Repeat2 size={16} />}
            {EmailOrBlur(getValue() as string)}
          </div>
        );
      },
    },
    {
      header: t("nft.taks_pruchase_price"),
      accessorFn: (nft) => `${helpers.price(nft.originalPrice, "€", 2)}`,
    },
    {
      header: t("nft.taks_resell_price"),
      accessorFn: (nft) =>
        nft.currentPrice ? `${helpers.price(nft.currentPrice, "€", 2)}` : "-",
    },
  ];

  const hotelColumns: ColumnDef<iNft>[] = [
    {
      header: t("nft.taks_participants"),
      accessorFn: (nft) => {
        return nft.payload?.rooms?.reduce((total: number, room: any) => {
          return total + (room.guestsAdults ?? 0) + (room.guestsKids ?? 0);
        }, 0);
      },
    },
    {
      // header: "Check-in",
      id: "payload.checkin",
      header: t("nft.checkin"),
      // header: ({ column }) => (
      //   <DataTableColumnHeader column={column} title={t("nft.checkin")} />
      // ),
      accessorFn: (nft) => {
        const checkin = datetimeToString(
          nft.payload?.checkin,
          nft.payload?.location?.gmt,
          true
        );
        return `${checkin}`;
      },
    },
    {
      header: t("nft.checkout"),
      accessorFn: (nft) => {
        const checkout = datetimeToString(
          nft.payload?.checkout,
          nft.payload?.location?.gmt,
          true
        );
        return `${checkout}`;
      },
    },
    {
      id: "actions",
      accessorKey: "id",
      header: t("nft.taks_actions"),
      cell: function render({ getValue, row }) {
        return (
          <ActionCell row={row} collection={collection} onSuccess={onSuccess} />
        );
      },
    },
  ];

  const experienceColumns: ColumnDef<iNft>[] = [
    {
      header: "Participants",
      accessorFn: (nft) => {
        return nft.payload?.rooms?.reduce((total: number, room: any) => {
          return total + (room.guestsAdults ?? 0) + (room.guestsKids ?? 0);
        }, 0);
      },
    },
    {
      header: "Date",
      accessorFn: (nft) => {
        const date = datetimeToString(
          nft.payload?.date,
          nft.payload?.location?.gmt,
          true
        );
        return `${date}`;
      },
    },
    {
      id: "actions",
      accessorKey: "id",
      header: "Actions",
      cell: function render({ getValue, row }) {
        return (
          <ActionCell row={row} collection={collection} onSuccess={onSuccess} />
        );
      },
    },
  ];

  if (collectionType === COLLECTION_TYPE.experience) {
    return [...commonColumns, ...experienceColumns];
  } else {
    return [...commonColumns, ...hotelColumns];
  }
};
