import { Button } from "@/components/ui/button";
import { t } from "i18next";

const DataAnalysisDeleteModal = ({
  deleteMask,
  hideModal,
}: {
  deleteMask: () => Promise<void>;
  hideModal: () => void;
}) => {
  return (
    <div
      id="DataAnalysisDeleteModal"
      className="flex gap-2 w-full justify-end mt-8 mb-4"
    >
      <Button onClick={hideModal}>{t("dataAnalysis.cancel")}</Button>
      <Button onClick={deleteMask} variant={"destructive"}>
        {t("dataAnalysis.modal_delete_btn")}
      </Button>
    </div>
  );
};

export default DataAnalysisDeleteModal;
