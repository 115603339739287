import { Button } from "@/components/ui/button";
import iDataAnalysisMask, {
  editAnalysisMask,
} from "@/services/dataanalysismask";
import { Plus } from "lucide-react";
import { DATA_ANALYSIS_MODULE_TYPE } from "@/services/dataanalysismask";
import { MouseEventHandler, useEffect, useState } from "react";
import { Separator } from "@/components/ui/separator";
import DataAnalysisModuleTypeCard from "./DataAnalysisModuleTypeCard";
import { useSectionTitle } from "@/context/SectionTitleContext";

const DataAnalysisEditor = ({
  maskData,
  setMaskData,
}: {
  maskData: iDataAnalysisMask;
  setMaskData: ({}: any) => void;
}) => {
  const [disabled, setDisabled] = useState<any>({});
  const { setTitle } = useSectionTitle();
  const handleClick = (e: any) => {
    let name = e.target.name;
    let modulesCopy = maskData.modules;
    modulesCopy.push({
      name: name,
      config: {},
    });
    setMaskData({ ...maskData, modules: modulesCopy });
    setDisabled({ ...disabled, [name]: true });
  };

  const editData = () => {
    console.log("data - editData : ", {
      ...maskData,
      dataAnalysisMaskId: maskData._id,
      name: maskData.name,
      modules: maskData.modules,
    });
    editAnalysisMask({
      ...maskData,
      dataAnalysisMaskId: maskData._id,
      name: maskData.name,
      modules: maskData.modules,
    });
  };

  useEffect(() => {
    setTitle(maskData.name);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let temp: any = {};
    maskData.modules.map((el) => {
      temp[el.name] = true;
    });
    setDisabled(temp);
  }, [maskData]);

  return (
    <div id="DataAnalysisEditor" className="flex flex-col gap-4">
      <div className="flex gap-8 justify-between">
        <div className="flex flex-col w-full">
          <div className="flex justify-center items-center border border-dashed rounded-md p-6 h-8">
            <Plus />
          </div>
          {maskData.modules.map((el: any, index) => {
            return (
              <DataAnalysisModuleTypeCard
                key={"card" + index}
                name={el.name}
                setMaskData={setMaskData}
                maskData={maskData}
                setDisabled={setDisabled}
              />
            );
          })}
          <Separator className="my-8" />
          <div className="w-full flex justify-between items-center">
            <Button className="w-fit" onClick={() => setMaskData({})}>
              Back
            </Button>
            <Button
              variant="takyon"
              className="w-fit"
              onClick={() => editData()}
            >
              Salva
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-96 border-l-2 pl-8">
          {(
            Object.keys(DATA_ANALYSIS_MODULE_TYPE) as Array<
              keyof typeof DATA_ANALYSIS_MODULE_TYPE
            >
          ).map((key, index) => {
            let val = key.toLowerCase();
            return (
              <Button
                key={"btn" + index}
                className="flex justify-center items-center border rounded-md p-4 hover:bg-green-400 hover:font-bold"
                name={val}
                onClick={(e) => handleClick(e)}
                disabled={disabled[val]}
              >
                {val}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DataAnalysisEditor;
