import { forwardRef, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { getCollection, iCollection } from "@/services/collection";
import DetailsForm from "./DetailsForm";
import { useTranslation } from "react-i18next";
import { CollectionPageChildProps } from "../CollectionPage";

export default function Details({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();
  const { collectionId } = useParams<{ collectionId: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    setBackBtn(true);
    setProgress(20);
  }, []);

  useEffect(() => {
    if (collection) {
      setTitleCollectionPage(
        `${t("collection.hotel_basics")} - ${collection!.name}`
      );
    }
  }, [collection]);

  return collection ? (
    <DetailsForm
      onRefresh={onRefresh}
      collection={collection!}
      nextPage={`/collection/${collectionId}/infos/images`}
    />
  ) : (
    <p>{t("collection.error")}</p>
  );
}
