import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { createAnalysisMask } from "@/services/dataanalysismask";
import { Button } from "@/components/ui/button";

const DataAnalysisCreateModal = ({
  hideModal,
  load,
}: {
  hideModal: () => void;
  load: Function;
}) => {
  const formSchema = z.object({
    name: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    await createAnalysisMask(data);
    await load();
    hideModal();
  };

  return (
    <Form {...form}>
      <form
        id="DataAnalysisCreateModal"
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("dataAnalysis.mask_name")} *</FormLabel>
              <FormControl>
                <Input
                  placeholder="Inserisci il nome della maschera"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-4 w-full justify-end">
          <Button className="" onClick={() => hideModal()}>
            Indietro
          </Button>
          <Button className="" variant="takyon" type="submit">
            Crea
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default DataAnalysisCreateModal;
