import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { redirect, useNavigate } from "react-router-dom";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { H2 } from "../typography/Typography";
import getLink, { LINK_TYPE } from "@/lib/links";
import { useAuthContext } from "@/context/AuthContext";
import { ArrowLeft } from "lucide-react";

export function VerifyEmailForm() {
  const { t } = useTranslation();

  const formSchema = z.object({
    code: z.string().min(6, "Verification code must be at least 6 characters."),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
    },
  });

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sendAgainTimer, setSendAgainTimer] = useState(0); // Timer for resend link

  const { verifyEmail, sendVerificationEmail, logout } = useAuthContext();

  // Timer effect
  useEffect(() => {
    if (sendAgainTimer > 0) {
      const timerId = setInterval(() => {
        setSendAgainTimer(sendAgainTimer - 1);
      }, 1000);
      return () => clearInterval(timerId); // Cleanup interval
    }
  }, [sendAgainTimer]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      await verifyEmail({
        redirect: getLink(LINK_TYPE.VERIFY_EMAIL),
        code: values.code,
      });
      navigate(getLink(LINK_TYPE.HOME));
    } catch (error: any) {
      console.log(error);
      form.setError("code", {
        type: "manual",
        message: t("auth.invalid_verification_code"),
      });
    }
    setIsLoading(false);
  }

  async function handleSendAgain() {
    try {
      await sendVerificationEmail({
        redirect: getLink(LINK_TYPE.VERIFY_EMAIL),
      });
      setSendAgainTimer(60); // Set timer to 60 seconds
      console.log("Verification email sent again.");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="mx-auto max-w-sm w-full">
      <div className="flex items-center justify-center h-full">
        <ArrowLeft
          className="mr-2 cursor-pointer"
          onClick={() => {
            logout();
            navigate(getLink(LINK_TYPE.AUTH));
          }}
        />
        <H2 className="flex-1 text-center">{t("auth.verify_email")}</H2>
      </div>
      <div className="border-b border-gray-300 mt-8 mb-8"></div>
      <div className="mt-5">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="code"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("auth.verification_code")}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("auth.verify_email_placeholder")}
                          {...field}
                          disabled={isLoading} // Disable input during loading
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                type="submit"
                variant={"takyon"}
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? t("auth.verifying") : t("auth.verify")}
              </Button>
            </div>
          </form>

          {/* Send again link with timer */}
          <div className="mt-4 text-center text-sm">
            {sendAgainTimer > 0 ? (
              <p>
                {t("auth.send_again_in")} {sendAgainTimer}
              </p>
            ) : (
              <>
                {t("auth.verify_email_send_again_text") + " "}
                <button
                  className="underline"
                  onClick={handleSendAgain}
                  disabled={sendAgainTimer > 0} // Disable if timer is active
                >
                  {t("auth.verify_email_send_again")}
                </button>
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}
