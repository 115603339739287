import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./partners-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { useModal } from "@/context/ModalContext";
import { useNavigate } from "react-router-dom";
import { getPartnersPaginated, iPartner } from "@/services/partners";
import { Button } from "@/components/ui/button";
import PartnerForm from "./PartnerForm";

export default function PartnersDatatable({}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iPartner[]>([]);

  const fetchData = useCallback(async () => {
    console.log("fetchData  inside start");
    setLoading(true);

    try {
      // const params: any = {
      //   size,
      //   page,
      //   mode,
      //   sort,
      // };

      let textQ = {};
      if (searchTerm) {
        textQ = {
          $or: [
            // list of fields where to search
            { name: { $regex: searchTerm, $options: "i" } },
          ],
        };
      }

      const payload = {
        page: page + 1,
        size: pageSize,
        query: textQ, // getUsersPaginated search only email as search term
      };

      console.log("fetchData : ", payload);

      // if (query) params.query = { name: { $regex: query, $options: "i" } };

      const result = await getPartnersPaginated(payload);

      setPageCount(pageCount);
      setData(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sorting, searchTerm]);

  const columns = useMemo(
    () => getColumns(t, showModal, hideModal, fetchData),
    []
  );

  useEffect(() => {
    console.log("fetchData hook start");
    fetchData();
    console.log("fetchData hook end");
  }, [fetchData]);

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={true}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
        actions={[
          <Button
            onClick={() =>
              showModal(<PartnerForm onSuccess={fetchData} />, {
                title: t("partners.add_partner"),
                description: "",
              })
            }
          >
            {t("partners.add_partner")}
          </Button>,
        ]}
      />
    </div>
  );
}
