import CollectionTeam from "@/components/collection/CollectionTeam";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { getCollection, iCollection } from "@/services/collection";
import { getGroup, iGroup } from "@/services/groups";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { CollectionPageChildProps } from "../CollectionPage";
import { Skeleton } from "@/components/ui/skeleton";

const CollectionTeamOnboarding = ({
  group,
  collection,
  loading,
  onRefresh,
}: CollectionPageChildProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setBackBtn(true);
    setProgress(60);
  }, []);

  useEffect(() => {
    if (collection) {
      setTitleCollectionPage(
        `${t("collection.members")} - ${collection!.name}`
      );
    }
  }, [collection]);

  if (isLoading)
    return (
      <>
        <div className="flex flex-col space-y-3">
          <Skeleton className="bg-gray-200 h-[32px] w-[730px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />
          <Skeleton className="bg-gray-200 h-[122px] rounded-lg" />
        </div>
      </>
    );
  if (!collection || !group) return <>{t("collection.not_found")}</>;

  return (
    <>
      <CollectionTeam
        collection={collection}
        onRefresh={onRefresh}
        group={group}
      />
      <div className="flex flex-col">
        <Separator id="separator" className="mb-8 bg-gray-200 h-0.5" />
        <div className="flex flex-row gap-4 justify-between">
          <Button
            className=""
            type="button"
            onClick={() =>
              navigate(`/collection/${collection._id}/infos/images`)
            }
          >
            {t("auth.back")}
          </Button>
          {/* depending on isFree the next step will be complete or payments */}
          {!collection.isFree ? (
            <Button
              variant="takyon"
              type="submit"
              onClick={() =>
                navigate(`/collection/${collection._id}/infos/payments`)
              }
            >
              {t("buttons.save_and_continue")}
            </Button>
          ) : (
            <Button
              variant="takyon"
              type="submit"
              onClick={() =>
                navigate(`/collection/${collection._id}/infos/complete`)
              }
            >
              {t("buttons.save_and_continue")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionTeamOnboarding;
