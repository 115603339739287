import { Button } from "@/components/ui/button";
import { TFunction } from "i18next";

export const getColumns = (
  t: TFunction,
  // showModal: Function,
  // hideModal: Function,
  loadCollections: Function,
  // census: any,
  // data: any,
  fillIndex: Function,
  navigate: Function
): any[] => {
  return [
    {
      header: "Id",
      accessorKey: "_id",
      accessorFn: (value: any) => value._id,
    },
    {
      header: "Name",
      accessorKey: "name",
      accessorFn: (value: any) => (value ? "🟢" : "🔴"),
      cell: function render(props: any) {
        let id = props.row.original._id;
        return (
          <a
            className="cursor-pointer"
            onClick={() => navigate(`/collection/${id}/nfts`)}
          >
            {props.row.original.name}
          </a>
        );
      },
    },
    {
      header: "HCOM slug",
      accessorKey: `census.hotelsComSlug`,
      accessorFn: (value: any) => (value ? "🟢" : "🔴"),
    },
    {
      header: "HCOM id",
      accessorKey: "census.hotelsComId",
      accessorFn: (value: any) => (value ? "🟢" : "🔴"),
      //   new Date(row.payload.created).toLocaleDateString(),
    },
    {
      header: "Description",
      accessorKey: "data.description",
      accessorFn: (value: any) => (value ? "🟢" : "🔴"),
    },
    {
      header: "Services",
      accessorKey: "data,services",
      accessorFn: (value: any) => (value.length ? "🟢" : "🔴"),
      // accessorFn: (row: any) =>
    },
    {
      header: "Rooms",
      accessorKey: "data.rooms",
      accessorFn: (value: any) => (value.length ? "🟢" : "🔴"),
    },
    {
      header: "POI",
      accessorKey: "data.poi",
      accessorFn: (value: any) => (value.length ? "🟢" : "🔴"),
    },
    {
      header: "Policies",
      accessorKey: "data.policies",
      accessorFn: (value: any) => (value.length ? "🟢" : "🔴"),
    },
    {
      header: "Reviews",
      accessorKey: "data.reviews",
      accessorFn: (value: any) => (value.length ? "🟢" : "🔴"),
    },
    {
      header: "R. Summary",
      accessorKey: "data.reviewSummary",
      accessorFn: (value: any) => (value.length ? "🟢" : "🔴"),
    },
    {
      header: "Actions",
      accessorKey: "payload",
      cell: function render(props: any) {
        let key = props.row.index;
        return (
          <div className="flex flex-col gap-2">
            <Button
              onClick={async () => {
                await fillIndex(key, false);
                loadCollections(false);
              }}
              // loading={loadingIndex === key}
              // small
              // text="Fill"
            >
              Fill
            </Button>
            <Button
              // loading={loadingIndex === key}
              onClick={async () => {
                await fillIndex(key, true);
                loadCollections(false);
              }}
              // small
              // text="Fill ov."
              // error
            >
              Fill ov.
            </Button>
          </div>
        );
      },
    },
  ];
};
