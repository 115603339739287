import { useDashboardData } from "@/hooks/dashboard";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DashboardSkeleton from "./DashboardSkeleton";
import { useRbac } from "@/hooks/rbac";
import KbdSearch from "@/components/buttons/KbdSearch";

export default function DashboardAdmin() {
  const { t } = useTranslation();

  const { isSuperAdmin } = useRbac();

  // SET PAGE TITLE
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle("");
  }, [setTitle]);

  return (
    <>
      <KbdSearch />
    </>
  );
}
