import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@/context/AuthContext";
import { H2 } from "../typography/Typography";
import getLink, { LINK_TYPE } from "@/lib/links";
import { getUrlParameter } from "@/utils/generic";

export function RegisterForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { register, sendVerificationEmail } = useAuthContext();

  const formSchema = z.object({
    email: z.string().email(t("auth.invalid_email")),
    password: z.string().min(6, t("auth.password_too_short")),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: getUrlParameter("email") ?? "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      await register(values);
      await sendVerificationEmail({
        redirect: getLink(LINK_TYPE.VERIFY_EMAIL),
      });
      // navigate(getLink(LINK_TYPE.HOME)); // Navigate after successful registration
    } catch (error: any) {
      console.log(error);

      const errorMessage = String(error.response.data.message).toLowerCase();

      if (errorMessage.includes("user already exists"))
        form.setError("email", {
          type: "manual",
          message: String(t("auth.email_already_taken")),
        });
    }
    setIsLoading(false);
  }

  return (
    <div className="mx-auto max-w-sm w-full">
      <div className="flex justify-center">
        <H2>{t("auth.register")}</H2>
      </div>
      <div className="mt-1 text-center text-sm">
        {t("auth.login_ask")}{" "}
        <Link to={getLink(LINK_TYPE.LOGIN)} className="underline font-medium">
          {t("auth.login")}
        </Link>
      </div>
      <div className="border-b border-gray-300 mt-8 mb-8"></div>
      <div className="mt-5">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("auth.email")}</FormLabel>
                      <FormControl>
                        <Input placeholder={t("auth.email")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid gap-2">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("auth.password_register")}</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder={t("auth.password_register")}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                type="submit"
                variant={"takyon"}
                className="w-full"
                disabled={isLoading}
              >
                {isLoading ? t("auth.registering") : t("auth.register")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
