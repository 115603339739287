import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PartnersDatatable from "./PartnersDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function Partners() {
  const { t } = useTranslation();

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("partners.page_title")}`);
  }, [setTitle]);

  return <PartnersDatatable />;
}
