import { useNavigate } from "react-router-dom";
import { selectUser } from "../../../store/userSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PAGE_DIM } from "../../../utils/generic";
import {
  autofillCollectionCensusInfo,
  getCollections,
  iCollection,
  patchCollection,
} from "../../../services/collection";
import { Loader2 } from "lucide-react";
import { DEFAULT_LANGUAGE, LANG } from "../../../services/i18next";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Button } from "@/components/ui/button";
import { useRbac } from "@/hooks/rbac";
import PaginationMod from "@/components/pagination/PaginationMod";
import TranslationsDatatable from "./TranslationsDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useTranslation } from "react-i18next";

export default function Translations() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("translations.page_title")}`);
  }, [setTitle]);

  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [loadingIndex, setLoadingIndex] = useState(-1);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const [lang, setLang] = useState<LANG>(DEFAULT_LANGUAGE);
  const { isSuperAdmin } = useRbac();

  const loadCollections = async (loading = true) => {
    if (loading) setIsLoading(true);
    try {
      const data = await getCollections({
        page,
        size,
      });

      setTotal(data.total || 0);
      setCollections(data.data);
    } catch (error) {
      console.log(error);
    }
    if (loading) setIsLoading(false);
  };

  // const onSizeChange = (size: number) => {
  //   setSize(size);
  // };

  // const onPageChange = (collectionPage: number) => {
  //   setPage(collectionPage);
  // };

  useEffect(() => {
    if (user && !user!._id) return;
    if (isSuperAdmin) return navigate("");

    loadCollections();
    // eslint-disable-next-line
  }, [page, user, size]);

  useEffect(() => {
    loadCollections();
  }, []);

  const fillIndex = async (i: number, override: boolean) => {
    setLoadingIndex(i);
    const collection = collections[i];

    if (collection.census?.type !== "hotel") return;

    try {
      let info = JSON.parse(JSON.stringify(collection.census?.info ?? {}));

      let needsUpdate = true;
      if (!override) {
        needsUpdate =
          !info[lang]?.description ||
          !info[lang]?.services?.length ||
          !info[lang]?.rooms?.length ||
          !info[lang]?.poi?.length ||
          !info[lang]?.policies?.length ||
          !info[lang]?.reviews?.length ||
          !info[lang]?.reviewSummary?.vote;
      }

      if (needsUpdate)
        info = await autofillCollectionCensusInfo(collection, lang, override);

      await patchCollection({
        ...collection,
        census: { ...collection.census, info },
        collectionId: collection._id,
      });
    } catch (error) {
      console.log("error on collection", collection._id, error);
    }

    setLoadingIndex(-1);
  };

  const fill = async (override: boolean) => {
    try {
      for (let i = 0; i < collections.length; i++) {
        await fillIndex(i, override);
      }
    } catch (error) {
      console.log(error);
    }

    loadCollections();
  };

  if (isLoading) return <Loader2 />;

  return (
    <div className="content">
      <div className="flex flex-col gap-4">
        {/* <Select
              defaultValue={lang}
              onValueChange={(e: any) => setLang(e.target.value as LANG)}
              options={Object.values(LANG).map((lang) => {
                return { value: lang, text: lang };
              })}
            /> */}

        <Select
          defaultValue={lang}
          onValueChange={(e: any) => setLang(e as LANG)}
        >
          <SelectTrigger className="w-fit">
            <SelectValue placeholder="Select" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>{"Seleziona una lingua"}</SelectLabel>

              {Object.values(LANG).map((l, index) => (
                <SelectItem key={"lang" + index} value={l}>
                  {l}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>

        <div className="flex gap-4">
          <Button
            onClick={() => fill(false)}
            // loading={loadingIndex > 0}
            // small
            // text="Fill"
          >
            Fill
          </Button>
          <Button
            // loading={loadingIndex > 0}
            onClick={() => fill(true)}
            // small
            // text="Fill override"
            // error
          >
            Fill override
          </Button>
        </div>
      </div>

      {!isLoading && (
        <TranslationsDatatable
          translations={collections}
          loadCollections={loadCollections}
          fillIndex={fillIndex}
          navigate={navigate}
        />
      )}
      {/* <table>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>HCOM slug</th>
                <th>HCOM id</th>
                <th>Description</th>
                <th>Services</th>
                <th>Rooms</th>
                <th>POI</th>
                <th>Policies</th>
                <th>Reviews</th>
                <th>R. Summary</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {collections.map((collection, key) => {
                if (collection.census?.type !== "hotel") return <></>;

                const census = collection.census;
                const info = collection.census?.info ?? {};
                return (
                  <tr key={"collection_" + key}>
                    <td>
                      {loadingIndex === key ? (
                        <Loader2 height="10px" width="10px" scale={0.2} />
                      ) : null}
                    </td>
                    <td
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(`/collection/${collection._id}/nfts`)
                      }
                    >
                      {collection.name}
                    </td>

                    <td>{census?.hotelsComSlug ? "🟢" : "🔴"}</td>
                    <td>{census?.hotelsComId ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.description ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.services?.length ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.rooms?.length ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.poi?.length ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.policies?.length ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.reviews?.length ? "🟢" : "🔴"}</td>
                    <td>{info[lang]?.reviewSummary?.vote ? "🟢" : "🔴"}</td>
                    <td>
                      <div className="d-flex gap-1">
                        <Button
                          onClick={async () => {
                            await fillIndex(key, false);
                            loadCollections(false);
                          }}
                          // loading={loadingIndex === key}
                          // small
                          // text="Fill"
                        >
                          Fill
                        </Button>
                        <Button
                          // loading={loadingIndex === key}
                          onClick={async () => {
                            await fillIndex(key, true);
                            loadCollections(false);
                          }}
                          // small
                          // text="Fill ov."
                          // error
                        >
                          Fill ov.
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table> */}
      {/* <PaginationMod
            page={page}
            size={size}
            total={total}
            onSizeChange={onSizeChange}
            onPageChange={onPageChange}
          /> */}
    </div>
  );
}
