import { iCollection } from "@/services/collection";
import {
  iBid,
  iNft,
  NFT_OWNER_TYPE,
  putNftForSalePending,
} from "@/services/nft";
import { datetimeToString } from "@/utils/generic";
import { ColumnDef } from "@tanstack/react-table";

import { TFunction } from "i18next";
import helpers from "@/components/helpers/helpers";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Button } from "@/components/ui/button";
import { LucideEdit, LucideEye, MoreVertical } from "lucide-react";
import DetailHotel from "@/components/collection/DetailHotel";
import CopyId from "@/components/helpers/CopyId";
import { useModal } from "@/context/ModalContext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DataTableColumnHeader } from "@/components/datatable/data-table-column-header";
import { Badge } from "@/components/ui/badge";
import HotelIntegrationForm from "./forms/HotelIntegrationForm";

export const getColumns = (
  t: TFunction,
  refresh: () => {}
): ColumnDef<any>[] => {
  return [
    {
      header: "#",
      accessorFn: (nft) => nft?.shortId,
    },
    {
      id: "hotelName",
      header: t("hotelIntegration.name"),
      accessorKey: "hotelName",
      // accessorFn: (nft) => datetimeToString(nft._createdAt, undefined, true),
    },
    {
      id: "bookingEngine",
      header: t("hotelIntegration.bookingEngine"),
      accessorKey: "bookingEngine",
      // accessorFn: (nft) => datetimeToString(nft._createdAt, undefined, true),
      cell: ({ getValue }) => (
        <Badge variant="outline">{getValue() as string}</Badge>
      ),
    },
    {
      id: "bookingEngineHotelId",
      header: t("hotelIntegration.bookingEngineHotelId"),
      accessorKey: "bookingEngineHotelId",
      // accessorFn: (nft) => datetimeToString(nft._createdAt, undefined, true),
    },
    {
      id: "enable",
      header: t("hotelIntegration.enable"),
      accessorFn: (hotelIntegration) => hotelIntegration.enable,
      cell: ({ getValue }) => {
        return (
          <Badge variant={getValue() === true ? "default" : "outline"}>
            {getValue() === true ? t("enabled") : t("disabled")}
          </Badge>
        );
      },
    },
    {
      id: "actions",
      accessorKey: "id",
      header: t("nft.taks_actions"),
      cell: function render({ getValue, row }) {
        const { showModal, hideModal } = useModal();

        return (
          <div className="flex flex-row flex-nowrap gap-0">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(
                  <HotelIntegrationForm
                    data={row.original}
                    onSuccess={() => {
                      hideModal();
                      refresh();
                    }}
                  />,
                  {
                    title: (
                      <>
                        {t("nft.update")}{" "}
                        <CopyId resourceId={row.original._id!} />
                      </>
                    ),
                    description: null,
                  }
                );
              }}
            >
              <LucideEdit size={16} />
            </Button>
          </div>
        );
      },
    },
  ];
};
