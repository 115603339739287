import { H4 } from "@/components/typography/Typography";
import React, { useState, useEffect } from "react";
import CollectionImages from "./CollectionImages";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Separator } from "@radix-ui/react-separator";
import { iCollection, patchCollection } from "@/services/collection";
import { Loader2 } from "lucide-react";
import { useModal } from "@/context/ModalContext";
// import { getCollection } from "@/services/collection";

export default function ImagesForm({
  collection,
  nextPage,
  onRefresh,
}: {
  collection: iCollection;
  nextPage?: string;
  onRefresh: Function;
}) {
  const [coverImage, setCoverImage] = useState<Array<string>>([]);
  const [secondaryImages, setSecondaryImages] = useState<Array<string>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const saveImages = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await patchCollection({
        images: [...coverImage, ...secondaryImages],
        collectionId: collection._id,
      });
      await onRefresh();
      // await patchCollection({ secondaryImages: allImgs, collectionId: collectionId });
      // setIsSaveEnabled(false);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    saveImages(e);
    hideModal();
    if (nextPage && !isLoading) {
      navigate(nextPage);
    }
  };

  // useEffect(() => {
  //   onRefresh();
  // }, []);

  useEffect(() => {
    if (coverImage && coverImage.length == 0) {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  }, [coverImage]);

  useEffect(() => {
    if (collection && collection.images) {
      setSecondaryImages(collection.images.slice(1));
      setCoverImage([collection.images[0]]);
    }
  }, [collection]);

  return (
    <form className="space-y-2">
      <div>
        <H4>{t("onboarding.step2_cover")} *</H4>
        <p>{t("onboarding.step2_cover_tip")}</p>
        <CollectionImages
          saveBtn={false}
          images={coverImage || []}
          setImages={setCoverImage}
          required={true}
          logo={false}
          cover={true}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          collection={collection}
        />
      </div>
      <div>
        <H4>{t("onboarding.step2_other_cover")}</H4>
        <p>{t("onboarding.step2_other_cover_tip")}</p>
        <CollectionImages
          saveBtn={true}
          images={secondaryImages || []}
          setImages={setSecondaryImages}
          coverImage={coverImage}
          disableBtn={disableBtn}
          logo={false}
          cover={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          collection={collection}
        />
      </div>
      {/* <div>
        <H4>Logo</H4>
        <p>Carica il logo della tua struttura in formato jpg, png o svg.</p>
        <div>
          <CollectionImages
            saveBtn={true}
            secondaryImages={logoImage}
            setSecondaryImages={setLogoImage}
            disableBtn={disableBtn}
            logo={true}
            cover={false}
            collection={collection}
          />
        </div>
      </div> */}
      <div className="flex flex-col">
        <Separator id="separator" className="mb-8 bg-gray-200 h-0.5" />
        <div className="flex flex-row justify-between">
          {nextPage && (
            <Button
              className=""
              type="button"
              onClick={() =>
                navigate(`/collection/${collection._id}/infos/details`)
              }
            >
              {t("auth.back")}
            </Button>
          )}
          {!isLoading ? (
            <Button
              variant="takyon"
              type="submit"
              onClick={(e: any) => onSubmit(e)}
              disabled={coverImage.length == 0}
            >
              {t("buttons.save_and_continue")}
            </Button>
          ) : (
            <Button disabled className="min-w-40">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("buttons.loading")}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}
