import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useDashboardData } from "@/hooks/dashboard";
import getLink, { getDefaultLink, LINK_TYPE } from "@/lib/links";
import {
  COLLECTION_PERMISSIONS,
  iCollection,
  onBoardingCompleted,
} from "@/services/collection";
import { iGroup } from "@/services/groups";
import { ChevronDown, ChevronUp, Group, Hotel } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Skeleton } from "../ui/skeleton";
import { useRbac } from "@/hooks/rbac";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

interface CollectionSelectorProps {
  collection: iCollection;
  className?: string;
}

export default function CollectionSelector({
  collection,
  className,
}: CollectionSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { can } = useRbac();

  const { groups, collectionsGroupedByGroupId, isLoading } = useDashboardData();

  const handleCollectionClick = (collection: iCollection) => {
    const onboardingComplete = onBoardingCompleted(collection);

    if (!onboardingComplete && can(COLLECTION_PERMISSIONS.update, collection)) {
      navigate(
        getLink(LINK_TYPE.COLLECTION_ONBOARDING, {
          __COLLECTIONID__: collection._id,
        })
      );
    } else {
      const link = getDefaultLink(collection);
      navigate(link);
    }
  };

  return isLoading ? (
    <Skeleton className="w-[200px] h-[28px]" />
  ) : (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild className="w-fit min-w-[200px]">
        <div
          className={`flex items-start cursor-pointer text-xl font-medium ${
            className || ""
          }`}
        >
          <p>
            {collection
              ? collection.name
              : t("collection_selector_placeholder")}
          </p>
          {open ? (
            <ChevronUp className="ml-2 h-4 w-4 mt-2" />
          ) : (
            <ChevronDown className="ml-2 h-4 w-4 mt-2" />
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
        <DropdownMenuItem onClick={() => navigate(getLink(LINK_TYPE.HOME))}>
          <Group size={18} className="text-gray-400 mr-2" />
          <p className="text-sm text font-medium">{t("collection_view_all")}</p>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {groups.map((group: iGroup, index: number) => {
          const collections = collectionsGroupedByGroupId[group._id] || [];

          return (
            <div key={group._id}>
              {index > 0 && <DropdownMenuSeparator />}
              <DropdownMenuLabel>{group.name}</DropdownMenuLabel>
              {collections.map((collection: iCollection) =>
                !can(COLLECTION_PERMISSIONS.update, collection) &&
                !onBoardingCompleted(collection) ? (
                  <TooltipProvider key={collection._id}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <DropdownMenuItem key={collection._id}>
                          <Hotel size={18} className="text-gray-400 mr-2" />
                          <p className="text-sm text text-gray-400">
                            {collection.name}
                          </p>
                        </DropdownMenuItem>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{t("tooltips.collection_missing_permission")}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <DropdownMenuItem
                    key={collection._id}
                    onClick={() => handleCollectionClick(collection)}
                  >
                    <Hotel size={18} className="text-gray-400 mr-2" />
                    <p className="text-sm text">{collection.name}</p>
                  </DropdownMenuItem>
                )
              )}
            </div>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
