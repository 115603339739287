import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { createPaymentMethod, getSubscriptionLink } from "@/services/payment";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { iCollection } from "@/services/collection";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { useLocation } from "react-router-dom";
import { getStripeRedirectUrl } from "@/lib/links";

export function PaymentMethodsModal({
  collection,
  onSuccess,
}: {
  collection: iCollection;
  onSuccess(): void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const paymentDefaultValue = collection?.name
    ? `${t("paymentmethods.prefix")} ${collection.name}`
    : "";

  const [name, setName] = useState(paymentDefaultValue);

  // current route
  const { pathname } = useLocation();

  const { hideModal } = useModal();

  // Define form schema using zod
  const formSchema = z.object({
    vat: z.string().min(1, t("payment.vat_error")),
    fiscalCode: z.string().optional(),
    name: z.string().min(1, t("payment.business_name_error")),
    zip: z.string().min(1, t("payment.zip_error")),
    city: z.string().optional(),
    address: z.string().min(1, t("payment.address_error")),
    province: z.string().min(1, t("payment.province_error")),
    contactName: z.string().optional(),
    contactPhone: z.string().optional(),
    recipientCode: z.string().min(1, t("payment.recipient_code_error")),
    pec: z.string().email(t("payment.pec_error")),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vat: "",
      fiscalCode: "",
      name: "",
      zip: "",
      city: "",
      address: "",
      province: "",
      contactName: "",
      contactPhone: "",
      recipientCode: "",
      pec: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      const payload = {
        name,
        billingDetails: values,
      };

      const paymentMethod = await createPaymentMethod(payload);

      // get current route to build the redirect
      const redirectForStripe = getStripeRedirectUrl(
        pathname,
        paymentMethod._id
      );

      const link = await getSubscriptionLink(
        paymentMethod._id,
        redirectForStripe
      );

      console.log("paymentMethod", paymentMethod);
      console.log("getSubscriptionLink", link);

      onSuccess();

      window.location.href = link;
    } catch (error: any) {
      console.log(error);
      // Handle errors if needed
    }
    setIsLoading(false);
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.pm_name")} {t("form.required")}
        </label>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={t("payment.placeholder_pm_name")}
          required
          type="text"
          className="w-full"
        />
      </div>

      <Separator />

      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.vat")} {t("form.required")}
        </label>
        <Input
          {...form.register("vat")}
          placeholder={t("payment.placeholder_vat")}
          required
          type="text"
          className="w-full"
        />
        {form.formState.errors.vat && (
          <p className="text-red-500 text-sm">
            {form.formState.errors.vat.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.fiscal_code")}
        </label>
        <Input
          {...form.register("fiscalCode")}
          placeholder={t("payment.placeholder_fiscal_code")}
          type="text"
          className="w-full"
        />
        {form.formState.errors.fiscalCode && (
          <p className="text-red-500 text-sm">
            {form.formState.errors.fiscalCode.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium mb-2">
          {t("payment.business_name")} {t("form.required")}
        </label>
        <Input
          {...form.register("name")}
          placeholder={t("payment.placeholder_business_name")}
          required
          type="text"
          className="w-full"
        />
        {form.formState.errors.name && (
          <p className="text-red-500 text-sm">
            {form.formState.errors.name.message}
          </p>
        )}
      </div>

      <div className="d-flex gap-2">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">
            {t("payment.zip")} {t("form.required")}
          </label>
          <Input
            {...form.register("zip")}
            placeholder={t("payment.placeholder_zip")}
            required
            type="text"
            className="w-full"
          />
          {form.formState.errors.zip && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.zip.message}
            </p>
          )}
        </div>

        <div className="w-full mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("payment.city")} {t("form.required")}
          </label>
          <Input
            {...form.register("city")}
            placeholder={t("payment.placeholder_city")}
            type="text"
            className="w-full"
            required
          />
          {form.formState.errors.city && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.city.message}
            </p>
          )}
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">
            {t("payment.address")} {t("form.required")}
          </label>
          <Input
            {...form.register("address")}
            placeholder={t("payment.placeholder_address")}
            required
            type="text"
            className="w-full"
          />
          {form.formState.errors.address && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.address.message}
            </p>
          )}
        </div>

        <div className="w-full mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("payment.province")} (es. MI) {t("form.required")}
          </label>
          <Input
            {...form.register("province")}
            placeholder={t("payment.placeholder_province")}
            required
            type="text"
            className="w-full"
          />
          {form.formState.errors.province && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.province.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex gap-2 ">
        <div className="flex-1">
          <label className="block text-sm font-medium mb-2">
            {t("payment.contact_name")} {t("form.required")}
          </label>
          <Input
            {...form.register("contactName")}
            placeholder={t("payment.placeholder_contact_name")}
            type="text"
            className="w-full"
            required
          />
          {form.formState.errors.contactName && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.contactName.message}
            </p>
          )}
        </div>

        <div className="flex-1">
          <label className="block text-sm font-medium mb-2">
            {t("payment.contact_phone")}
          </label>
          <Input
            {...form.register("contactPhone")}
            placeholder={t("payment.placeholder_contact_phone")}
            type="text"
            className="w-full"
          />
          {form.formState.errors.contactPhone && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.contactPhone.message}
            </p>
          )}
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">
            {t("payment.recipient_code")} {t("form.required")}
          </label>
          <Input
            {...form.register("recipientCode")}
            placeholder={t("payment.placeholder_recipient_code")}
            required
            type="text"
            className="w-full mt-4"
          />
          {form.formState.errors.recipientCode && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.recipientCode.message}
            </p>
          )}
        </div>

        <div className="w-full mt-4">
          <label className="block text-sm font-medium mb-2">
            {t("payment.pec")} {t("form.required")}
          </label>
          <Input
            {...form.register("pec")}
            placeholder={t("payment.placeholder_pec")}
            required
            type="email"
            className="w-full"
          />
          {form.formState.errors.pec && (
            <p className="text-red-500 text-sm">
              {form.formState.errors.pec.message}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-between pt-4">
        <Button
          variant="destructive"
          onClick={() => {
            hideModal();
          }}
        >
          {t("paymentmethods.cancel")}
        </Button>
        <LoadingSubmitButton
          variant={"takyon"}
          type="submit"
          isLoading={isLoading}
        >
          {isLoading
            ? t("paymentmethods.loading")
            : t("paymentmethods.save_button")}
        </LoadingSubmitButton>
      </div>
    </form>
  );
}
