import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./nfts-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { getNftsPaginated, iNft, NFT_SELL_STATUS } from "@/services/nft";

interface Filters {
  status?: string[];
}

export default function NftsDatatable() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState({});

  const [filters, setFilters] = useState<Filters>({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iNft[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    let queryPayload: any = {};

    if (searchTerm) {
      if (!queryPayload.$and) {
        queryPayload.$and = [];
      }
      const searchConditions = [
        { _id: { $regex: searchTerm, $options: "i" } },
        { shortId: { $regex: searchTerm, $options: "i" } },
        { owner: { $regex: searchTerm, $options: "i" } },
        { "payload.reference": { $regex: searchTerm, $options: "i" } },
        { "payload.room": { $regex: searchTerm, $options: "i" } },
      ];
      queryPayload.$and.push({ $or: searchConditions });
    }

    // filters
    // Aggiungi le condizioni dei filtri alla query
    Object.keys(filters).forEach((key) => {
      if (!queryPayload.$and) {
        queryPayload.$and = [];
      }
      if (key === "status") {
        const values = filters[key] as string[];
        // values is an array of selected values
        values.forEach((value: string) => {
          if (value === "active") {
            queryPayload.$and.push({ "payload.checkout": { $gt: new Date() } });
          } else if (value === "sale") {
            queryPayload.$and.push({ sellStatus: NFT_SELL_STATUS.FOR_SALE });
          } else if (value === "past") {
            queryPayload.$and.push({ "payload.checkout": { $lt: new Date() } });
          }
        });
      }
    });

    const payload: any = {
      page: page + 1,
      size: pageSize,
      query: {
        // ...filters,
        // ...sorting,
        ...queryPayload,
      },
    };

    console.log("NftsDatatable - payload", payload);

    try {
      const result = await getNftsPaginated(payload);

      const pageCount = Math.ceil(result.total / pageSize);

      setData(result.data);
      setPageCount(pageCount);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sorting, searchTerm]);

  const columns = useMemo(() => getColumns(t, fetchData), []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toolbarFilters = useMemo(
    () => [
      {
        // column: "payload_status",
        type: "select",
        title: "Status",
        options: [
          { label: "Active", value: "active" },
          { label: "For Sale", value: "sale" },
          { label: "Past", value: "past" },
        ],
        key: "status",
        onChange: (values: string[]) => {
          const activeFilters = { ...filters };
          activeFilters["status"] = values;
          console.log("activeFilters", activeFilters);
          //setFilters(activeFilters);
        },
      },
    ],
    []
  );

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={true}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        // onFiltersChange={setFilters}
        onFiltersChange={(filters) => {
          console.log(
            "filters are handled with toolbarFilters object",
            filters
          );
        }}
        filters={toolbarFilters}
      />
    </div>
  );
}
