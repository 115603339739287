import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "@/components/ui/input";

import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { Button } from "../ui/button";
import { DialogClose } from "../ui/dialog";
import { DatePicker } from "../form_fields/DatePicker";
import { DateRangePicker } from "../form_fields/DateRangePicker";
import { iCollection } from "@/services/collection";
import { useEffect, useMemo, useState } from "react";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { RoomsSuggestion } from "../form_fields/RoomsSuggestion";
import { Textarea } from "../ui/textarea";
import { LANG } from "@/services/i18next";

import { iNft, payloadHotelType } from "@/services/nft";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { H4 } from "../typography/Typography";
import { formHotelSchema, FormSchemaHotel } from "./forms/validationSchema";
import SelectOrUpdateImages from "../form_fields/SelectOrUpdateImages";

export default function FormNft({
  collection = null,
  nft,
  submitBtnLabel,
  showCloseBtn = false,
  // isSubmitting TODO
  onSubmit,
}: {
  nft?: iNft<payloadHotelType>;
  collection?: iCollection | null;
  submitBtnLabel?: string;
  showCloseBtn?: boolean;
  onSubmit: (data: FormSchemaHotel) => void;
}) {
  const { t } = useTranslation();

  const [nftImages, setNftImages] = useState<string[]>([]);

  const refinedSchema = formHotelSchema.refine(
    (data) => {
      if (isEditMode) {
        return !!data.owner;
      }
      return true;
    },
    {
      message: "Owner is required in edit mode",
      path: ["owner"],
    }
  );

  const form = useForm<FormSchemaHotel>({
    resolver: zodResolver(refinedSchema),
    defaultValues: {
      lockDate: undefined,
      _createdAt: undefined,
      rooms: [{ name: "", guestsAdults: 0, guestsKids: 0 }],
      checkin: {
        from: undefined,
        to: undefined,
      },
      fakeOwnerName: nft?.fakeOwnerName || "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);

  // lockDate is one day before checkin, autofill it
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === "checkin" && value.checkin?.from) {
        const lockDate = new Date(value.checkin.from);
        lockDate.setDate(lockDate.getDate() - 1);
        form.setValue("lockDate", lockDate);
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  // if editMode load nft data
  useEffect(() => {
    if (nft) {
      const nftToForm = {
        _createdAt: new Date(nft._createdAt),
        owner: nft.owner,
        lang: nft.lang,
        reference: nft.payload?.reference, //TODO - check because if in edit mode is not possible that is empty
        lockDate: new Date(nft.lockDate),
        images: nft.images,
        checkin: {
          from: new Date(String(nft.payload?.checkin)),
          to: new Date(String(nft.payload?.checkout)),
        },
        board: nft.payload?.board,
        extra: nft.payload?.extra,
        rooms: nft.payload?.rooms,
        price: nft.originalPrice,
        fakeOwnerName: nft?.fakeOwnerName,
      };

      // prefill formState with nft data
      form.reset(nftToForm);

      setIsEditMode(true);
    }
  }, [nft, form]);

  // list of languages
  const i18langs = useMemo(() => {
    return Object.keys(LANG).map((lang) => {
      return {
        text: lang,
        value: lang,
      };
    });
  }, [LANG]);

  // used for rooms array field
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "rooms",
  });

  useEffect(() => {
    // load existing collection data
    // with nft imageUrl
    const nftImages = nft?.images?.length ? nft.images : [];
    setNftImages(nftImages);
  }, [nft]);
  //collection / rooms suggestion

  //console.log("isEditMode", isEditMode);
  //console.log("formState", form.formState.errors, form.getValues());

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, (errors) => {
          //console.log("Form errors:", errors);
          const firstError = Object.keys(errors)[0];
          if (firstError) {
            //console.log("Scrolling to error:", firstError);
            const element = document.querySelector(`.${firstError}`);
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
          }
        })}
        className="space-y-3"
      >
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="reference"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("nft.reference")}*</FormLabel>
                <FormControl>
                  <Input
                    className="reference"
                    placeholder={t("nft.reference")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="_createdAt" // secondo me meglio dedicare un altro campo...
            render={({ field }) => (
              <FormItem className="flex-column">
                <FormLabel>{t("nft.booking_date")}</FormLabel>
                <FormControl>
                  <DatePicker className="w-full _createdAt" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex gap-3">
          {isEditMode && (
            <FormField
              control={form.control}
              name="owner"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>{t("nft.owner")}</FormLabel>
                  <FormControl>
                    <Input
                      className="owner"
                      placeholder={t("nft.owner")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="lang"
            render={({ field }) => (
              <FormItem className="flex-grow-0 lang">
                <FormLabel>{t("nft.lang")}</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={t("form.select")} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {i18langs.map((lang) => (
                      <SelectItem key={lang.value} value={lang.value}>
                        {lang.text}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={`fakeOwnerName`}
            render={({ field }) => (
              <FormItem className="flex-1 fakeOwnerName">
                <FormLabel>
                  {t("nft.fakeOwnerName")} {t("form.optional")}
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder={t("nft.fakeOwnerName_placeholder")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="checkin"
            render={({ field }) => (
              <FormItem className="checkin">
                <FormLabel>
                  {t("nft.checkin")} {t("nft.checkout")}
                </FormLabel>
                <FormControl>
                  <DateRangePicker {...field} />
                </FormControl>
                <FormDescription>
                  {t("nft.checkin_checkou_descr")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lockDate" // secondo me meglio dedicare un altro campo...
            render={({ field }) => (
              <FormItem className="flex-column lockDate">
                <FormLabel>{t("nft.lock_date")}</FormLabel>
                <FormControl>
                  <div>
                    <DatePicker className="w-full" {...field} />
                  </div>
                </FormControl>
                <FormDescription>
                  {t("nft.lock_date_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem className="price">
                <FormLabel>{t("nft.price")} (€)</FormLabel>
                <FormControl>
                  <Input placeholder={t("nft.price")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="board"
          render={({ field }) => (
            <FormItem className="board">
              <FormLabel>{t("nft.board")}</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t("form.select")} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="RO">{t("nft.board_ro")}</SelectItem>
                  <SelectItem value="BNB">{t("nft.board_bnb")}</SelectItem>
                  <SelectItem value="HB">{t("nft.board_hb")}</SelectItem>
                  <SelectItem value="FB">{t("nft.board_fb")}</SelectItem>
                  <SelectItem value="AI">{t("nft.board_ai")}</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="images"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("nft.images")} {t("form.optional")}
              </FormLabel>
              <FormControl>
                <SelectOrUpdateImages
                  existingImages={nftImages}
                  onSelectedImages={(selectedImages) => {
                    form.setValue("images", selectedImages);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="pt-5">
          <H4>{t("nft.rooms")}</H4>
          <Separator className="my-1" />
          {fields.map((field, index) => (
            <div className="flex items-end space-x-2">
              <FormField
                control={form.control}
                name={`rooms.${index}.name`}
                render={({ field, fieldState }) => (
                  <FormItem className="w-full">
                    <FormLabel>
                      {t("nft.room_type")} {field.value}
                    </FormLabel>
                    <FormControl className="rooms">
                      <RoomsSuggestion
                        collection={collection!}
                        selected={field.value}
                        onSelect={(value) => {
                          field.onChange(value ? value : undefined);
                        }}
                      />
                    </FormControl>
                    <FormMessage>{fieldState.error?.message}</FormMessage>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`rooms.${index}.guestsAdults`}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("nft.room_guests_adults")}</FormLabel>
                    <FormControl>
                      <Input
                        className="guestsAdults"
                        type="text"
                        placeholder={t("nft.room_guests_adults")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`rooms.${index}.guestsKids`}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t("nft.room_guests_kids")}</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder={t("nft.room_guests_kids")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                className="ml-auto"
                variant="ghost"
                size="icon"
                type="button"
                onClick={() => {
                  if (confirm(t("alerts.are_you_sure"))) {
                    remove(index);
                  }
                }}
              >
                <X size={16} />
              </Button>
            </div>
          ))}
          <div className="mt-5 flex">
            <Button
              variant="gray"
              className="ml-auto"
              size="sm"
              type="button"
              onClick={() =>
                append({
                  name: "",
                  guestsAdults: Number(),
                  guestsKids: Number(),
                })
              }
            >
              {t("nft.add_room")}
            </Button>
          </div>
        </div>

        <FormField
          control={form.control}
          name="extra"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("nft.extra")} {t("form.optional")}
              </FormLabel>
              <FormControl>
                <Textarea placeholder={t("nft.note")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex mt-10">
          {showCloseBtn && (
            <DialogClose asChild>
              <Button type="button" variant="takyon">
                {t("buttons.close")}
              </Button>
            </DialogClose>
          )}
          <LoadingSubmitButton
            className="ml-auto"
            variant="takyon"
            type="submit"
            isLoading={isSubmitting}
          >
            {submitBtnLabel ?? t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
