import axios from "axios";
import { Pagination } from "../utils/Pagination";

export interface iHotelIntegrationConfig {
  _id: string;
  bookingEngine: string;
  hotelName: string;
  bookingEngineHotelId: string;
  structureToken?: string;
  structureUser?: string;
  structurePassword?: string;
  enable: boolean;
}

export async function getHotelPaginated(): Promise<
  Pagination<iHotelIntegrationConfig>
> {
  // page: number,
  // size: number
  const response = await axios.get("/hotelBookingIntegrations/getListHotel");
  return response.data;
}

export async function registerNewHotelBooking(payload: any) {
  const response = await axios.post(`/hotelBookingIntegrations/registerHotel`, {
    payload,
  });
  return response.data;
}

export async function updateHotelBooking(id: string, payload: any) {
  const response = await axios.patch(
    `/hotelBookingIntegrations/updateHotel/${id}`,
    {
      payload,
    }
  );
  return response.data;
}

export async function enableDisableHotelBooking(payload: any) {
  const response = await axios.post(
    `/hotelBookingIntegrations/enableDisableHotel`,
    {
      payload,
    }
  );
  return response.data;
}

export async function deleteHotelBooking(idCollection: string) {
  const response = await axios.delete(`/hotelBookingIntegrations/deleteHotel`, {
    data: { idCollection },
  });
  return response.data;
}
