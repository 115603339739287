import "./App.css";
import "./global.css";

import { BrowserRouter as Router, useLocation } from "react-router-dom";
import AppRoutes from "./config/routes";
import { TitleProvider } from "./context/SectionTitleContext";
import { Provider } from "react-redux";
import store from "./store/store";
import { ModalProvider } from "./context/ModalContext";
import Modal from "./components/layout/Modal";
import JuneAnalytics from "./components/analytics/JuneAnalytics";

// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./context/AuthContext";
import { FeedbackProvider } from "./context/FeedbackContext";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    // eslint-disable-next-line
  }, [pathname]);
  return <></>;
}

const queryClient = new QueryClient();
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://63adf1285e07536976ba0f02a5c8a1eb@o4508137813311488.ingest.de.sentry.io/4508448620806224",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {import.meta.env.VITE_ENV === "production" && (
          <JuneAnalytics writeKey="wk-b01c01f5ebad4847a472e491a04f6e1b" />
        )}
        <Router>
          <AuthProvider>
            <ModalProvider>
              <FeedbackProvider>
                <TitleProvider>
                  <AppRoutes />
                </TitleProvider>
                <Modal />
              </FeedbackProvider>
            </ModalProvider>
          </AuthProvider>
          <ScrollToTop />
        </Router>
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
