import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { setIsLoading } from "../../../../store/appSlice";
import {
  deleteFaqInCategory,
  getFaqsInCategory,
  iFaq,
  iSupportFaq,
} from "../../../../services/supportfaqs";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { Separator } from "@/components/ui/separator";
import { Trash2 } from "lucide-react";
import FaqForm from "./FaqForm";
import DeleteModal from "@/components/ui/deleteModal";

interface CategoryFaqsProps {
  onExit: Function;
  category: iSupportFaq;
}

export default function Faqs({ onExit, category }: CategoryFaqsProps) {
  const { t } = useTranslation();

  const [faqs, setFaqs] = useState<iFaq[]>([]);
  const { showModal, hideModal } = useModal();

  const loadFaqs = async () => {
    setIsLoading(true);
    try {
      const data = await getFaqsInCategory(category._id);
      setFaqs(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadFaqs();
  }, []);

  return (
    <main>
      <div className="container">
        <div className="content">
          <section id="support-section" className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <Button onClick={() => onExit?.()}>{t("payment.back")}</Button>

              <Button
                variant="takyon"
                onClick={(e: any) => {
                  e.stopPropagation();
                  showModal(
                    <FaqForm category={category} refresh={loadFaqs} />,
                    {
                      title: t("support.create_faq"),
                      description: " ",
                    }
                  );
                }}
                className="create-btn"
                // text={String(t("support.create_faq"))}
              >
                {t("support.create_faq")}
              </Button>
            </div>

            <span className="font-semibold">
              {category.name} {`( ${category.lang} )`}
            </span>

            <Separator className="mt-6 mb-8" />

            <div className="flex flex-col gap-4">
              {faqs.map((faq: iFaq) => (
                <div
                  key={Math.random()}
                  className="support-item"
                  onClick={() =>
                    showModal(
                      <FaqForm
                        faq={faq}
                        category={category}
                        refresh={loadFaqs}
                      />,
                      {
                        title: t("support.edit_faq"),
                        description: " ",
                      }
                    )
                  }
                >
                  <div className="cursor-pointer hover:bg-slate-100 p-4 rounded-md">
                    <div className="italic font-semibold mb-2">
                      {faq.question}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                  </div>

                  <div className="rigth">
                    <Trash2
                      size={16}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        showModal(
                          <DeleteModal
                            onRemove={async () => {
                              await deleteFaqInCategory({
                                _id: category._id,
                                _faqId: faq._faqId,
                              });
                              hideModal();
                              loadFaqs();
                            }}
                          />,
                          {
                            title: t("modals.remove_modal"),
                            description: t("modals.remove_modal_text"),
                          }
                        );
                      }}
                      className="ml-auto my-4 cursor-pointer hover:text-red-600 mx-4"
                    />
                  </div>
                  <Separator className="mt-6 mb-8" />
                </div>
              ))}
            </div>

            {faqs.length === 0 && <div>{String(t("support.no_faqs"))}</div>}
          </section>
        </div>
      </div>
    </main>
  );
}
