import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { iCollection, setTrialDate } from "@/services/collection";
import { useRbac } from "@/hooks/rbac";
import { iGroup } from "@/services/groups";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import { LargeText } from "../typography/Typography";
import { useTranslation } from "react-i18next";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { DatePicker } from "../form_fields/DatePicker";

const formSchema = z.object({
  trialEndDate: z.date(),
});

export default function FormSettingsTrialPeriod({
  collection,
  group,
  onRefresh,
}: {
  collection: iCollection;
  group: iGroup;
  onRefresh(): void;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const { t } = useTranslation();
  const { can } = useRbac();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    form.reset({
      trialEndDate: collection.trialEndDate,
    });
  }, [collection]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsSubmitting(true);

    try {
      await setTrialDate({
        collectionId: collection._id,
        trialEndDate: data.trialEndDate,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="space-y-3">
          <div>
            <FormField
              control={form.control}
              name="trialEndDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("collection.trialEndDate")}</FormLabel>
                  <FormControl>
                    <div>
                      <DatePicker {...field} />
                    </div>
                  </FormControl>
                  <FormDescription>
                    {t("collection.trialEndDate_description")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-row gap-4">
            <LoadingSubmitButton
              className="ml-auto"
              variant="takyon"
              type="submit"
              isLoading={isSubmitting}
            >
              {t("buttons.save")}
            </LoadingSubmitButton>
          </div>
        </div>
      </form>
    </Form>
  );
}
