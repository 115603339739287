import { Button } from "@/components/ui/button";
import { t, TFunction } from "i18next";

import { deletePartner, iPartner } from "@/services/partners";

import PartnerForm from "./PartnerForm";
import CopyId from "@/components/helpers/CopyId";
import { LucideEdit, MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ColumnDef } from "@tanstack/react-table";
import DeleteModal from "@/components/ui/deleteModal";

export const getColumns = (
  t: TFunction,
  showModal: (content: React.ReactNode, options?: any) => void,
  hideModal: () => void,
  onRefresh: () => void
): ColumnDef<iPartner>[] => {
  return [
    {
      header: "ID",
      accessorKey: "_id",
      cell: ({ getValue }) => <CopyId resourceId={getValue() as string} />,
    },
    {
      header: t("partners.name"),
      accessorKey: "name",
    },
    {
      header: t("partners.location"),
      accessorKey: "location.label",
    },
    {
      header: t("partners.stars"),
      accessorKey: "stars",
    },
    {
      header: t("partners.logo"),
      accessorKey: "logo",
      cell: function render(props: any) {
        return (
          <img
            style={{ width: "50px", maxHeight: "50px" }}
            src={props.getValue()}
            alt="takyon partner logo"
          />
        );
      },
    },
    {
      header: t("partners.image"),
      accessorKey: "image",
      cell: function render(props: any) {
        return (
          <img
            style={{ width: "50px", maxHeight: "50px" }}
            src={props.getValue()}
            alt="takyon partner"
          />
        );
      },
      // accessorFn: (row: any) => row.image || 'No image'
    },
    {
      header: t("partners.liveDate"),
      accessorKey: "liveDate",
      accessorFn: (row: iPartner) => {
        console.log("row.liveDate : ", row.liveDate);
        return row.liveDate ? new Date(row.liveDate).toLocaleDateString() : "-";
      },
    },
    {
      header: `${t("tables.actions")}`,
      accessorKey: "",
      // accessorFn: (row: any) => row.collectionNames.join("\n"),
      cell: function render({ row }) {
        let id = row.original._id;

        return (
          <div className="flex gap-2">
            <Button
              variant="ghost"
              onClick={() =>
                showModal(
                  <PartnerForm onSuccess={onRefresh} _partner={row.original} />,
                  {
                    title: t("partners.edit_partner"),
                    description: "",
                  }
                )
              }
              // small
              // text={"Edit"}
            >
              <LucideEdit size={16} />
            </Button>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() =>
                    showModal(
                      <DeleteModal
                        onRemove={async () => {
                          await deletePartner({ _id: row.original._id });
                          hideModal();
                          onRefresh();
                        }}
                      />,
                      {
                        title: t("modals.remove_modal"),
                        description: t("modals.remove_modal_text"),
                      }
                    )
                  }
                >
                  {t("tables.delete")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];
};
