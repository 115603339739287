import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import ImagesForm from "./ImagesForm";
import { CollectionPageChildProps } from "../CollectionPage";
import { useTranslation } from "react-i18next";

export default function Images({
  collection,
  onRefresh,
}: CollectionPageChildProps) {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();

  useEffect(() => {
    setBackBtn(true);
    setProgress(40);
  }, []);

  useEffect(() => {
    if (collection) {
      setTitleCollectionPage(
        `${t("collection.hotel_images")} - ${collection!.name}`
      );
    }
  }, [collection]);

  return (
    <ImagesForm
      onRefresh={onRefresh}
      collection={collection!}
      nextPage={`/collection/${collection?._id}/infos/team`}
    />
  );
}
