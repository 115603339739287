import { useEffect } from "react";

interface JuneAnalyticsProps {
  writeKey: string;
}

declare global {
  interface Window {
    analytics: any;
  }
}

const JuneAnalytics: React.FC<JuneAnalyticsProps> = ({ writeKey }) => {
  useEffect(() => {
    window.analytics = {};
    const juneify = (writeKey: string) => {
      console.log("Initializing June Analytics..."); // Log inizializzazione
      window.analytics._writeKey = writeKey;
      const script = document.createElement("script");
      script.type = "application/javascript";
      script.onload = function () {
        console.log("June script loaded successfully"); // Log successo caricamento
        window.analytics.debug(true);

        // Configurazione esplicita del dominio
        window.analytics.track("app_initialized", {
          domain: window.location.hostname,
          environment: "development",
          timestamp: new Date().toISOString(),
          app: "takyon-dashboard",
          version: "3.0",
        });

        // Track page view con dettagli aggiuntivi
        window.analytics.page({
          domain: window.location.hostname,
          path: window.location.pathname,
          url: window.location.href,
          title: document.title,
          environment: "development",
          app: "takyon-dashboard",
        });

        console.log("June Analytics initialized with key:", writeKey);
        console.log("Test events sent to June Analytics");
      };
      script.onerror = function (error) {
        console.error("Error loading June script:", error); // Log errori
      };
      script.src =
        "https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js";
      const first = document.getElementsByTagName("script")[0];
      if (first && first.parentNode) {
        first.parentNode.insertBefore(script, first);
      } else {
        document.head.appendChild(script);
      }
    };
    juneify(writeKey);
  }, [writeKey]);

  return null;
};

export default JuneAnalytics;
