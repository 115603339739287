import { ArrowDown, ArrowUp, Trash2 } from "lucide-react";

const DataAnalysisModuleTypeCard = ({
  name,
  setMaskData,
  maskData,
  setDisabled,
}: {
  name: string;
  setMaskData: ({}: {}) => void;
  maskData: any;
  setDisabled: ({}) => void;
}) => {
  const deleteType = (name: string) => {
    let res = maskData.modules.filter(
      (el: any) => el.name !== undefined && el.name != name
    );
    setMaskData({ ...maskData, modules: res });
    setDisabled((prev: any) => {
      return { ...prev, [name]: false };
    });
  };

  const orderData = (direction = 0) => {
    let maskDataCopy = { ...maskData };
    let index = maskDataCopy.modules.findIndex((el: any) => el.name == name);
    if (index <= 0 && direction < 0) {
      return;
    }
    if (index >= maskData.modules.length - 1 && direction > 0) {
      return;
    }
    let el = maskDataCopy.modules.splice(index, 1);
    maskDataCopy.modules.splice(index + direction, 0, el[0]);
    setMaskData(maskDataCopy);
  };

  return (
    <div id="DataAnalysisModuleTypeCard" className="flex gap-2 my-3">
      <div className="flex flex-col gap-2 justify-center items-center">
        <div
          className="border rounded-md p-2 cursor-pointer hover:bg-green-400"
          onClick={() => orderData(-1)}
        >
          <ArrowUp />
        </div>
        <div
          className="border rounded-md p-2 cursor-pointer hover:bg-green-400"
          onClick={() => orderData(1)}
        >
          <ArrowDown />
        </div>
      </div>
      <div className="flex justify-center items-center p-4 border rounded-md bg-gray-200 w-full">
        <span className="font-semibold">{name.toLowerCase()}</span>
      </div>
      <div
        className="border rounded-md p-2 bg-black text-white self-end cursor-pointer hover:bg-red-600"
        onClick={() => deleteType(name.toLowerCase())}
      >
        <Trash2 />
      </div>
    </div>
  );
};

export default DataAnalysisModuleTypeCard;
