import { useEffect, useRef, useState } from "react";
import { PAGE_DIM, autoGrowTextArea } from "../../../utils/generic";

import {
  editEmail,
  getEmailsPaginated,
  iEmail,
  sendTestEmail,
} from "../../../services/emails";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { useModal } from "@/context/ModalContext";

import { Separator } from "@/components/ui/separator";
import PaginationMod from "@/components/pagination/PaginationMod";
import { CreateEmail } from "./forms/CreateEmail";
import SingleSend from "./forms/SingleSend";

interface loadEmailsProps {
  page: number;
  size: number;
}

export default function Emails() {
  const { t } = useTranslation();
  const [emails, setEmails] = useState<iEmail[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [edited, setEdited] = useState<(string | undefined)[]>([]);
  // const [open, setOpen] = useState<string | undefined>();
  const [open, setOpen] = useState<any>({});
  const [testEmail, setTestEmail] = useState("");

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);

  const elementRef = useRef<HTMLDivElement>(null);

  const { showModal } = useModal();

  const loadEmails = async ({ page = 1, size }: loadEmailsProps) => {
    setIsLoading(true);
    try {
      const data = await getEmailsPaginated({ page, size });

      setTotal(data.total || 0);

      setEmails(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    // scrollToTopList(elementRef);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    // scrollToTopList(elementRef);
  };

  const saveEmail = async (email: iEmail) => {
    setIsLoading(true);
    try {
      setEdited((edited) => {
        edited = edited.filter((e) => e !== email.type);
        return edited;
      });

      await editEmail(email);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadEmails({ page: page, size: size });
    // eslint-disable-next-line
  }, [page, size]);

  //   if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <br />
          <div className="flex gap-2">
            <Button
              onClick={() =>
                showModal(
                  <CreateEmail
                    onCreate={(e: any) => {
                      const mailType = e.type;

                      setEmails([
                        {
                          templateId: "",
                          type: mailType,
                          translations: {
                            it: {
                              subject: "",
                              buttonText: "",
                              messages: [],
                            },
                            en: {
                              subject: "",
                              buttonText: "",
                              messages: [],
                            },
                          },
                        },
                        ...emails,
                      ]);
                    }}
                  />,
                  {
                    title: " ",
                    description: " ",
                  }
                )
              }
            >
              {t("emails.new_email")}
            </Button>
            <Button
              onClick={() =>
                showModal(<SingleSend />, {
                  title: " ",
                  description: " ",
                })
              }
              //   text="Create single send"
            >
              {t("emails.create_single_send")}
            </Button>
          </div>
          <Separator className="mt-6 mb-8" />
          {emails.length ? (
            <>
              <div ref={elementRef}>
                {emails.map((email) => (
                  <div
                    key={"email_" + email._id}
                    className="flex flex-col gap-3 mt-6"
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        // setOpen(open === email.type ? undefined : email.type)
                        setOpen({
                          [email._id as string]:
                            open[email._id as string] === email.type
                              ? undefined
                              : email.type,
                        })
                      }
                    >
                      --- Type: <strong>{email.type}</strong>
                    </div>
                    <div
                      className={`${
                        open[email._id as string] === email.type
                          ? ""
                          : "h-0 invisible"
                      }`}
                    >
                      <div
                        className={`flex items-center gap-3 mb-4 ${
                          open[email._id as string] ? "" : "h-0 invisible"
                        }`}
                      >
                        Template:{" "}
                        <input
                          className="w-full p-2 border rounded-md"
                          type="text"
                          value={email.templateId}
                          onChange={(e) => {
                            setEdited((edited) => {
                              edited.push(email.type);
                              return edited;
                            });
                            setEmails(
                              emails.map((_email) => {
                                if (email.type === _email.type)
                                  _email.templateId = e.target.value;
                                return _email;
                              })
                            );
                          }}
                        />
                      </div>

                      {[...Object.keys(email.translations as any)].map(
                        (lang) => {
                          const config = email.translations as any;
                          return (
                            open[email._id as string] && (
                              <div
                                className={`flex flex-col gap-4 border p-3 my-6 ${
                                  open[email._id as string]
                                    ? ""
                                    : "h-0 invisible"
                                }`}
                                key={"email_" + email._id + "_lang_" + lang}
                              >
                                <u className="whitespace-nowrap">
                                  LANG: {lang}
                                </u>
                                <div className="flex items-center gap-3 mt-2">
                                  <label htmlFor="test-email">to: </label>
                                  <input
                                    id="test-email"
                                    onChange={(e) =>
                                      setTestEmail(e.target.value)
                                    }
                                    value={testEmail}
                                    className="w-full p-2 border rounded-md"
                                    type="text"
                                  />
                                  <Button
                                    //   small
                                    //   loading={isLoading}
                                    //   text="send test"
                                    variant="takyon"
                                    onClick={async () => {
                                      setIsLoading(true);
                                      try {
                                        await sendTestEmail({
                                          type: email.type,
                                          to: testEmail,
                                          lang: lang,
                                        });
                                      } catch (error) {
                                        alert(error);
                                      }
                                      setIsLoading(false);
                                    }}
                                  >
                                    send test
                                  </Button>
                                </div>
                                <div
                                  className="flex items-center gap-3 mb-6 pb-3 border-b-2"
                                  style={{ borderStyle: "groove" }}
                                >
                                  Subject:
                                  <input
                                    className="w-full p-2 border rounded-md"
                                    type="text"
                                    value={config[lang].subject}
                                    onChange={(e) => {
                                      setEdited((edited) => {
                                        edited.push(email.type);
                                        return edited;
                                      });
                                      setEmails(
                                        emails.map((_email) => {
                                          if (email.type === _email.type) {
                                            (_email.translations as any)[
                                              lang
                                            ].subject = e.target.value;
                                          }

                                          return _email;
                                        })
                                      );
                                    }}
                                  />
                                </div>

                                <div
                                  className={`${
                                    open[email._id as string]
                                      ? ""
                                      : "h-0 invisible"
                                  }`}
                                >
                                  <div className="my-2 font-semibold">
                                    Messages:{" "}
                                  </div>
                                  {config[lang].messages.map(
                                    (m: string, index: number) => {
                                      return (
                                        <div
                                          key={
                                            "email_" +
                                            email._id +
                                            "_lang_" +
                                            lang +
                                            "_" +
                                            index
                                          }
                                        >
                                          <div className="flex gap-3 mb-1">
                                            <strong>[{index}]</strong>
                                            <textarea
                                              onInput={autoGrowTextArea}
                                              name=""
                                              id=""
                                              className="w-full min-h-fit border my-2 p-2 rounded-md"
                                              value={m}
                                              onChange={(e) => {
                                                setEdited((edited) => {
                                                  edited.push(email.type);
                                                  return edited;
                                                });
                                                setEmails(
                                                  emails.map((_email) => {
                                                    if (
                                                      email.type === _email.type
                                                    ) {
                                                      (
                                                        _email.translations as any
                                                      )[lang].messages[index] =
                                                        e.target.value;
                                                    }

                                                    return _email;
                                                  })
                                                );
                                              }}
                                            >
                                              {m}
                                            </textarea>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                  <Button
                                    //   text="add message"
                                    //   small
                                    className="mt-4 ml-8"
                                    variant="takyon"
                                    onClick={() => {
                                      setEmails(
                                        emails.map((_email) => {
                                          if (email.type === _email.type) {
                                            (_email.translations as any)[
                                              lang
                                            ].messages.push("");
                                          }

                                          return _email;
                                        })
                                      );
                                    }}
                                  >
                                    add message
                                  </Button>
                                  <div className="flex items-center gap-3 my-6 ml-8">
                                    Button:{" "}
                                    <input
                                      className="w-full border p-2 rounded-md"
                                      type="text"
                                      value={config[lang].buttonText}
                                      onChange={(e) => {
                                        setEdited((edited) => {
                                          edited.push(email.type);
                                          return edited;
                                        });
                                        setEmails(
                                          emails.map((_email) => {
                                            if (email.type === _email.type) {
                                              (_email.translations as any)[
                                                lang
                                              ].buttonText = e.target.value;
                                            }

                                            return _email;
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="flex w-full justify-end mt-8 mb-2">
                                    <Button
                                      variant="takyon"
                                      //   loading={isLoading}
                                      onClick={() => saveEmail(email)}
                                      //   light={!edited.includes(email.type)}
                                      disabled={!edited.includes(email.type)}
                                      //   text={"Save " + email.type}
                                    >
                                      {"Save " + email.type}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        }
                      )}

                      {/* <div className="ml-auto">
                        <Button
                          //   loading={isLoading}
                          onClick={() => saveEmail(email)}
                          //   light={!edited.includes(email.type)}
                          disabled={!edited.includes(email.type)}
                          //   text={"Save " + email.type}
                        >
                          {"Save " + email.type}
                        </Button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
              <PaginationMod
                page={page}
                size={size}
                total={total}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <p>{t("pagination.no_show")}</p>
          )}
        </div>
      </div>
    </main>
  );
}
