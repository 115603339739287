import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { LucideEye, MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { datetimeToString } from "@/utils/generic";
import {
  iWebhookLog,
  sendAgainWebhookLog,
  setWebhookLogAsSuccess,
} from "@/services/webhook";
import CopyId from "@/components/helpers/CopyId";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface LogRequestResponseProps {
  webhookLog: iWebhookLog;
}

export function LogRequestResponse({ webhookLog }: LogRequestResponseProps) {
  const { t } = useTranslation();
  const [mode, setMode] = useState(false);

  return (
    <section className="p-3">
      <div className="d-flex justify-content-between gap-2">
        <Button
          // text={String(t("dev.webhook_mode_request"))}
          onClick={() => setMode(false)}
          // small
          disabled={!mode}
          className="w100"
        >
          {t("dev.webhook_mode_request")}
        </Button>
        <Button
          // text={String(t("dev.webhook_mode_response"))}
          onClick={() => setMode(true)}
          // small
          disabled={mode}
          className="w100"
        >
          {t("dev.webhook_mode_response")}
        </Button>
      </div>

      <br />

      {!mode ? (
        <pre>{JSON.stringify(webhookLog.request, null, 2)}</pre>
      ) : (
        <pre>{JSON.stringify(webhookLog.responses, null, 2)}</pre>
      )}
    </section>
  );
}

export const getColumns = (
  t: TFunction,
  onRefresh: () => void
): ColumnDef<iWebhookLog>[] => {
  return [
    {
      header: t("dev.webhook_date"),
      accessorFn: (webhookLog) =>
        datetimeToString(webhookLog.date, undefined, true, true),
    },
    {
      header: t("dev.webhook_attempts"),
      accessorKey: "attemps",
    },
    {
      header: t("dev.webhook_success"),
      accessorFn: (webhookLog) =>
        webhookLog.success ? t("dev.yes") : t("dev.no"),
    },
    {
      header: t("dev.webhook_event"),
      accessorKey: "request.event",
    },
    {
      header: `${t("dev.webhook_actions")}`,
      cell: ({ getValue, row }) => {
        const { showModal, hideModal } = useModal();

        return (
          <>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(<LogRequestResponse webhookLog={row.original} />, {
                  title: (
                    <>
                      {t("dev.webhook_modal_detail_title")}
                      <CopyId resourceId={row.original._id!} />
                    </>
                  ),
                  description: null,
                });
              }}
            >
              <LucideEye size={16} />
            </Button>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={async () => {
                    await setWebhookLogAsSuccess(row.original._id);
                    onRefresh();
                  }}
                >
                  {t("dev.webhook_again")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  disabled={row.original.success}
                  onClick={async () => {
                    await sendAgainWebhookLog(row.original._id);
                    onRefresh();
                  }}
                >
                  {t("dev.webhook_set_success")}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        );
      },
    },
  ];
};
