import { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import PaymentMethods from "./PaymentMethods";
import { COLLECTION_PERMISSIONS, iCollection } from "@/services/collection";
import { useRbac } from "@/hooks/rbac";
import { useTranslation } from "react-i18next";

const PaymentMethodsWrapper = ({
  collection,
  nextPage,
  loading,
  onRefresh,
}: {
  collection: iCollection;
  nextPage: string;
  loading: boolean;
  onRefresh: () => void;
}) => {
  const { setBackBtn, setProgress, setTitleCollectionPage } =
    useOutletContext<any>();

  const { t } = useTranslation();

  useEffect(() => {
    setBackBtn(true);
    setProgress(80);
  }, []);

  useEffect(() => {
    if (collection) {
      setTitleCollectionPage(
        `${t("collection.payment_methods")} - ${collection!.name}`
      );
    }
  }, [collection]);

  return (
    <div className="pt-6">
      <PaymentMethods
        onRefresh={onRefresh}
        collection={collection}
        nextPage={nextPage}
        loading={loading}
      />
    </div>
  );
};

export default PaymentMethodsWrapper;
