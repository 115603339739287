import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./users-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
// import { H3 } from "@/components/typography/Typography";
// import { iCollection } from "@/services/collection";
import { getUsersPaginated, iUser } from "@/services/auth";
import { useModal } from "@/context/ModalContext";
import { useNavigate } from "react-router-dom";
import { iCollection } from "@/services/collection";
import { Button } from "@/components/ui/button";
import UserForm from "./forms/UserForm";

export default function UserDatatable({}: // users,
// onSuccess,
{
  // users: iUser[];
  // onSuccess: Function;
}) {
  //   const { collectionId } = useParams<{ collectionId: string }>();

  //   useEffect(() => {
  //     console.log("Load collection data", collectionId);
  //   }, [collectionId]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iUser[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      /*
      getUsersPaginated search only email as search term
      let textQ = {};
      if (searchTerm) {
        textQ = {
          $or: [
            // list of fields where to search
            { _id: { $regex: searchTerm, $options: "i" } },
            { email: { $regex: searchTerm, $options: "i" } },
            { firstName: { $regex: searchTerm, $options: "i" } },
            { secondName: { $regex: searchTerm, $options: "i" } },
          ],
        };
      }
      */

      const payload = {
        page: page + 1,
        size: pageSize,
        query: searchTerm, // getUsersPaginated search only email as search term
      };

      const result = await getUsersPaginated(payload);

      const pageCount = Math.ceil(result.total / pageSize);
      setPageCount(pageCount);
      setData(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sorting, searchTerm]);

  const columns = useMemo(
    () => getColumns(t, showModal, hideModal, fetchData, navigate),
    []
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={true}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
        actions={[
          <Button
            onClick={() =>
              showModal(
                <UserForm
                  onSuccess={() => {
                    hideModal();
                    fetchData();
                  }}
                />,
                {
                  title: t("users.add_user"),
                  description: "",
                }
              )
            }
          >
            {t("users.add_user")}
          </Button>,
        ]}
      />
    </div>
  );
}
