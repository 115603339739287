import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { forwardRef } from "react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar, CalendarProps } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useTranslation } from "react-i18next";

interface DatePickerProps {
  onChange: (date: Date) => void;
  initialDate?: Date;
  placeholder?: string;
  value?: Date;
  className?: string;
}

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              props.className,
              !props.value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {props.value ? (
              format(props.value, "PPP")
            ) : (
              <span>{props.placeholder ?? t("form.pick_date")}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            style={{ pointerEvents: "all" }}
            mode="single"
            selected={props.value}
            defaultMonth={props.value}
            onSelect={(date) => props.onChange(date!)}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    );
  }
);
