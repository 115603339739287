import { iCollection } from "@/services/collection";
import BuybacksDatatable from "./BuybacksDatatable";
import SaleEventsDatatable from "./SaleEventsDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LargeText } from "@/components/typography/Typography";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";

export default function BuybacksTab({
  collection,
}: {
  collection: iCollection;
}) {
  // set page title
  const { setTitle } = useSectionTitle();
  const { t } = useTranslation();

  useEffect(() => {
    setTitle(`${t("collection.tab_buybacks")} - ${collection.name}`);
  }, [setTitle]);

  return (
    <div className="space-y-5">
      <div>
        <div className="flex items-center justify-start gap-1 w-full">
          <p className="text-xl font-semibold">
            {t("collection.tab_buybacks")}
          </p>
          <Popover>
            <PopoverTrigger asChild>
              <Info className="cursor-pointer" size={16} />
            </PopoverTrigger>
            <PopoverContent className="w-80 ml-5">
              <p>{t("collection.tab_buybacks_description")}</p>
            </PopoverContent>
          </Popover>
        </div>
        <BuybacksDatatable collection={collection} />
      </div>
    </div>
  );
}
