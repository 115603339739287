import { iCollection } from "@/services/collection";
import {
  getAnalysisMaskData,
  MaskDataResponse,
} from "@/services/dataanalysismask";
import { useEffect, useState } from "react";
import Customer from "./Customer";
import Overview from "./Overview";
import RevenueHighlight from "./RevenueHighlight";
import AppreciationStars from "./AppreciationStars";
import GeneratedTak from "./GeneratedTak";
import PerformanceSection from "./PerformanceSection";
import SidebarLatestNews from "./SidebarLatestNews";
import SidebarContactOurExpert from "./SidebarContactOurExpert";
import { Skeleton } from "@/components/ui/skeleton";
import { isEmptyObject } from "@/utils/generic";

export default function CollectionAnalyticsMaskTab({
  collection,
}: {
  collection: iCollection;
}) {
  const [data, setData] = useState<MaskDataResponse>({});
  const [isLoading, setLoading] = useState(true);

  const loadMaskData = async () => {
    try {
      setLoading(true);
      const data = await getAnalysisMaskData({ collectionId: collection._id });
      setData(data);
      setLoading(false);
    } catch (error) {
      console.log("Error getting mask data", error);
    }
  };

  useEffect(() => {
    loadMaskData();
  }, []);

  return isLoading ? (
    <div className="flex gap-4">
      <div className="flex flex-wrap gap-2 w-3/4">
        <Skeleton className="w-full h-[286px]" />
        <div className="flex w-full gap-2">
          <Skeleton className="w-full h-[160px]" />
          <Skeleton className="w-full h-[160px]" />
        </div>
        <Skeleton className="w-full h-[401px]" />
        <Skeleton className="w-full h-[90px]" />
        <Skeleton className="w-full h-[521px]" />
      </div>
      <div className="h-full flex flex-col w-1/4 gap-2">
        {[1, 2, 3].map((index) => (
          <Skeleton key={index} className="w-full h-[167px]" />
        ))}
      </div>
    </div>
  ) : (
    <div className="flex gap-4">
      <div
        className="flex flex-wrap gap-4 w-3/4"
        style={{ minHeight: "520px" }}
      >
        {Object.keys(data).map((key) => {
          const dataEmpty = isEmptyObject((data as any)[key]);
          if (dataEmpty) return null;

          switch (key) {
            case "base":
              return (
                <div key={key} className="w-full">
                  <Overview data={(data as any)[key]} collection={collection} />
                </div>
              );
            case "revenue":
              return (
                <RevenueHighlight
                  key={key}
                  value={(data as any)[key].value}
                  unit={"€"}
                  delta={(data as any)[key].delta}
                />
              );
            case "appreciation":
              return (
                <AppreciationStars
                  key={key}
                  value={(data as any)[key].value}
                  delta={(data as any)[key].delta}
                />
              );
            case "performance":
              return <PerformanceSection key={key} data={(data as any)[key]} />;
            case "customer":
              return <Customer key={key} data={(data as any)[key]} />;
            case "nft":
              return (
                <GeneratedTak
                  key={key}
                  data={(data as any)[key]}
                ></GeneratedTak>
              );
            default:
              return (
                <div key={`${key}-default`}>
                  <strong>{key}</strong>
                  <br />

                  {Object.keys((data as any)[key]).map((_key, _index) => {
                    return (
                      <p key={`${_key}-${_index}`}>
                        <u>{_key}:</u>{" "}
                        {JSON.stringify((data as any)[key][_key])}
                      </p>
                    );
                  })}
                  <br />
                  <hr />
                  <br />
                </div>
              );
          }
        })}
      </div>
      <div className="h-full flex flex-col w-1/4">
        <div className="w-full flex flex-col gap-4" style={{ height: "300px" }}>
          <SidebarLatestNews />
          <SidebarContactOurExpert />
          {/* <AnalyticsBanner /> */}
        </div>
      </div>
    </div>
  );
}
