import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { BillingDetails } from "@/services/payment";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface BillingFormProps {
  billing?: BillingDetails;
  readonly?: boolean;
}

const formSchema = z.object({
  address: z.string(),
  city: z.string(),
  contactName: z.string(),
  contactPhone: z.string(),
  fiscalCode: z.string(),
  name: z.string(),
  pec: z.string(),
  province: z.string(),
  recipientCode: z.string(),
  vat: z.string(),
  zip: z.string(),
});

export function PaymentBillingForm({ billing, readonly }: BillingFormProps) {
  const { t } = useTranslation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    if (billing) {
      form.reset(billing);
    }
  }, [billing]);

  return (
    <Form {...form}>
      <form>
        <div className="p-1 space-y-3">
          <FormField
            control={form.control}
            name="vat"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("payment.vat")}
                  {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("payment.vat")}
                    readOnly={readonly}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="fiscalCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("payment.fiscal_code")}
                  {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("payment.fiscal_code")}
                    readOnly={readonly}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("payment.business_name")}
                  {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("payment.business_name")}
                    readOnly={readonly}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-3">
            <FormField
              control={form.control}
              name="zip"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("payment.zip")}
                    {t("form.required")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("payment.zip")}
                      readOnly={readonly}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("payment.city")}
                    {t("form.required")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("payment.city")}
                      readOnly={readonly}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-4 gap-3">
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>
                    {t("payment.address")}
                    {t("form.required")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("payment.address")}
                      readOnly={readonly}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="province"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("payment.province")}
                    {t("form.required")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("payment.province")}
                      readOnly={readonly}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-3">
            <FormField
              control={form.control}
              name="contactName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("payment.contact_name")}
                    {t("form.required")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("payment.contact_name")}
                      readOnly={readonly}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <div className="flex gap-4 items-center w-full">
              <label className="whitespace-nowrap" htmlFor="">
                {t("payment.contact_name")}
              </label>
              <input
                className="w-full border rounded-md px-1"
                defaultValue={billing?.contactName}
                readOnly={readonly}
                type="text"
                name="billingDetails[contactName]"
              />
            </div> */}
            <FormField
              control={form.control}
              name="contactPhone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("payment.contact_phone")}
                    {t("form.required")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("payment.contact_phone")}
                      readOnly={readonly}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <div className="flex gap-4 items-center w-full">
              <label className="whitespace-nowrap" htmlFor="">
                {t("payment.contact_phone")}
              </label>
              <input
                className="w-full border rounded-md px-1"
                defaultValue={billing?.contactPhone}
                readOnly={readonly}
                type="text"
                name="billingDetails[contactPhone]"
              />
            </div> */}
          </div>

          <FormField
            control={form.control}
            name="recipientCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("payment.recipient_code")}
                  {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("payment.recipient_code")}
                    readOnly={readonly}
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("payment.recipient_code_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="pec"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("payment.pec")}
                  {t("form.required")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("payment.pec")}
                    readOnly={readonly}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <div className="flex gap-4 items-center w-full">
            <label className="whitespace-nowrap" htmlFor="">
              {t("payment.pec")}*
            </label>
            <input
              className="w-full border rounded-md px-1"
              required
              defaultValue={billing?.pec}
              readOnly={readonly}
              type="email"
              name="billingDetails[pec]"
            />
          </div> */}
          {/* </div> */}
        </div>
      </form>
    </Form>
  );
}
