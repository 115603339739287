import { DropdownMenuItem } from "../ui/dropdown-menu";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "../ui/tooltip";

export default function DropdownMenuItemWithTooltip({
  children,
  disabledMessage,
  ...props
}: {
  children: React.ReactNode;
  disabledMessage: string;
} & React.ComponentProps<typeof DropdownMenuItem>) {
  const { disabled, ...restProps } = props;
  return disabled ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownMenuItem
            {...restProps}
            className="text-gray-400 hover:!text-gray-400"
          >
            {children}
          </DropdownMenuItem>
        </TooltipTrigger>
        <TooltipContent>
          <p>{disabledMessage}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <DropdownMenuItem {...props}>{children}</DropdownMenuItem>
  );
}
