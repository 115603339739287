import { iCollection } from "@/services/collection";
import { datetimeToString } from "@/utils/generic";
import { ColumnDef } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import { LucideEye } from "lucide-react";
import { useModal } from "@/context/ModalContext";
import { TFunction } from "i18next";
import helpers from "@/components/helpers/helpers";
import CopyId from "@/components/helpers/CopyId";
import { iNftEvent } from "@/services/event";
import DetailHotel from "@/components/collection/DetailHotel";

export const getColumns = (
  collection: iCollection,
  t: TFunction
): ColumnDef<iNftEvent>[] => {
  return [
    // {
    //   header: "#",
    //   accessorFn: (event) =>
    //     String(event._nft?._id).substring(String(event._nft?._id).length - 6),
    // },
    {
      id: "actions",
      accessorKey: "id",
      header: "",
      cell: function render({ getValue, row }) {
        const { showModal } = useModal();

        return (
          <>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                showModal(<DetailHotel nft={row.original._nft} />, {
                  title: (
                    <>
                      {t("nft.modal_detail_title")} - #
                      {row.original._nft?.shortId}
                      <CopyId resourceId={row.original._id!} />
                    </>
                  ),
                  description: null,
                });
              }}
            >
              <LucideEye size={16} />
            </Button>
          </>
        );
      },
    },
    {
      header: t("nft.taks_reference"),
      accessorFn: (event) => event._nft?.payload?.reference,
    },
    {
      header: t("collection.events_mode"),
      accessorFn: (event) => String(event.payload.mode).toUpperCase(),
    },
    {
      header: `${t("collection.events_price")} (€)`,
      accessorFn: (event) => helpers.price(event.payload.price),
    },
    {
      header: `${t("collection.events_date")}`,
      accessorFn: (event) => datetimeToString(event._createdAt),
    },
  ];
};
