import { useState } from "react";
import { giveCreditsToUser } from "@/services/auth";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";
import { Button } from "@/components/ui/button";

export default function GiveCreditsForm({
  userId,
  onSuccess,
}: {
  userId: string;
  onSuccess?: () => void;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = z.object({
    userId: z.string(),
    amount: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userId: userId,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      // const data = formToObject(e.target);
      await giveCreditsToUser(data);
      onSuccess?.();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Form {...form}>
      <form
        id="GiveCreditsForm"
        className="flex flex-col gap-4"
        // onSubmit={submit}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t("users.give_credits_amount")} {t("form.required")}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t("users.give_credits_amount")}
                  {...field}
                  type="number"
                  step={0.01}
                  min={0}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* <label htmlFor="">{t("dev.credits_amount")}</label>
          <input
            className="border rounded-md px-2"
            type="number"
            step={0.01}
            name="amount"
            min={0}
          /> */}

        <FormField
          control={form.control}
          name="userId"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Inserisci l'id dell'utente"
                  type="hidden"
                  {...field}
                />
              </FormControl>
            </FormItem>
          )}
        />
        {/* <input type="text" name="userId" defaultValue={userId} hidden /> */}

        <div className="flex justify-end mt-2 gap-2">
          <Button onClick={() => console.log("close modal")}>
            {t("buttons.cancel")}
          </Button>
          <LoadingSubmitButton
            isLoading={isLoading}
            variant="takyon"
            type="submit"
          >
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
