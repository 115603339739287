import { z } from "zod";
import i18n from "i18next";

const t = i18n.t.bind(i18n);

export const formHotelSchema = z.object({
  _createdAt: z.date(),
  owner: z.string().optional(), // required for new records inside the form with extend
  lang: z.string(),
  reference: z.string(),
  checkin: z.object({
    from: z.date(),
    to: z.date(),
  }),
  lockDate: z.date(),
  board: z.string(),
  extra: z.string().optional(),
  gold: z.boolean().optional(),
  rooms: z.array(
    z.object({
      name: z.string().min(1, "Room type cannot be empty"),
      guestsAdults: z
        .preprocess(
          (val) => (typeof val === "number" ? String(val) : val),
          z.string().optional()
        )
        .transform((val) => Number(val))
        .refine((val) => !isNaN(val), {
          message: "guestsAdults must be a number",
        })
        .refine((val) => val >= 1, {
          message: "guestsAdults must be greater than 0",
        }),
      guestsKids: z
        .preprocess(
          (val) => (typeof val === "number" ? String(val) : val),
          z.string().optional()
        )
        .transform((val) => Number(val))
        .refine((val) => !isNaN(val), {
          message: "guestsAdults must be a number",
        })
        .refine((val) => val >= 0, {
          message: "guestsAdults must be non-negative",
        }),
    })
  ),
  price: z
    .preprocess(
      (val) => (typeof val === "number" ? String(val) : val),
      z.string()
    )
    .transform((val) => Number(val))
    .refine((val) => !isNaN(val), { message: "Price must be a number" })
    .refine((val) => val >= 0, { message: "Price must be non-negative" }),
  //imageUrl: z.string(),
  images: z.array(z.string()).optional(),
  fakeOwnerName: z.string().optional(),
});

export const formExperienceSchema = z.object({
  // _createdAt: z.date(),
  _createdAt: z.date(),
  owner: z.string().optional(), // required for new records inside the form with extend
  lang: z.string(),
  reference: z.string(),
  start_date: z.date(),
  gold: z.boolean().optional(),
  originalPrice: z
    .preprocess(
      (val) => (typeof val === "number" ? String(val) : val),
      z.string()
    )
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "Price must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, { message: "Price must be non-negative" }), // Additional validation
  // price: z
  //   .preprocess(
  //     (val) => (typeof val === "number" ? String(val) : val),
  //     z.string()
  //   )
  //   .transform((val) => Number(val)) // Convert string input to number
  //   .refine((val) => !isNaN(val), { message: "Price must be a number" }) // Check if it's a valid number
  //   .refine((val) => val >= 0, { message: "Price must be non-negative" }), // Additional validation
  lockDate: z.date(),
  extra: z.string().optional(), // notes
  // custom experience fields
  partecipants: z
    .preprocess(
      (val) => (typeof val === "number" ? String(val) : val),
      z.string().optional()
    )
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "Participants must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, {
      message: "Participants must be non-negative",
    }), // Additional validation,
  duration: z
    .preprocess(
      (val) => (typeof val === "number" ? String(val) : val),
      z.string().optional()
    )
    .transform((val) => Number(val)) // Convert string input to number
    .refine((val) => !isNaN(val), { message: "Duration must be a number" }) // Check if it's a valid number
    .refine((val) => val >= 0, { message: "Duration must be non-negative" }), // Additional validation,
  type: z.string().optional(),
  category: z.string().optional(),
  images: z.array(z.string()).optional(),
  fakeOwnerName: z.string().optional(),
});

export type FormSchemaHotel = z.infer<typeof formHotelSchema>;
export type FormSchemaExperience = z.infer<typeof formExperienceSchema>;
