import { useModal } from "@/context/ModalContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./button";

export default function DeleteModal({
  onRemove,
}: {
  onRemove(): Promise<void>;
}) {
  const { hideModal } = useModal();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const remove = async () => {
    setIsLoading(true);
    try {
      await onRemove();
      hideModal();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex justify-end">
      <div className="flex gap-4">
        <Button disabled={isLoading} onClick={hideModal}>
          {t("members.cancel")}
        </Button>
        <Button variant="destructive" disabled={isLoading} onClick={remove}>
          {t("members.remove")}
        </Button>
      </div>
    </div>
  );
}
