import { iUser } from "@/services/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { iGroup } from "@/services/groups";
import { Pagination } from "@/utils/Pagination";

interface UserState {
  data?: iUser;
  groups: iGroup[];
}

const initialState: UserState = {
  data: undefined,
  groups: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<iUser | undefined>) => {
      // console.log("Setting user", action.payload);
      state.data = action.payload;
    },
    setGroups: (state, action: PayloadAction<Pagination<iGroup>>) => {
      // console.log("Setting groups", action.payload);
      state.groups = action.payload.data; // Aggiorna i gruppi
    },
    clearUser: (state) => {
      state.data = undefined;
      state.groups = [];
    },
  },
});

export const { setUser, clearUser, setGroups } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.data;
export const selectUserGroups = (state: RootState) => state.user.groups;

export default userSlice.reducer;
