import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import FormDeveloperApiKey from "./FormDeveloperApiKey";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { deleteApiKey, getMyApiKeys, iApiKey } from "@/services/apikey";
import { LucideTrash2 } from "lucide-react";
import DeleteModal from "@/components/ui/deleteModal";

export default function DeveloperApiKeys({}: {}) {
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<Array<iApiKey>>([]);

  const loadApiKeys = async () => {
    setIsLoading(true);
    try {
      const data = await getMyApiKeys();
      setApiKeys(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadApiKeys();
  }, []);

  const onRemoveApiKey = async (apiKey: any) => {
    try {
      // const data = formToObject(e.target);
      await deleteApiKey({
        apikeyId: apiKey._id,
      });
      hideModal();
    } catch (error) {
      console.log(error);
    }

    await loadApiKeys();
  };

  return (
    <>
      <div className="flex w-full justify-between items-center">
        <span className="title font-bold">{t("dev.api_key_list")}</span>
        <Button
          className="ml-auto"
          // loading={isLoading}
          variant="takyon"
          onClick={() =>
            showModal(<FormDeveloperApiKey />, {
              title: t("dev.create_apikey"),
              description: "",
            })
          }
          // text={String(t("dev.create_apikey"))}
        >
          {t("dev.create_apikey")}
        </Button>
      </div>
      <div className="apikey-list">
        {apiKeys.map((apikey, key) => {
          return (
            <div
              className="apikey mt-5 mb-8 p-6 border rounded-md flex justify-between"
              key={key}
            >
              <div>
                <p className="font-semibold mb-2">{apikey.name}</p>
                <span>{apikey.token}</span>
              </div>
              <LucideTrash2
                size={16}
                onClick={() =>
                  showModal(
                    <DeleteModal onRemove={() => onRemoveApiKey(apikey)} />,
                    {
                      title: t("modals.remove_modal"),
                      description: t("modals.remove_modal_text"),
                    }
                  )
                }
                className="apikey-delete"
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
