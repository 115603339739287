import ErrorPage from "@/components/errors/ErrorPage";
import GroupSection from "@/components/group/GroupSection";
import { getCollections, iCollection } from "@/services/collection";
import { getGroup, iGroup } from "@/services/groups";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function GroupPage() {
  const { groupId } = useParams();

  const [isLoadingGroup, setIsLoadingGroup] = useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);
  const [group, setGroup] = useState<iGroup>();
  const [collections, setCollections] = useState<iCollection[]>([]);

  const { t } = useTranslation();

  const loadGroup = async () => {
    setIsLoadingGroup(true);
    try {
      const temp_group = await getGroup(String(groupId));
      setGroup(temp_group);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingGroup(false);
  };

  const loadCollections = async () => {
    setIsLoadingCollections(true);
    try {
      const collections = await getCollections({
        page: 1,
        size: 500,
        query: JSON.stringify({ groupId: groupId }),
      });
      setCollections(collections.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingCollections(false);
  };

  useEffect(() => {
    loadGroup();
    loadCollections();
  }, [groupId]);

  if (isLoadingGroup) return <>{t("group.loading")}</>;
  if (isLoadingCollections) return <>{t("collection.loading")}</>;

  if (!group) return <ErrorPage message={t("group.not_found")} />;

  return (
    <>
      <GroupSection
        onRefresh={() => {
          loadGroup();
          loadCollections();
        }}
        group={group}
        collections={collections}
        key={group._id}
      />
    </>
  );
}
