import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./invoices-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { useModal } from "@/context/ModalContext";
import { useNavigate } from "react-router-dom";
import { getInvoicesPaginated, iInvoice } from "@/services/payment";

// export default function InvoicesDatatable({
//   invoices,
//   loadInvoices,
//   apiKeys,
// }: {
//   invoices: iInvoice[];
//   loadInvoices: Function;
//   apiKeys: any;
// }) {

type QueryPayload = {
  $and?: Array<any>;
};

export default function InvoicesDatatable() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iInvoice[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      // const data = await getInvoicesPaginated(0, 0, archived);

      let queryPayload: QueryPayload = {
        // $and: [
        //   { archived: false }
        // ],
      };

      if (searchTerm) {
        const searchConditions = [
          { collectionNames: { $regex: searchTerm, $options: "i" } },
          {
            "payload.payment_settings.default_mandate": {
              $regex: searchTerm,
              $options: "i",
            },
          },
          { paymentMethodName: { $regex: searchTerm, $options: "i" } },
          { _id: { $regex: searchTerm, $options: "i" } },
          // other search conditions
        ];
        if (!queryPayload.$and) {
          queryPayload.$and = [];
        }
        queryPayload.$and?.push({ $or: searchConditions });
      }

      if (filters) {
        filters.forEach((filter) => {
          if (filter.id === "archived") {
            if (!queryPayload.$and) {
              queryPayload.$and = [];
            }
            queryPayload.$and?.push({ archived: true });
          }
        });
      }

      console.log("InvoiceDatatable - queryPayload", queryPayload);

      const payload = {
        page: page + 1,
        size: pageSize,
        query: queryPayload, // getUsersPaginated search only email as search term
      };

      // if (mode === "archived") archived = true;
      // if (mode === "unarchived") archived = false;

      const result = await getInvoicesPaginated(payload);

      const pageCount = Math.ceil(result.total / pageSize);
      setPageCount(pageCount);
      setData(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, filters, sorting, searchTerm]);

  const toolbarFilters = useMemo(() => {
    return [
      // {
      //   column: "payload_status",
      //   type: "select",
      //   title: "Status",
      //   options: [
      //     { label: "Active", value: "active" },
      //     { label: "Inactive", value: "inactive" },
      //   ],
      //   key: "status",
      // },
      // { type: "date-range", label: "Date Range", key: "createdAt" },
      {
        column: "archived",
        type: "toggle",
        title: "Archived",
        key: "archived",
      },
    ];
  }, [t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(
    () =>
      getColumns(
        t,
        showModal,
        hideModal,
        navigate,
        fetchData,
        [] // apiKeys
      ),
    []
  );

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={true}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={(filters) => {
          // setFilters(filters);
        }}
        filters={toolbarFilters}
      />
    </div>
  );
}
