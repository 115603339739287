import { customerResponse } from "@/services/dataanalysismask";
import { useTranslation } from "react-i18next";
import { Label, Pie, PieChart } from "recharts";
import { useEffect } from "react";
import countries from "i18n-iso-countries";
import "i18n-iso-countries/langs/en.json";
import "i18n-iso-countries/langs/es.json";
import "i18n-iso-countries/langs/it.json";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  Circle,
  Computer,
  Earth,
  Laptop,
  LaptopMinimal,
  MonitorSmartphone,
  Phone,
  Smartphone,
  Tablet,
  User,
  Users,
} from "lucide-react";

export function Chart({
  data,
  formattedData,
  dataKey,
  nameKey,
  title,
  icon,
}: {
  data: any;
  icon: any;
  formattedData: { icon: any; text: string; value: string }[];
  dataKey: string;
  nameKey: string;
  title: string;
}) {
  const { t } = useTranslation();

  const chartConfig = {
    tablet: {
      label: "Tablet",
    },
    desktop: {
      label: "Desktop",
    },
    mobile: {
      label: "Mobile",
    },
    single: {
      label: t("analytics.customer.type_single"),
    },
    couple: {
      label: t("analytics.customer.type_couple"),
    },
    group: {
      label: t("analytics.customer.type_group"),
    },
    family: {
      label: t("analytics.customer.type_family"),
    },
    other: {
      label: t("analytics.customer.type_other"),
    },
  } satisfies ChartConfig;

  return (
    <Card className="rounded-md flex flex-col w-full">
      <CardHeader className="pb-0 pt-3.5">
        <CardTitle className="text-xl font-medium">{title}</CardTitle>
        {/* <CardDescription>January - June 2024</CardDescription> */}
      </CardHeader>
      <CardContent className="flex-1 pb-0 px-5">
        {/* @ts-ignore */}
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px] relative"
        >
          <div className="absolute flex justify-center items-center left-0 top-0 right-0 bottom-0">
            <div className="h-32 w-32 absolute bg-slate-200 flex justify-center items-center rounded-full">
              {icon}
            </div>
          </div>
          <PieChart className="h-32 w-32">
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  formatter={(value, country, data) => (
                    <div className="w-full flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Circle
                          size={14}
                          fill={data.payload.fill}
                          color={data.payload.fill}
                        />
                        <p>{country}</p>
                      </div>
                      <p className="ml-2">{value}%</p>
                    </div>
                  )}
                />
              }
            />
            <Pie
              data={data}
              dataKey={dataKey}
              nameKey={nameKey}
              innerRadius={70}
              outerRadius={85}
            ></Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm w-full px-5 pb-3.5">
        {formattedData.map((data, key) => {
          return (
            <div key={key} className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                {data.icon}
                <p className="text-base font-medium">{data.text}</p>
              </div>
              <div className="text-base font-semibold">{data.value}</div>
            </div>
          );
        })}
      </CardFooter>
    </Card>
  );
}

export default function Customer({ data }: { data: customerResponse }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const registerLocales = async () => {
      try {
        countries.registerLocale(
          (await import("i18n-iso-countries/langs/en.json")).default
        );
        countries.registerLocale(
          (await import("i18n-iso-countries/langs/it.json")).default
        );
        countries.registerLocale(
          (await import("i18n-iso-countries/langs/es.json")).default
        );
      } catch (error) {
        console.error("Error loading country locales:", error);
      }
    };

    registerLocales();
  }, []);

  const formatNumber = (num: any) => {
    return Number((Number(num) * 100).toFixed(0));
  };

  const chartDeviceData = [
    {
      device: "tablet",
      amount: formatNumber(data.devices?.tablet),
      fill: "#3C8E37",
    },
    {
      device: "desktop",
      amount: formatNumber(data.devices?.desktop),
      fill: "#1C3C1A",
    },
    {
      device: "mobile",
      amount: formatNumber(data.devices?.mobile),
      fill: "#43F3AE",
    },
  ];
  const chartDeviceFormattedData = [
    {
      icon: <Smartphone size={20} className="inline" color="#8CE786" />,

      text: "Mobile",
      value: `${formatNumber(data.devices?.mobile)}%`,
      rawValue: data.devices?.mobile,
    },
    {
      icon: <Laptop size={20} className="inline" color="#1C3C1A" />,

      text: "Desktop",
      value: `${formatNumber(data.devices?.desktop)}%`,
      rawValue: data.devices?.desktop,
    },
    {
      icon: <Tablet size={20} className="inline" color="#3C8E37" />,

      text: "Tablet",
      value: `${formatNumber(data.devices?.tablet)}%`,
      rawValue: data.devices?.tablet,
    },
  ].sort((a, b) => Number(b.rawValue) - Number(a.rawValue));

  const chartPeopleData = [
    {
      type: "single",
      amount: formatNumber(data.people?.single),
      fill: "#3C8E37",
    },
    {
      type: "couple",
      amount: formatNumber(data.people?.couple),
      fill: "#1C3C1A",
    },
    {
      type: "group",
      amount: formatNumber(data.people?.group),
      fill: "#43F3AE",
    },
    {
      type: "family",
      amount: formatNumber(data.people?.family),
      fill: "#8CA200",
    },
  ];
  const chartPeopleFormattedData = [
    {
      icon: <User size={20} className="inline" color="#3C8E37" />,
      text: t("analytics.customer.type_single"),
      value: `${formatNumber(data.people?.single)}%`,
      rawValue: data.people?.single,
    },
    {
      icon: <Users size={20} className="inline" color="#1C3C1A" />,
      text: t("analytics.customer.type_couple"),

      value: `${formatNumber(data.people?.couple)}%`,
      rawValue: data.people?.couple,
    },
    {
      icon: <Users size={20} className="inline" color="#8CE786" />,
      text: t("analytics.customer.type_group"),

      value: `${formatNumber(data.people?.group)}%`,
      rawValue: data.people?.group,
    },
    {
      icon: <Users size={20} className="inline" color="#8CA200" />,
      text: t("analytics.customer.type_family"),
      value: `${formatNumber(data.people?.family)}%`,
      rawValue: data.people?.family,
    },
  ].sort((a, b) => Number(b.rawValue) - Number(a.rawValue));

  // Process countries
  const countryEntries = Object.entries(data.countries || {}).sort(
    (a: any, b: any) => b[1] - a[1]
  );

  const topCountries = countryEntries.slice(0, 4); // Take the top 4
  const otherCountries = countryEntries.slice(4); // Group the rest

  const totalOtherAmount = otherCountries.reduce(
    (acc, [, value]) => acc + (value ?? 0),
    0
  );

  function createHexColorFromChars(char: string) {
    if (char.length < 2) {
      throw new Error("Input must be at least 2 characters long");
    }

    // Convert characters to ASCII values
    const char1Code = char[0].charCodeAt(0);
    const char2Code = char[1].charCodeAt(0);

    // Introduce more variance by incorporating primes and non-linear transformations
    const red = ((char1Code * 17 + char2Code * 31) % 256)
      .toString(16)
      .padStart(2, "0");
    const green = (
      (Math.abs(char1Code - char2Code) * 13 + char1Code * 29) %
      256
    )
      .toString(16)
      .padStart(2, "0");
    const blue = ((char1Code ** 2 + char2Code ** 2 + 19) % 256)
      .toString(16)
      .padStart(2, "0");

    // Return the hex color
    return `#${red}${green}${blue}`;
  }

  // Then the function itself
  const getCountryFullName = (countryCode: string): string => {
    const currentLanguage = i18n.language;

    try {
      return (
        countries.getName(countryCode.toUpperCase(), currentLanguage) ||
        countryCode.toUpperCase()
      );
    } catch {
      return countryCode.toUpperCase();
    }
  };

  const chartCountryData = [
    ...topCountries.map(([country, value]) => {
      if (country === "en") country = "gb";
      return {
        country: getCountryFullName(country),
        amount: formatNumber(value),
        fill: createHexColorFromChars(country), // Random color
      };
    }),
    {
      country: t("analytics.customer.type_other"),
      amount: formatNumber(totalOtherAmount),
      fill: "#CCCCCC", // Gray for "Other"
    },
  ];

  const chartCountryFormattedData = [
    ...topCountries.map(([country, value]) => {
      if (country === "en") country = "gb";
      return {
        icon: (
          <Circle
            fill={createHexColorFromChars(country)}
            size={18}
            className="inline"
            color={createHexColorFromChars(country)}
          />
        ),
        text: getCountryFullName(country),
        value: `${formatNumber(value)}%`,
      };
    }),
    {
      icon: (
        <Circle fill="#CCCCCC" size={18} className="inline" color="#CCCCCC" />
      ),
      text: t("analytics.customer.other"),
      value: `${formatNumber(totalOtherAmount)}%`,
    },
  ];

  return (
    <div className="rounded-xl px-4 p-5 gap-2 w-full border">
      <p className="text-2xl font-medium mb-4">
        {t("analytics.customer.section_title")}
      </p>

      <div className="flex gap-4">
        <Chart
          icon={<MonitorSmartphone size={35} strokeWidth={1} />}
          formattedData={chartDeviceFormattedData}
          title={t("analytics.customer.devices")}
          data={chartDeviceData}
          dataKey="amount"
          nameKey="device"
        />
        <Chart
          icon={<Earth size={35} strokeWidth={1} />}
          formattedData={chartCountryFormattedData}
          title={t("analytics.customer.countries")}
          data={chartCountryData}
          dataKey="amount"
          nameKey="country"
        />
        <Chart
          icon={<User size={35} strokeWidth={1} />}
          formattedData={chartPeopleFormattedData}
          title={t("analytics.customer.people")}
          data={chartPeopleData}
          dataKey="amount"
          nameKey="type"
        />
      </div>
    </div>
  );
}
