import { useModal } from "@/context/ModalContext";
import { DEFAULT_LANGUAGE, LANG } from "@/services/i18next";
import {
  iInvoice,
  sendInvoiceEmail,
  toggleInvoiceArchived,
} from "@/services/payment";
import { useMemo, useState } from "react";

import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox } from "@/components/ui/checkbox";

interface InvoiceModalProps {
  invoice: iInvoice;
  onSuccess: Function;
}

export function SendEmailInvoice({ invoice, onSuccess }: InvoiceModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const langOptions = useMemo(
    () =>
      Object.keys(LANG).map((lang) => {
        return {
          text: lang,
          value: lang,
        };
      }),
    []
  );

  const formSchema = z
    .object({
      recipientEmail: z.string().email().optional(), // Optional at field level
      customEmail: z.string().email().optional(), // Optional at field level
      lang: z.string(),
      invoiceNumber: z.string().min(1),
      archive: z.boolean(),
    })
    .refine((data) => data.recipientEmail || data.customEmail, {
      message: "At least one email (Recipient or Custom) must be provided.",
      path: ["recipientEmail"], // Error will be attached to recipientEmail, adjust if needed
    });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      recipientEmail: invoice.payload?.customer_email,
      lang: DEFAULT_LANGUAGE,
      archive: true,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    try {
      const { recipientEmail, customEmail, lang, archive, invoiceNumber } =
        data;
      const email = customEmail || recipientEmail;
      await sendInvoiceEmail(invoice._id, email, lang as any);

      if (archive) {
        // Archive the invoice
        await toggleInvoiceArchived(invoice._id!, true, invoiceNumber);
      }

      hideModal();
      onSuccess();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <div className="flex gap-2 items-end">
          <FormField
            control={form.control}
            name="recipientEmail"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>
                  {t("invoices.sendinvoice.recipientEmail")}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("invoices.sendinvoice.recipientEmail")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="mb-3">or</div>

          <FormField
            control={form.control}
            name="customEmail"
            render={({ field }) => (
              <FormItem className="flex-1">
                <FormLabel>{t("invoices.sendinvoice.customEmail")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("invoices.sendinvoice.customEmail")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="lang"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("invoices.lang")} *</FormLabel>
              <Select
                value={field.value}
                onValueChange={(value) => {
                  field.onChange;
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder={t("form.select")} />
                </SelectTrigger>
                <SelectContent>
                  {langOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.text}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="invoiceNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("invoices.sendinvoice.invoiceNumber")} *</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("invoices.sendinvoice.invoiceNumber")}
                  {...field}
                />
              </FormControl>
              <FormDescription>
                {t("invoices.sendinvoice.invoiceNumber_description")}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="archive"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>{t("invoices.sendinvoice.archive")}</FormLabel>
            </FormItem>
          )}
        />

        <div className="flex mt-10">
          <LoadingSubmitButton isLoading={isLoading} variant="takyon">
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
