import { Button } from "@/components/ui/button";
import { iUser, loginAs } from "@/services/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ColumnDef } from "@tanstack/react-table";
import {
  Archive,
  ListChecks,
  Loader2,
  LucideEye,
  MoreVertical,
  OctagonAlert,
} from "lucide-react";
import { TFunction } from "i18next";
import {
  iInvoice,
  iLastUpdateInvoice,
  toggleInvoiceArchived,
} from "@/services/payment";
import CopyId from "@/components/helpers/CopyId";
import { SendInvoice } from "./forms/SendInvoice";
import { SendEmailInvoice } from "./forms/SendEmailInvoice";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ChargesLogModal } from "@/pages/paymentmethods/modals/ChargesLogModal";
import { PaymentBillingForm } from "@/pages/accountSettings/forms/PaymentBillingForm";
import helpers from "@/components/helpers/helpers";
import { useModal } from "@/context/ModalContext";

// import { formToObject } from "@/utils/generic";

interface CreateUserFormProps {
  onSuccess: Function;
  user?: iUser;
}

function makeid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

// const { showModal } = useModal();
export const getColumns = (
  t: TFunction,
  showModal: Function,
  hideModal: Function,
  navigate: Function,
  onRefresh: () => void,
  apiKeys: any
): ColumnDef<iInvoice>[] => {
  const { confirmModal } = useModal();

  return [
    {
      header: "ID",
      accessorKey: "_id",
      cell: ({ getValue }) => <CopyId resourceId={getValue() as string} />,
    },
    {
      header: t("invoices.invoiceId"),
      accessorKey: "invoceInternalId",
      // accessorFn: (row: any) => getSignalStatus(row.payload.status),
      cell: ({ getValue }) => {
        return getValue() && <Badge>{getValue() as string}</Badge>;
      },
    },
    {
      header: t("invoices.paymentMethodName"),
      accessorKey: "paymentMethodName",
    },
    {
      header: t("invoices.dateCreated"),
      accessorKey: "payload.created",
      accessorFn: (row: any) => {
        return new Date(row.payload.created * 1000).toLocaleDateString();
      },
    },
    {
      header: t("invoices.datePaid"),
      accessorKey: "payload.effective_at",
      accessorFn: (row: any) =>
        new Date(row.payload.effective_at * 1000).toLocaleDateString(),
    },
    {
      header: t("invoices.collectionNames"),
      accessorKey: "collectionNames",
      // accessorFn: (row: any) => row.collectionNames.join("\n"),
      cell: function render(props: any) {
        let ids = props.getValue();

        return (
          <>
            {ids.length > 0 ? (
              ids.map((c: any, key: any) => (
                <p
                  className="m-0 whitespace-nowrap"
                  key={"collection_" + ids[key]}
                  onClick={() => navigate(`/collection/${ids[key]}/payment`)}
                >
                  <u className="cursor-pointer">{c}</u>
                  {key < ids.length - 1 && ", "}
                </p>
              ))
            ) : (
              <div className="flex gap-1">
                <OctagonAlert className="text-red-600" size={16} />{" "}
                {t("invoices.noCollection")}
              </div>
            )}
          </>
        );
      },
    },
    // {
    //   header: t("invoices.collectionIDs"),
    //   accessorKey: "_id",
    // },
    {
      header: t("invoices.amount"),
      accessorKey: "payload.total",
      cell: ({ getValue, row }) => {
        const currency = "€"; //row.original.payload?.currency;
        const totalAmount = Number(getValue());
        const priceWithoutVat = helpers.priceWithoutVat(
          Number(totalAmount),
          22, // TODO - make this dynamic
          currency
        );
        return (
          <p className="m-0 whitespace-nowrap">
            {helpers.price(totalAmount, currency, 2)}
            <br />
            <small>{priceWithoutVat}</small>
          </p>
        );
      },
    },
    {
      header: t("invoices.archived"),
      accessorKey: "archived",
      // accessorFn: (row: any) => getSignalStatus(row.payload.status),
      cell: ({ getValue }) => {
        return (
          getValue() == true && (
            <div className="flex justify-center">
              <Archive size={16} />
            </div>
          )
        );
      },
    },
    {
      header: t("invoices.status"),
      accessorKey: "payload.status",
      // accessorFn: (row: any) => getSignalStatus(row.payload.status),
      cell: ({ row }) => {
        const lightSignalMap: any = {
          PAID: {
            label: "Confermata",
            color: "bg-takyon",
            tooltip: "Fattura Confermata",
          },
          RC: {
            label: "Consegnata",
            color: "bg-takyon",
            tooltip: "Fattura consegnata al destinatario.",
          },
          GENERATED: {
            label: "Xml generato",
            color: "bg-warning",
            tooltip: "Xml fattura generato.",
          },
          SEND_PROVIDER: {
            label: "Sdi",
            color: "bg-warning",
            tooltip: "Fattura inviata al SDI.",
          },
          "PRESA IN CARICO": {
            label: "Presa in carico",
            color: "bg-warning",
            tooltip: "Fattura accettata dal fornitore, da inviare a SDI.",
          },
          NS: {
            label: "Check non superati",
            color: "bg-danger",
            tooltip: "Fattura rifiutata dal SdI causa controlli non superati.",
          },
          "NON INVIATA": {
            label: "Non inviata",
            color: "bg-danger",
            tooltip: "Fattura al fornitore, ma si è verificato un errore.",
          },
          INVIATA: {
            label: "Inviata",
            color: "bg-takyon",
            tooltip:
              "La fattura è stata inviata al Sistema di Interscambio (SDI).",
          },
          MC: {
            label: "",
            color: "bg-takyon",
            tooltip:
              "La fattura è emessa e presente sul portale Fatture e Pagamenti del cliente, tuttavia non è stata consegnata tramite il metodo scelto.",
          },
          SEND_ANOTHER: {
            label: "Spedita in altro modo",
            color: "bg-warning",
            tooltip: "Fattura spedita con un altro servizio.",
          },
          "NON TROVATA": {
            label: "Non trovata",
            color: "bg-danger",
            tooltip: "La fattura non è stata trovata nel provider.",
          },
          ERROR: {
            label: "Error",
            color: "bg-danger",
            tooltip:
              "Il file della fattura è stato inviato al fornitore, che ha restituito un errore specifico.",
          },
        };

        const statusValue = row.original.payload?.status
          ? lightSignalMap[row.original.payload.status.toUpperCase()]
          : null;

        if (!statusValue) {
          return null;
        }

        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Badge
                  variant="outline"
                  className={`whitespace-nowrap ${statusValue.color}`}
                >
                  {statusValue.label}
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                <p>{statusValue.tooltip}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      },
    },
    {
      header: `${t("tables.actions")}`,
      accessorKey: "payload",
      cell: function render({ row }) {
        // console.log("apiKeys : ", apiKeys);
        return (
          <div className="flex gap-1">
            <Button
              variant={"ghost"}
              onClick={() =>
                showModal(
                  <PaymentBillingForm
                    billing={row.original.billingDetails}
                    readonly
                  />,
                  {
                    title: t("payment.title"),
                    description: " ",
                  }
                )
              }
            >
              <LucideEye size={16} />
            </Button>
            <Button
              variant={"ghost"}
              title={t("invoices.viewCharges_modal_title")}
              onClick={() =>
                showModal(
                  <ChargesLogModal
                    payment={
                      {
                        // _id: invoice.paymentMethodId,
                        _id: row.original.paymentMethodId,
                        subscriptionCurrentEndDate:
                          // invoice.payload?.period_end * 1000,
                          row.original.payload?.period_end * 1000,
                        subscriptionCurrentStartDate:
                          // invoice.payload?.period_start * 1000,
                          row.original.payload?.period_start * 1000,
                      } as any
                    }
                  />,
                  {
                    title: t("invoices.viewCharges_modal_title"),
                    description: "",
                  }
                )
              }
            >
              <ListChecks size={16} />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  // disabled={
                  //   // invoice.invoiceServiceStatus === "PRESA IN CARICO" ||
                  //   // invoice.invoiceServiceStatus === "SEND_PROVIDER" ||
                  //   // invoice.invoiceServiceStatus === "RC" ||
                  //   // invoice.invoiceServiceStatus === "SEND_ANOTHER"
                  //   row.original.payload.status.toUpperCase() ===
                  //     "PRESA IN CARICO" ||
                  //   row.original.payload.status.toUpperCase() ===
                  //     "SEND_PROVIDER" ||
                  //   row.original.payload.status.toUpperCase() === "RC" ||
                  //   row.original.payload.status.toUpperCase() ===
                  //     "SEND_ANOTHER" ||
                  //   row.original.payload.status.toUpperCase() === "PAID"
                  // }
                  // small
                  onClick={() =>
                    showModal(
                      <SendInvoice
                        // invoice={invoice}
                        invoice={row.original}
                        apikey={apiKeys[0]?.token}
                        onSuccess={onRefresh}
                      />,
                      {
                        title: t("invoices.sendInvoiceToBillingProvider"),
                        description: " ",
                      }
                    )
                  }
                >
                  {row.original.invoiceServiceStatus
                    ? t("invoices.resendInvoice")
                    : t("invoices.sendInvoice")}
                </DropdownMenuItem>

                {/* TODO - add check for invoiceServiceStatus */}
                {row.original.archived === false && (
                  <DropdownMenuItem
                    onClick={() =>
                      showModal(
                        <SendEmailInvoice
                          invoice={row.original}
                          onSuccess={onRefresh}
                        />,
                        {
                          title: t("invoices.sendEmailInvoice_title"),
                          description: " ",
                        }
                      )
                    }
                  >
                    {t("invoices.sendEmail")}
                  </DropdownMenuItem>
                )}
                {row.original.archived && (
                  <DropdownMenuItem
                    onClick={() =>
                      confirmModal(
                        t("invoices.unarchiveConfirmation"),
                        t("invoices.unarchiveConfirmationDescription"),
                        async () => {
                          await toggleInvoiceArchived(
                            row.original._id,
                            false,
                            row.original.invoceInternalId
                          );
                          onRefresh();
                        }
                      )
                    }
                  >
                    {row.original.archived
                      ? t("invoices.unarchive")
                      : t("invoices.archive")}
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },

    // {
    //   header: `${t("tables.actions")}`,
    //   accessorKey: "actions",
    //   // accessorFn: (payment) => `${payment.amount.toFixed(2)}`,
    //   //   accessorFn: (value) => {
    //   //     console.log("value : ", value);
    //   //     console.log("value.actions : ", value.actions);
    //   //   },
    //   //   accessorFn: (value) => value.actions,
    //   // cell: function render({ getValue }: { getValue: any }) {
    //   cell: function render(props: any) {
    //     // console.log("getValue('actions') : ", getValue("actions"));
    //     // console.log("props - row : ", props);
    //     let columns = [
    //       "_id",
    //       "email",
    //       "emailVerified",
    //       "firstName",
    //       "secondName",
    //       "phone",
    //       "stripeId",
    //       "stripeAccountDetailSubmitted",
    //       "lang",
    //       "isAdmin",
    //       "isStealth",
    //       "marketingEmails",
    //     ];
    //     // console.log("props : ", props);
    //     // console.log(
    //     //   "props.row.getValue('email') : ",
    //     //   props.row.getValue("email")
    //     // );

    //     let user: any = {};
    //     for (let i of columns) {
    //       user[i] = props.row.getValue(i);
    //     }
    //     // console.log("user : ", user);
    //     // let user = columns.map((el) => {
    //     //   return {...user, [el]: props.row.getValue(el) };
    //     // });
    //     // console.log("user : ", user);
    //     // console.log("onSuccess : ", onSuccess);

    //     return (
    //       //   <Button
    //       //     className="border-0 underline bg-transparent"
    //       //     onClick={() =>
    //       //       showModal(getValue(), { title: "", description: "" })
    //       //     }
    //       //   >
    //       //     {t("dev.create_alias")}
    //       //   </Button>
    //       <>
    //         {/* {console.log("onSuccess : ", onSuccess)} */}
    //         <Button
    //           onClick={(e) => {
    //             showModal(<CreateUserForm onSuccess={onSuccess} user={user} />);
    //           }}
    //           // text={String(t("dev.edit"))}
    //         >
    //           {t("dev.edit")}
    //         </Button>
    //         <Button
    //           onClick={() =>
    //             showModal(
    //               <GiveCreditsForm userId={user._id} hideModal={hideModal} />
    //             )
    //           }
    //           // text={String(t("dev.give_credits"))}
    //         >
    //           {t("dev.give_credits")}
    //         </Button>
    //         <Button
    //           onClick={async () => {
    //             navigate("");
    //             await loginAs({ email: user.email });
    //             window.location.reload();
    //           }}
    //           // text={String(t("dev.login_as"))}
    //         >
    //           {t("dev.login_as")}
    //         </Button>
    //       </>
    //     );
    //   },
    // },

    // {
    //   id: "actions",
    //   accessorKey: "id",
    //   header: t("offer.actions"),
    //   cell: function render({ getValue, row }) {
    //     const { showModal } = useModal();

    //     return (
    //       <DropdownMenu>
    //         <DropdownMenuTrigger asChild>
    //           <Button aria-haspopup="true" size="icon" variant="ghost">
    //             <MoreVertical className="h-4 w-4" />
    //             <span className="sr-only">Toggle menu</span>
    //           </Button>
    //         </DropdownMenuTrigger>
    //         <DropdownMenuContent align="end">
    //           {/* <DropdownMenuItem
    //             onClick={() =>
    //               showModal(<BuybackActionForm nft={row.original} />, {
    //                 title: t("nft.buyback"),
    //                 description: t("nft.buyback_text"),
    //               })
    //             }
    //           >
    //             {t("nft.buyback")}
    //           </DropdownMenuItem> */}
    //           <DropdownMenuItem>{t("form.action_delete")}</DropdownMenuItem>
    //         </DropdownMenuContent>
    //       </DropdownMenu>
    //     );
    //   },
    // },
  ];
};
