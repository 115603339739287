import GroupCollectionList from "./GroupCollectionList";
import GroupTeam from "./GroupTeam";
import GroupSettings from "./GroupSettings";
import { GROUP_PERMISSIONS, iGroup, verifyGroup } from "@/services/groups";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { useModal } from "@/context/ModalContext";
import { CreateCollectionForm } from "../form/CreateCollectionForm";
import {
  CirclePlus,
  EllipsisVertical,
  LucideSiren,
  Settings,
  Users,
} from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useRbac } from "@/hooks/rbac";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { iCollection } from "@/services/collection";
import { Separator } from "../ui/separator";
export default function GroupSection({
  group,
  collections,
  onRefresh,
}: {
  group: iGroup;
  collections: iCollection[];
  onRefresh(): void;
}) {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const { isSuperAdmin, can } = useRbac();

  const verify = async () => {
    await verifyGroup({ groupId: group._id });
    onRefresh();
  };

  const groupTeam = can(GROUP_PERMISSIONS.view_members, group);
  const groupSettings = can(GROUP_PERMISSIONS.update, group);
  const canGroupActions = groupTeam || groupSettings;

  return (
    <div>
      <div className="flex mb-8 justify-between">
        <div className="text-xl font-medium	overflow-hidden text-ellipsis whitespace-nowrap mr-1">
          {group.name}
        </div>
        {canGroupActions && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="cursor-pointer">
              <EllipsisVertical />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {groupTeam && (
                <DropdownMenuItem
                  className="flex items-center gap-2"
                  onClick={() =>
                    showModal(
                      <GroupTeam
                        group={group}
                        onRefresh={onRefresh}
                        members={group.members}
                      />,
                      {
                        title: "",
                        description: "",
                      }
                    )
                  }
                >
                  <Users size={18} className="text-gray-400"></Users>
                  {t("group.team")}
                </DropdownMenuItem>
              )}
              {groupSettings && (
                <DropdownMenuItem
                  className="flex items-center gap-2"
                  onClick={() =>
                    showModal(
                      <GroupSettings onRefresh={onRefresh} group={group} />,
                      {
                        title: "",
                        description: "",
                      }
                    )
                  }
                >
                  <Settings size={18} className="text-gray-400"></Settings>
                  {t("group.settings")}
                </DropdownMenuItem>
              )}
              {can(GROUP_PERMISSIONS.create_collection, group) && (
                <DropdownMenuItem>
                  <Button
                    variant={"gray"}
                    onClick={() => {
                      showModal(
                        <CreateCollectionForm
                          groupId={group._id}
                          onSuccess={onRefresh}
                        />,
                        {
                          title: t("group.create_collection"),
                          description: "",
                        }
                      );
                    }}
                  >
                    <CirclePlus size={18} className="mr-2"></CirclePlus>
                    {t("group.create_collection")}
                  </Button>
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      <Separator className="my-10" />

      {!group.verified && isSuperAdmin ? (
        <Alert className="mt-5">
          <LucideSiren className="h-4 w-4" color="red" />
          <AlertTitle>{t("group.not_verified_title")}</AlertTitle>
          <AlertDescription>
            <div className="flex">
              <div>
                {t("group.not_verified")}{" "}
                <a href={t("group.not_verified_1")} target="_blank">
                  {t("group.not_verified_1")}
                </a>
              </div>
              {isSuperAdmin && (
                <Button
                  onClick={() => {
                    verify();
                  }}
                  className="ml-auto px-2"
                  size="sm"
                >
                  {t("group.verify")}
                </Button>
              )}
            </div>
          </AlertDescription>
        </Alert>
      ) : null}

      <GroupCollectionList
        group={group}
        collections={collections}
        onRefresh={onRefresh}
      />
    </div>
  );
}
