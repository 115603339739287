import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CollectionPageChildProps } from "../CollectionPage";

const formSchema = z.object({
  averageBookingPrice: z.string(),
  averageNumberOfDaysPerStay: z.string(),
  averageOccupancyPercentage: z.string(),
  directBookingPercentage: z.string(),
  nonRefoundableSalesPercentage: z.string().email(),
});

export default function Prices({
  group,
  collection,
  loading,
  onRefresh,
}: CollectionPageChildProps) {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    console.log(data);
  };

  return (
    <div>
      <h1>Prices</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="averageBookingPrice"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("collection.census_averageBookingPrice")}*
                </FormLabel>
                <FormControl>
                  <Input placeholder={t("form.insert_price")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="averageNumberOfDaysPerStay"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("collection.averageNumberOfDaysPerStay")}
                </FormLabel>
                <FormControl>
                  <Input placeholder={t("form.insert_price")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="averageOccupancyPercentage"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("collection.averageOccupancyPercentage")}
                </FormLabel>
                <FormControl>
                  <Input placeholder={t("form.insert_perc")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="directBookingPercentage"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("collection.directBookingPercentage")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("form.insert_perc")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="nonRefoundableSalesPercentage"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t("collection.census_nonRefundableSalesPercentage")}
                </FormLabel>
                <FormControl>
                  <Input placeholder={t("form.insert_perc")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">{t("buttons.save_and_continue")}</Button>
        </form>
      </Form>
    </div>
  );
}
