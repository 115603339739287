import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./developer-webhook-logs";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { getCollectionsAliases, iCollection } from "@/services/collection";
import { useAuthContext } from "@/context/AuthContext";
import { getWebhookLogsPaginated, iWebhookLog } from "@/services/webhook";

export default function DeveloperWebhookLogsDatatable({}: {}) {
  const { t } = useTranslation();
  const { userId } = useAuthContext();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<iWebhookLog[]>();

  const [loading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const queryPayload: any = {};

      if (searchTerm) {
        queryPayload.$or = [
          // { name: { $regex: searchTerm, $options: "i" } },
        ];
      }

      const payload: any = {
        page: page + 1,
        size: pageSize,
        query: {
          // ...filters,
          // ...sorting,
          ...queryPayload,
        },
      };

      // getWebhookLogsPaginated is different from others paginated services
      // because it accept only page number as parameter
      // and if no results return an empty array
      const result = await getWebhookLogsPaginated(payload.page);

      console.log("DeveloperWebhookLogsDatatable - result", result);
      if (Array.isArray(result) && result.length === 0) {
        setData([]);
        setPageCount(0);
      } else {
        const pageCount = Math.ceil(result.total / pageSize);

        setData(result.data);
        setPageCount(pageCount);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [page, pageSize, sorting, filters, searchTerm]);

  const columns = useMemo(() => getColumns(t, fetchData), []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!data) return null;

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={false}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
      />
    </div>
  );
}
