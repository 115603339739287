import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserDatatable from "./UserDatatable";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function Users() {
  const { t } = useTranslation();

  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("users.page_title")}`);
  }, [setTitle]);

  return <UserDatatable />;
}
