import { Separator } from "@radix-ui/react-separator";
import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CollectionPageChildProps } from "../CollectionPage";
import { getDefaultLink } from "@/lib/links";

const CompletionMessage = ({ collection }: CollectionPageChildProps) => {
  const { setBackBtn, setProgress, setTitleCollectionPage, setInfoTip } =
    useOutletContext<any>();
  const navigate = useNavigate();
  const { collectionId } = useParams<{ collectionId: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    setBackBtn(false);
    setProgress(100);
    setTitleCollectionPage(t("boarding.step3.title"));
    setInfoTip("");
  }, []);

  return (
    <div className="h-full pt-8">
      <p className="mb-20">{t("boarding.step3.message")}</p>
      <div className="flex flex-col">
        <Separator id="separator" className="mb-8 bg-gray-200 h-0.5" />
        <div className="flex flex-row justify-between">
          {/* <Button
            className=""
            type="button"
            onClick={() =>
              navigate(`/collection/${collectionId}/infos/payments`)
            }
          >
            {t("auth.back")}
          </Button> */}
          <Button
            variant={"takyon"}
            className=""
            type="button"
            onClick={() => {
              const link = getDefaultLink(collection);
              navigate(link);
            }}
          >
            {t("boarding.step3.gotocollection")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompletionMessage;
