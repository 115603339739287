import { Button } from "@/components/ui/button";
import { iUser, loginAs } from "@/services/auth";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import GiveCreditsForm from "./forms/GiveCreditsForm";
import UserForm from "./forms/UserForm";
import CopyId from "@/components/helpers/CopyId";
import { LucideEdit, MoreVertical } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import DataTableBooleanCell from "@/components/datatable/data-table-boolean-cell";
// import DeleteModal from "@/components/ui/deleteModal";

// interface UserFormProps {
//   onSuccess: Function;
//   user?: iUser;
// }

export const getColumns = (
  t: TFunction,
  showModal: (content: React.ReactNode, options?: any) => void,
  hideModal: () => void,
  onRefresh: () => void,
  navigate: Function
): ColumnDef<iUser>[] => {
  return [
    {
      header: "ID",
      accessorKey: "_id",
      cell: ({ getValue }) => <CopyId resourceId={getValue() as string} />,
    },
    {
      header: t("users.email"),
      accessorKey: "email",
    },
    {
      header: t("users.emailVerified"),
      accessorKey: "emailVerified",
      cell: ({ getValue }) => (
        <DataTableBooleanCell value={getValue() as boolean} />
      ),
    },
    {
      header: t("users.firstName"),
      accessorKey: "firstName",
    },
    {
      header: t("users.secondName"),
      accessorKey: "secondName",
    },
    {
      header: t("users.phone"),
      accessorKey: "phone",
    },
    {
      header: t("users.stripeId"),
      accessorKey: "stripeId",
    },
    {
      header: t("users.stripeFlag"),
      accessorKey: "stripeAccountDetailSubmitted",
    },
    {
      header: t("users.lang"),
      accessorKey: "lang",
    },
    {
      header: t("users.isAdmin"),
      accessorKey: "isAdmin",
      cell: ({ getValue }) => (
        <DataTableBooleanCell value={getValue() as boolean} />
      ),
    },
    {
      header: t("users.wallet"),
      accessorKey: "wallet",
    },
    {
      header: t("users.isStealth"),
      accessorKey: "isStealth",
      cell: ({ getValue }) => (
        <DataTableBooleanCell value={getValue() as boolean} />
      ),
    },
    {
      header: t("users.marketingEmails"),
      accessorKey: "marketingEmails",
      cell: ({ getValue }) => (
        <DataTableBooleanCell value={getValue() as boolean} />
      ),
      // accessorFn: (user) => `${user?.marketingEmails ? "Yes" : "No"}`,
    },
    {
      header: `${t("tables.actions")}`,
      accessorKey: "actions",
      cell: function render({ getValue, row }) {
        return (
          <div className="flex gap-2">
            <Button
              variant="ghost"
              onClick={(e) => {
                showModal(
                  <UserForm
                    onSuccess={() => {
                      hideModal();
                      onRefresh();
                    }}
                    user={row.original}
                  />,
                  {
                    title: t("users.edit_user"),
                    description: "",
                  }
                );
              }}
              // text={String(t("dev.edit"))}
            >
              <LucideEdit size={16} />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button aria-haspopup="true" size="icon" variant="ghost">
                  <MoreVertical className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() =>
                    showModal(
                      <GiveCreditsForm
                        userId={row.original._id}
                        onSuccess={hideModal}
                      />,
                      {
                        title: t("users.give_credits_modalTtitle"),
                        description: t("users.give_credits_modalDescription"),
                      }
                    )
                  }
                >
                  {t("users.give_credits")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={async () => {
                    navigate("");
                    await loginAs({ email: row.original.email });
                    window.location.reload();
                  }}
                  disabled={!row.original.emailVerified}
                >
                  {t("users.login_as")}
                </DropdownMenuItem>
                {/* <DropdownMenuItem
                  onClick={() =>
                    showModal(
                      <DeleteModal
                        onRemove={() => deleteUser(row.original._id!)}
                      />,
                      {
                        title: t("modals.remove_modal"),
                        description: t("modals.remove_modal_text"),
                      }
                    )
                  }
                >
                  {t("nft.delete")}
                </DropdownMenuItem> */}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];
};
