"use client";

import { useEffect, useState } from "react";
import { Combobox, ComboboxOptions } from "./Combobox";
import { iCollection } from "@/services/collection";
import { LANG } from "@/services/i18next";

export function RoomsSuggestion({
  collection,
  selected,
  onSelect,
}: {
  collection?: iCollection;
  selected: string;
  onSelect: (selection: any) => void;
}) {
  const [roomNamesSuggestion, setRoomNamesSuggestion] = useState<
    ComboboxOptions[]
  >([]);

  useEffect(() => {
    const names: ComboboxOptions[] = [];

    if (selected) {
      // Add the selected value to the suggestion list
      names.push({ value: selected, label: selected });
    }

    if (collection && collection.census?.info) {
      const langs = Object.keys(collection.census.info);

      langs.forEach((lang) => {
        const rooms = collection.census?.info?.[lang as LANG]?.rooms;
        if (!rooms?.length) return;

        const roomNames = rooms
          .map(
            (room: any) =>
              ({ value: room.name, label: room.name } as ComboboxOptions)
          )
          .filter((room: ComboboxOptions) => room.value !== selected);

        names.push(...roomNames);
      });
    }

    setRoomNamesSuggestion(names);
  }, [collection, selected]);

  const handleAddNewOption = (value: string) => {
    setRoomNamesSuggestion([...roomNamesSuggestion, { value, label: value }]);
    onSelect(value ? value : undefined);
  };

  return (
    <Combobox
      mode="single"
      options={roomNamesSuggestion}
      selected={selected}
      onChange={onSelect}
      onCreate={handleAddNewOption}
    />
  );
}
