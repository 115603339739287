import { useEffect, useMemo, useState } from "react";
import { getColumns } from "./translations-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";

export default function TranslationsDatatable({
  translations,
  loadCollections,
  fillIndex,
  navigate,
}: {
  translations: any[];
  loadCollections: Function;
  fillIndex: Function;
  navigate: Function;
}) {
  //   const { collectionId } = useParams<{ collectionId: string }>();

  //   useEffect(() => {
  //     console.log("Load collection data", collectionId);
  //   }, [collectionId]);

  // const navigate = useNavigate();
  const { t } = useTranslation();
  // const { showModal, hideModal } = useModal();
  const columns = useMemo(
    () =>
      getColumns(
        t,
        // showModal,
        // hideModal,
        loadCollections,
        // translations?.census,
        // translations?.census?.info.lang,
        fillIndex,
        navigate
      ),
    []
  );

  const [loading, setLoading] = useState(false); //ToDO: Hanlde loading

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  //   const [searchTerm, setSearchTerm] = useState("");

  //   const [data, setData] = useState<iCollection[]>([]);

  //   const fetchData = useCallback(async () => {
  //     console.log("fetchData  inside start");
  //     setLoading(true);
  //     try {
  //       // console.log("getBidsPaginated", payload);
  //       // const result = await getBidsPaginated(payload);
  //       const data = await getPendingPayments(
  //         paymentMethodId,
  //         page,
  //         collectionId
  //       );
  //       const pageCount = Math.ceil(data.length / pageSize);
  //       setData(data);
  //       setPageCount(pageCount);
  //       console.log("fetchData inside result.data", data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }, [collectionId, page, pageSize, filters, sorting, searchTerm]);

  //   useEffect(() => {
  //     console.log("fetchData hook start");
  //     fetchData();
  //     console.log("fetchData hook end");
  //   }, [fetchData]);

  useEffect(() => {
    if (Array.isArray(translations)) {
      setPageCount(Math.ceil(translations.length / pageSize));
    }
  }, [page]);

  useEffect(() => {
    if (Array.isArray(translations)) {
      setPageCount(Math.ceil(translations.length / pageSize));
    }
  }, [pageSize, translations]);

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={translations.slice(page * pageSize, page * pageSize + pageSize)}
        columns={columns}
        pageCount={pageCount}
        globalSearch={false}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
      />
    </div>
  );
}
