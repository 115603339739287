import { iCollection } from "@/services/collection";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSectionTitle } from "@/context/SectionTitleContext";
import TaksDatatable from "./TaksDatatable";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Info } from "lucide-react";

export default function TaksTab({ collection }: { collection: iCollection }) {
  const { t } = useTranslation();
  const { setTitle } = useSectionTitle();

  useEffect(() => {
    setTitle(`${t("collection.tab_bookings")} - ${collection.name}`);
  }, [setTitle]);
  return (
    <div>
      <div className="flex items-center justify-start gap-1 w-full pb-5 ">
        <p className="text-xl font-semibold">{t("collection.tab_bookings")}</p>
        <Popover>
          <PopoverTrigger asChild>
            <Info className="cursor-pointer" size={16} />
          </PopoverTrigger>
          <PopoverContent className="w-80 ml-5">
            <p>{t("collection.tab_taks_description")}</p>
          </PopoverContent>
        </Popover>
      </div>
      <TaksDatatable collection={collection} />
    </div>
  );
}
