import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";

// initialize axios
import { initializeAxios } from "./services/axios";

initializeAxios();
// initi18next();

// if (process.env.REACT_APP_ENV === "production") initializeTagManager();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
