import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createFaqInCategory,
  editFaqInCategory,
  iFaq,
  iSupportFaq,
} from "../../../../services/supportfaqs";

import { useModal } from "@/context/ModalContext";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { LoadingSubmitButton } from "@/components/form_fields/LoadingSubmitButton";

interface FaqFormProps {
  refresh: Function;
  category: iSupportFaq;
  faq?: iFaq;
}

export default function FaqForm({ refresh, category, faq }: FaqFormProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { hideModal } = useModal();

  const formSchema = z.object({
    language: z.string(),
    question: z.string().min(1),
    answer: z.string().min(1),
    _faqId: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      language: category.lang,
      question: faq?.question || "",
      answer: faq?.answer || "",
      _faqId: faq?._faqId,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsLoading(true);

    try {
      if (!faq) {
        await createFaqInCategory({ _id: category._id, faq: data });
      } else {
        await editFaqInCategory({ _id: category._id, faq: data });
      }

      hideModal();
      refresh?.();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  isLoading && <div className="loader"></div>;

  return (
    <div id="create-faq-modal" className="p-2">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("support.language")} *</FormLabel>
                <FormControl>
                  <Input placeholder={t("support.language")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <label htmlFor="question">{t("support.question")}</label>
        <textarea id="question" name="question" required /> */}
          <FormField
            control={form.control}
            name="question"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("support.question")} *</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder={t("support.question")}
                    className="min-h-14 max-h-40 h-32"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="answer"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("support.answer")} *</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder={t("support.answer")}
                    className="min-h-14 max-h-40 h-32"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="_faqId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("support.answer")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("support.language")}
                    type="hidden"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <LoadingSubmitButton
            className="btn btn-primary"
            variant="takyon"
            isLoading={isLoading}
          >
            {t("support.save")}
          </LoadingSubmitButton>
        </form>
      </Form>
    </div>
  );
}
