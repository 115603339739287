import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { iCollection } from "@/services/collection";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { LucideEdit, LucidePlusCircle } from "lucide-react";
import FormDeveloperAlias from "./FormDeveloperAlias";
import { iCollectionAlias } from "@/services/alias";

interface iCollectionWithAlias extends iCollection {
  CollectionAlias?: iCollectionAlias;
}

export const getColumns = (
  t: TFunction,
  onRefresh: () => void
): ColumnDef<iCollectionWithAlias>[] => {
  return [
    {
      header: t("collection.name"),
      accessorKey: "name",
    },
    {
      header: t("dev.collection_id"),
      accessorKey: "_id",
    },
    {
      header: `${t("dev.collection_alias")}`,
      accessorFn: (collection: iCollectionWithAlias) =>
        collection.CollectionAlias?.alias,
      cell: ({ getValue, row }) => {
        const { showModal, hideModal } = useModal();
        if (!getValue()) {
          return (
            <Button
              className="border-0 text-muted text-gray-400 bg-transparent flex gap-1"
              onClick={() =>
                showModal(
                  <FormDeveloperAlias
                    collectionId={row.original._id}
                    onSuccess={() => {
                      onRefresh();
                      hideModal();
                    }}
                  />,
                  {
                    title: `${t("dev.collection_alias_modal_text")} ${
                      row.original.name
                    }`,
                    description: "", //t("dev.collection_alias_modal_description"),
                  }
                )
              }
            >
              {t("dev.add_alias")} {"  "}
              <LucidePlusCircle size={16} />
            </Button>
          );
        } else {
          return (
            <>
              <span>{getValue() as string}</span>
              <Button
                size="icon"
                className="border-0 underline bg-transparent"
                onClick={() =>
                  showModal(
                    <FormDeveloperAlias
                      collectionId={row.original._id}
                      alias={row.original.CollectionAlias}
                      onSuccess={() => {
                        onRefresh();
                        hideModal();
                      }}
                    />,
                    {
                      title: `${t("dev.collection_alias_modal_text")}`,
                      description: "", // t("dev.collection_alias_modal_description"),
                    }
                  )
                }
              >
                <LucideEdit size={16} />
              </Button>
            </>
          );
        }
      },
    },
  ];
};
