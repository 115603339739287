import { FormEvent, useEffect, useState } from "react";
import { LANG } from "../../../../services/i18next";

import { createCategory } from "../../../../services/supportfaqs";
import { compressImage, formToObject } from "../../../../utils/generic";
import { useTranslation } from "react-i18next";
// import Button from "../../../components/dom/Button";
import { Button } from "@/components/ui/button";
// import Select from "../../../components/dom/Select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// import closeIcon from "../../../assets/icons/close.svg";
import { useModal } from "@/context/ModalContext";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { CircleX } from "lucide-react";

interface CategoryFormProps {
  onSuccess: Function;
  category?: any;
}

export default function CategoryForm({
  onSuccess,
  category,
}: CategoryFormProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { hideModal } = useModal();

  const [error, setError] = useState("");

  const [images, setImages] = useState<string[]>([]);

  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (category && category.icons && category.icons.length > 0) {
      setImages(category.icons);
    }
  }, [category]);

  const imageChangeHandler = async (e: any) => {
    const imageFiles = Array.from(e.target.files);

    setIsLoading(true);

    const readAsyncPromises = imageFiles.map(async (image: any) => {
      return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage as string);
          }
        };
      });
    });

    const newImages = await Promise.all(readAsyncPromises);

    if (clickedIndex !== null) {
      const updatedImages = [...images];
      updatedImages[clickedIndex] = newImages[0];
      setImages(updatedImages);

      setClickedIndex(null);
    } else {
      if (images.length + newImages.length <= 2) {
        setImages([...images, ...newImages]);
      } else {
        setError("You can only upload 2 images");
      }
    }
    setIsLoading(false);
  };

  const handleImageClick = (index: number) => {
    document.getElementById("category-icon")?.click();
    setClickedIndex(index);
  };

  const handleImageDelete = (indexToDelete: number) => {
    const updatedImages = [...images];
    updatedImages.splice(indexToDelete, 1);
    setImages(updatedImages);
    setError("");
  };

  isLoading && <div className="loader"></div>;

  const formSchema = z.object({
    _id: z.string().optional(),
    faqs: z.string().array().optional(),
    icons: z.string().array().optional(),
    lang: z.string(),
    name: z.string(),
    icon: z
      .instanceof(FileList)
      .optional()
      .refine(
        (files) =>
          !files || files.length === 0 || files[0].size <= 5 * 1024 * 1024,
        { message: "Il file deve essere minore di 5MB" }
      )
      .refine(
        (files) =>
          !files ||
          files.length === 0 ||
          files[0].type === "image/jpeg" ||
          files[0].type === "image/png",
        { message: "Accettiamo solo file JPEG o PNG" }
      ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lang: category?.lang,
      name: category?.name,
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setError("");

    setIsLoading(true);

    try {
      category?._id && (data._id = category._id);
      category?.faqs ? (data.faqs = category.faqs) : (data.faqs = []);

      images.length > 0 ? (data.icons = images) : (data.icons = []);

      await createCategory(data);
      hideModal();
      onSuccess();
    } catch (error: any) {
      const errorMessage = String(error.response).toLowerCase();
      console.log(errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <div id="faq-modal">
      <Form {...form}>
        {/* <form onSubmit={form.handleSubmit(createCategorySubmit)}> */}
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="lang"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("support.category.language")}</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={"Seleziona la lingua"} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(LANG).map((s, index) => (
                      <SelectItem
                        key={"item" + index}
                        value={s ?? t("form.select")}
                      >
                        {s}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("support.category.name")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("support.category.name")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="item-content">
            <FormField
              control={form.control}
              name="icon"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("support.category.icon")}</FormLabel>
                  <FormControl>
                    <Input
                      id="file-input"
                      type="file"
                      accept="image/png, image/jpeg"
                      placeholder={"Select a file"}
                      // {...field}
                      onChange={(e) => {
                        const files = e.target.files;
                        imageChangeHandler(e);
                        if (files && files.length > 0) {
                          field.onChange(files);
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex my-6 items-center justify-between">
              <Button
                variant="takyon"
                className="choose-btn"
                onClick={() => {
                  document.getElementById("category-icon")?.click();
                }}
              >
                {t("support.upload_icon")}
              </Button>
              <div className="flex gap-4">
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundImage: `url(${image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                    className="w-24 h-24"
                    onClick={() => handleImageClick(index)}
                  >
                    <div
                      className="flex justify-center items-center w-6 h-6 float-right cursor-pointer rounded-full bg-white"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleImageDelete(index);
                      }}
                    >
                      <CircleX />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            {error && <span className="text-rose-600">{error}</span>}

            <Button variant="takyon" className="btn btn-primary" type="submit">
              {t("support.save")}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
