import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./developer-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { getCollectionsAliases, iCollection } from "@/services/collection";
import { useAuthContext } from "@/context/AuthContext";

export default function DeveloperAliasesDatatable({}: {}) {
  const { t } = useTranslation();
  const { userId } = useAuthContext();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<iCollection[]>();

  const [loading, setLoading] = useState(false); //ToDO: Hanlde loading

  const fetchData = useCallback(async () => {
    // setLoading(true);
    try {
      const queryPayload: any = {};

      if (searchTerm) {
        queryPayload.$or = [
          { name: { $regex: searchTerm, $options: "i" } },
          { _id: { $regex: searchTerm, $options: "i" } },
          { "CollectionAlias.alias": { $regex: searchTerm, $options: "i" } }, // related Alias collection
        ];
      }

      const payload: any = {
        page: page + 1,
        size: pageSize,
        query: {
          // ...filters,
          // ...sorting,
          ...queryPayload,
        },
      };

      const result = await getCollectionsAliases(payload);
      const pageCount = Math.ceil(result.total / pageSize);
      setData(result.data);
      setPageCount(pageCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setLoading(false);
    }
  }, [page, pageSize, sorting, filters, searchTerm]);

  const columns = useMemo(() => getColumns(t, fetchData), []);

  useEffect(() => {
    console.log("DeveloperAliasesDatatable - useEffect on load");
    fetchData();
  }, [fetchData]);

  console.log("DeveloperAliasesDatatable - data", data, searchTerm);

  if (!data) return null;

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={true}
        onGlobalSearch={(filterValue) => {
          setSearchTerm(filterValue);
        }}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
      />
    </div>
  );
}
