import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Webhook, iWebhookLog, getWebhook } from "@/services/webhook";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { H3 } from "@/components/typography/Typography";
import { Separator } from "@/components/ui/separator";

import { useModal } from "@/context/ModalContext";

import DeveloperSettingsForm from "./DeveloperSettingsForm";
import DeveloperAliasesDatatable from "./DeveloperAliasesDatatable";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useSectionTitle } from "@/context/SectionTitleContext";
import DeveloperWebhookLogsDatatable from "./DeveloperWebhookLogsDatatable";
import DeveloperApiKeys from "./DeveloperApiKeys";
import DeveloperWebhookConfig from "./DeveloperWebhookConfig";

export default function DeveloperSettings() {
  const { t } = useTranslation();

  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(t("dev.title"));
  }, [setTitle]);

  console.log("Developer settings render...");

  return (
    <section>
      <DeveloperApiKeys />
      <Separator className="my-8" />
      <DeveloperWebhookConfig />
      <div className="hr"></div>
      <div className="space-y-3">
        <H3 className="title mb-2">{t("dev.collections_alias")}</H3>
        <p>{t("dev.collections_text")}</p>
        <DeveloperAliasesDatatable />
      </div>
    </section>
  );
}
