import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANG } from "@/services/i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editDocument, iDocument } from "@/services/documents";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useModal } from "@/context/ModalContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface DocProps {
  onSuccess: Function;
  doc?: iDocument;
  onExit?: Function;
}

export default function CreateDocModal({ onSuccess, doc, onExit }: DocProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");
  const [textValue, setTextValue] = useState(doc ? doc.text : "");
  const { hideModal } = useModal();
  const [selectValue, setSelectValue] = useState({
    lang: "",
    type: "",
    name: "",
  });

  const formSchema = z.object({
    _id: z.string().optional(),
    lang: z.string(),
    type: z.string(),
    name: z.string(),
    text: z.string(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lang: doc?.lang,
      type: doc?.type,
      name: doc?.name,
      text: doc?.text,
    },
  });
  // const createEditSubmit = async (e: FormEvent<HTMLFormElement>) => {
  const createEditSubmit = async (data: z.infer<typeof formSchema>) => {
    // e.preventDefault();
    setError("");

    setIsLoading(true);

    try {
      // const data = formToObject(e.target);
      if (doc?._id) data._id = doc._id;
      // data.text = textValue;

      await editDocument(data);
      hideModal();
      onSuccess();
    } catch (error: any) {
      const errorMessage = String(error.response).toLowerCase();
      console.log(errorMessage);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (doc) {
      setSelectValue(doc);
    }
  }, [doc]);

  return (
    <div id="create-document-modal">
      <div>
        <Button
          className="mb-4"
          onClick={() => onExit?.()}
          // small
          // text={String(t("payment.back"))}
        >
          {t("payment.back")}
        </Button>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(createEditSubmit)}
          className="flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="lang"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("documents.language")} *</FormLabel>
                <Select
                  // onValueChange={(e) => {
                  //   setSelectValue({ ...selectValue, lang: e });
                  //   field.onChange;
                  // }}
                  // value={selectValue?.lang ?? LANG.en}
                  onValueChange={field.onChange}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={"Seleziona la lingua"} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(LANG).map((s, index) => (
                      <SelectItem
                        key={"item" + index}
                        value={s ?? t("form.select")}
                      >
                        {s}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          {/*         
        <label htmlFor="language">{t("documents.language")}</label>
        <Select
          name="lang"
          options={Object.keys(LANG).map((lang) => {
            return {
              text: lang,
              value: lang,
            };
          })}
          className="lang"
          defaultValue={doc?.lang ?? LANG.en}
          id="language"
          required
        /> */}

          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("documents.doc_type")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("collection.census_email_placeholder")}
                    {...field}
                    // value={doc?.type ?? ""}
                    // onChange={(e) => {
                    //   setSelectValue({ ...selectValue, type: e.target.value });
                    //   field.onChange;
                    // }}
                    // value={selectValue?.type}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <label htmlFor="type">{t("documents.doc_type")}</label>
        <input
          type="text"
          id="type"
          name="type"
          defaultValue={doc?.type ?? ""}
          required
        /> */}

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("documents.doc_name")} *</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("collection.census_email_placeholder")}
                    {...field}
                    // value={doc?.name ?? ""}
                    // onChange={(e) => {
                    //   setSelectValue({ ...selectValue, name: e.target.value });
                    //   field.onChange;
                    // }}
                    // value={selectValue?.name}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <label htmlFor="name">{t("documents.doc_name")}</label>
        <input
          type="text"
          id="name"
          name="name"
          defaultValue={doc?.name ?? ""}
          required
        /> */}

          {/* <label htmlFor="text">{t("documents.doc_text")}</label> */}
          <FormLabel>{t("documents.doc_text")} *</FormLabel>

          <FormField
            control={form.control}
            name="text"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("documents.text")} *</FormLabel>
                <FormControl>
                  <ReactQuill
                    theme="snow"
                    {...field}
                    // value={textValue}
                    // onChange={(val) => {
                    //   setTextValue(val);
                    //   field.onChange;
                    // }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <ReactQuill
            theme="snow"
            value={textValue}
            onChange={(val) => setTextValue(val)}
          /> */}

          {error ? <span className="error">{error}</span> : null}

          <Button
            className="btn-edit-doc"
            variant="takyon"
            // text={String(t("documents.save"))}
            type="submit"
            // loading={isLoading}
          >
            {t("documents.save")}
          </Button>
        </form>
      </Form>
    </div>
  );
}
