import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { iCollection } from "@/services/collection";
import { useModal } from "@/context/ModalContext";
import { editWebhook, Webhook } from "@/services/webhook";
import { formToObject } from "@/utils/generic";
import { iCollectionAlias } from "@/services/alias";
import { iApiKey } from "@/services/apikey";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";

const DeveloperSettingsForm = ({
  webhook,
  setWebhook,
}: {
  webhook: Webhook | undefined;
  setWebhook: Function;
}) => {
  const { t } = useTranslation();
  const formSchema = z.object({
    url: z.string().url().trim().min(1),
    key: z.string(),
    unsafe: z.union([z.string(), z.boolean()]),

    // locationField: z.string(),
    // email: z.string().email(),
    // website: z.string(),
    // payment: z.boolean(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    values: {
      url: webhook?.url || "",
      key: webhook?.key || "",
      unsafe: webhook?.unsafe || false,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    // e.preventDefault();
    setIsLoading(true);
    try {
      //   const payload = await formToObject(e.target);
      // console.log("data - prima - onSubmit : ", data);
      data.key = webhook?.key || "";
      data.unsafe = data.unsafe === true ? "on" : "off";
      //   delete data.secret;
      // console.log("data - dopo - onSubmit : ", data);
      //   const payload = await formToObject(data);
      //   console.log("payload - onSubmit : ", payload);

      const webHook = await editWebhook(data);
      // console.log("webHook - onSubmit : ", webHook);

      setWebhook(webHook);
      //   setIsWHButtonDisabled(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col mt-8"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("dev.webhook_label")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("dev.webhook_label_placeholder")}
                  {...field}
                  //   onChange={() => setIsWHButtonDisabled(false)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="key"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("dev.webhook_key")}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t("dev.webhook_key_placeholder")}
                  readOnly
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="unsafe"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 my-4">
              <FormControl className="mt-2">
                <Switch
                  checked={field.value ? true : false}
                  onCheckedChange={field.onChange}
                  //   onChange={() => setIsWHButtonDisabled(false)}
                />
                {/* <Input
                  placeholder="Inserisci secret"
                  {...field}
                  value={webhook?.key}
                /> */}
              </FormControl>
              <FormLabel className="mb-2">{t("dev.webhook_unsafe")}</FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormLabel className="text-black font-semibold mb-8 mt-6">
          {t("dev.webhook_failed")}: {webhook?.amountFailed || 0}
        </FormLabel>
        <FormItem>
          <Button
            className="text-black font-semibold"
            // disabled={isWHButtonDisabled}

            // light={isWHButtonDisabled}
            // loading={isLoading}
            // text={String(t("dev.save"))}
            type="submit"
          >
            {t("dev.save")}
          </Button>
        </FormItem>
        {/* ************************************** */}
        {/*         
        <div className="flex flex-col mb-4">
          <label className="text-black font-semibold" htmlFor="">
            {t("dev.webhook_label")}
          </label>
          <Input
            defaultValue={webhook?.url}
            disabled={isLoading}
            type="text"
            name="url"
            placeholder="Inserisci endpoint"
            onChange={() => setIsWHButtonDisabled(false)}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="text-black font-semibold" htmlFor="">
            {t("dev.webhook_key")}
          </label>
          <Input
            type="text"
            readOnly
            placeholder="Inserisci secret"
            defaultValue={webhook?.key}
          />
        </div> */}

        {/* <div className="flex gap-2 align-items-center">
          <Checkbox
            // error
            name="unsafe"
            checked={webhook?.unsafe}
            onChange={() => setIsWHButtonDisabled(false)}
          />
          <Switch
            // error
            name="unsafe"
            checked={webhook?.unsafe}
            onChange={() => setIsWHButtonDisabled(false)}
          />
          <label htmlFor="">{t("dev.webhook_unsafe")}</label>
        </div>
        <div>
          <p className="text-black font-semibold mb-8 mt-6">
            {t("dev.webhook_failed")}: {webhook?.amountFailed}
          </p>
          <Button
            className="text-black font-semibold"
            disabled={isWHButtonDisabled}
            // light={isWHButtonDisabled}
            // loading={isLoading}
            // text={String(t("dev.save"))}
            type="submit"
          >
            {t("dev.save")}
          </Button>
        </div>
        <Separator className="my-8" /> */}
      </form>
      <Separator className="my-8" />
    </Form>
  );
};

export default DeveloperSettingsForm;
