import { iCollection } from "@/services/collection";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getColumns } from "./nfts-to-check-columns";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "@/components/datatable/data-table";
import { useParams } from "react-router-dom";
import {
  getNftsOfCollectionPaginated,
  iNft,
  NFT_SELL_STATUS,
} from "@/services/nft";

export default function NftsToCheckDatatable({
  collection,
}: {
  collection: iCollection;
}) {
  const { collectionId } = useParams<{ collectionId: string }>();

  const { t } = useTranslation();

  const columns = useMemo(() => getColumns(collection, t), [collection]);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState<iNft[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      // let textQ = {};
      // if (searchTerm) {
      //   textQ = {
      //     $or: [
      //       { _id: { $regex: searchTerm, $options: "i" } },
      //       { shortId: { $regex: searchTerm, $options: "i" } },
      //       { owner: { $regex: searchTerm, $options: "i" } },
      //       { "payload.reference": { $regex: searchTerm, $options: "i" } },
      //     ],
      //   };
      // }

      const payload = {
        collectionId,
        page: page + 1,
        size: pageSize,
        query: { sellStatus: NFT_SELL_STATUS.PENDING_SALE },
      };

      const result = await getNftsOfCollectionPaginated(payload);

      const pageCount = Math.ceil(result.total / pageSize);
      setPageCount(pageCount);
      setData(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [collectionId, page, pageSize, filters, sorting, searchTerm]);

  useEffect(() => {
    console.log("fetchData hook start");
    fetchData();
    console.log("fetchData hook end");
  }, [fetchData]);

  return (
    <div>
      <DataTableComponent
        isLoading={loading}
        data={data}
        columns={columns}
        pageCount={pageCount}
        globalSearch={false}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onSortingChange={setSorting}
        onFiltersChange={setFilters}
      />
    </div>
  );
}
