import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Loader2, Upload } from "lucide-react";
import { compressImage } from "../../../utils/generic";
import { patchCollection } from "@/services/collection";

export default function CollectionImages(props: any) {
  const {
    saveBtn,
    images,
    setImages,
    coverImage,
    logo = false,
    cover,
    isLoading,
    setIsLoading,
    collection,
  } = props;

  const { t } = useTranslation();
  const [multipleImages, setMultipleImages] = useState(false);

  useEffect(() => {
    if (saveBtn) {
      setMultipleImages(true);
    }
    if (logo) {
      setMultipleImages(false);
    }
  }, []);

  const imagesUploadInput = (e: any) => {
    const _images = Array.from(e.target.files);

    _images.forEach(async (image: any) => {
      setIsLoading(true);
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setImages((_images: any) => {
        if (cover) {
          return [readAsync];
        }
        const imagesCopy = [..._images];
        imagesCopy.push(readAsync as string);
        if (imagesCopy.length > 10) {
          return [...imagesCopy.slice(0, 10)];
        }
        return [...imagesCopy];
      });
      setIsLoading(false);
    });
  };

  // const saveImages = async (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   let allImgs = [];
  //   if (coverImage) {
  //     allImgs = [...coverImage, ...images];
  //   } else {
  //     allImgs = [...images];
  //   }

  //   setIsLoading(true);
  //   try {
  //     await patchCollection({ images: allImgs, collectionId: collection._id });
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setIsLoading(false);
  // };

  return (
    <section id="collection-images" className="pb-8">
      <div className="img-preview-container mt-2 flex gap-x-4 overflow-x-auto">
        {isLoading && (
          <div className="flex gap-4 justify-center items-center h-24 w-full">
            <Loader2 className="mr-2 w-20 animate-spin" />
          </div>
        )}
        {!isLoading &&
          images.map(
            (i: any, key: number) =>
              i && (
                <div
                  key={"img_" + Math.random().toString()}
                  className="img-preview mx-2"
                >
                  <input
                    readOnly
                    type="text"
                    hidden
                    value={i}
                    name="images[]"
                  />
                  <div
                    onClick={() => {
                      setImages((images: any) => {
                        if (images.length === 1) {
                          return [];
                        }
                        const imagesCopy = [...images];
                        imagesCopy.splice(key, 1);
                        return [...imagesCopy];
                      });
                    }}
                    className="flex delete cursor-pointer justify-end"
                  >
                    <span className="m-0 flex justify-center items-center w-7 h-7 border-r-gray-200 rounded-full pb-1.5 rotate-45 text-2xl border bg-white -mr-3.5 -mb-4">
                      +
                    </span>
                  </div>
                  {/* )} */}
                  <img
                    src={i}
                    // style={{
                    //   backgroundImage: `url(${i})`,
                    //   backgroundSize: "cover",
                    //   backgroundPosition: "center center",
                    // }}
                    className="image cursor-pointer rounded-md w-24 mb-4 min-w-24 hover:outline"
                    onClick={() => {
                      setImages((images: any) => {
                        const imagesCopy = [...images];
                        let to_move = imagesCopy.splice(key, 1);
                        return [...to_move, ...imagesCopy];
                      });
                    }}
                  />
                </div>
              )
          )}
      </div>
      <input
        onChange={imagesUploadInput}
        type="file"
        id="form-images"
        accept="image/png, image/jpeg"
        multiple={multipleImages}
        hidden
      />
      {images.length < 10 && (
        <div className="flex items-center gap-4">
          {!isLoading ? (
            <Button
              className="flex gap-4 min-w-40"
              //   metadata={`${images.length}/10`}
              //   text={String(t("collection.upload_images"))}
              onClick={(e) => {
                e.preventDefault();
                let index;
                if (logo) {
                  index = 2;
                } else {
                  index = saveBtn ? 1 : 0;
                }
                document
                  .querySelectorAll<HTMLElement>("#form-images")
                  [index].click();
              }}
              disabled={multipleImages && coverImage.length == 0 ? true : false}
            >
              <Upload />{" "}
              {t(
                multipleImages
                  ? "collection.select_images"
                  : "collection.select_image"
              )}
            </Button>
          ) : (
            <Button disabled className="min-w-40">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("buttons.loading")}
            </Button>
          )}
        </div>
      )}
      {/* )} */}
    </section>
  );
}
