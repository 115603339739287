import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { PAGE_DIM } from "../../utils/generic";
// import Button from "./Button";
import { Button } from "@/components/ui/button";

// import previous from "../../assets/icons/cleft.svg";
// import next from "../../assets/icons/cright.svg";

// import doubleprev from "../../assets/icons/double-left-chevron.svg";
import doublenext from "../../assets/icons/double-right-chevron.svg";
// import Select from "./Select";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";

interface iPaginationProps {
  page: number;
  size: number;
  total: number;
  onSizeChange: Function;
  onPageChange: Function;
}

function PaginationMod({
  total,
  size,
  onSizeChange,
  page,
  onPageChange,
}: iPaginationProps) {
  const { t } = useTranslation();

  const [_page, _setPage] = useState(page);
  const [_size, _setSize] = useState(size);

  const [itemRange, setItemRange] = useState("");

  const numberOfPages = Math.ceil(total / Number(size));

  const sizeRange: Array<any> = [10, 25, 50, 100, 250];

  const handlePrevious = () => {
    if (page <= 1) return;
    onPageChange(page - 1);
  };

  const handleNext = () => {
    if (page >= numberOfPages) return;
    onPageChange(page + 1);
  };

  const handleFirst = () => {
    if (page <= 1) return;
    onPageChange(1);
  };

  const handleLast = () => {
    if (page === numberOfPages) return;
    onPageChange(numberOfPages);
  };

  const handlePageNumber = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = parseInt((e.target as HTMLInputElement).value);
      if (value < 1 || value > numberOfPages) return;
      onPageChange(value || 1);
    }
  };

  const handlePageInputChange = (e: any) => {
    const newInputValue = e.target.value;
    if (
      !isNaN(newInputValue) &&
      newInputValue !== "0" &&
      newInputValue <= numberOfPages
    )
      _setPage(newInputValue);
  };

  const updateItemRange = (currentPage: number, pageSize: number) => {
    // logs.slice(page * pageSize, page * pageSize + pageSize)
    const startItem = (currentPage - 1) * pageSize + 1;
    // const endItem = Math.min(currentPage * pageSize, total);
    const endItem = (currentPage - 1) * pageSize + pageSize;
    setItemRange(`${startItem} - ${endItem}`);
  };

  useEffect(() => {
    _setPage(page);
    _setSize(size);
    updateItemRange(page, Number(size));
  }, [page, size, total]);

  return (
    <div className="flex w-full">
      <div className="flex justify-between w-full items-center mt-8">
        <div className="flex gap-4">
          <span>{t("pagination.items_per_page")}</span>
          <div className="w-max">
            {/* <Select
              // small
              defaultValue={String(_size)}
              value={String(_size)}
              onValueChange={(e: any) => {
                onSizeChange(e.target.value);
                onPageChange(1);
              }}
              options={sizeRange.map((sr) => {
                return { value: sr, text: sr };
              })}
            /> */}

            <Select
              // disabled={disabled}
              // defaultValue={defaultValue}
              defaultValue={_size as unknown as string}
              value={_size as unknown as string}
              onValueChange={(value: any) => {
                onSizeChange(value);
                // _setSize(value);
                onPageChange(1);
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t("team.roles")}</SelectLabel>

                  {sizeRange.map((size, index) => (
                    <SelectItem key={"size" + index} value={size}>
                      {size}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="flex gap-2">
          <span>{itemRange}</span>
          <span>{t("pagination.of")}</span>
          <span>{total}</span>
          <span>{t("pagination.items")}</span>
        </div>
        <div className="flex justify-between gap-2">
          <Button
            type="button"
            onClick={() => {
              handleFirst();
            }}
            // icon={doubleprev}
            // toggle
            className={`double ${page === 1 ? "disabled" : ""}`}
          >
            <ChevronsLeft />
          </Button>

          <Button
            type="button"
            onClick={() => {
              handlePrevious();
            }}
            // icon={previous}
            // toggle
            className={`${page === 1 ? "disabled" : ""}`}
          >
            <ChevronLeft />
          </Button>

          <div className="flex gap-2 items-center">
            <span className="w-fit h-full">
              <input
                type="text"
                id="page-number"
                className="p-0 ml-4 text-center w-12 h-full"
                value={_page}
                min={1}
                max={numberOfPages}
                onInput={(e) => {
                  handlePageInputChange(e);
                }}
                onKeyDown={(e) => {
                  handlePageNumber(e);
                }}
              />
            </span>
            {/* <div>
              {t("pagination.of")} {numberOfPages}
            </div> */}
            <span className="pr-8">
              {t("pagination.of")} {numberOfPages}
            </span>
          </div>

          <Button
            type="button"
            onClick={() => {
              handleNext();
            }}
            // icon={next}
            // toggle
            className={`${page >= numberOfPages ? "disabled" : ""}`}
          >
            <ChevronRight />
          </Button>

          <Button
            type="button"
            onClick={() => {
              handleLast();
            }}
            // icon={doublenext}
            // toggle
            className={`double ${page >= numberOfPages ? "disabled" : ""}`}
          >
            <ChevronsRight />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PaginationMod;
