import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  getAddressComponents,
  getGmt,
  getPlaceCoordinates,
} from "@/utils/generic";

interface Props {
  defaultValue?: any;
  onSelect?: Function;
}

export default function MapsAutocomplete({ onSelect, defaultValue }: Props) {
  const [value, setValue] = useState<any>();

  const getAddressComp = async (e: any) => {
    let data: any = {};
    let coords: any = {};
    let gmt: any;

    if (!e) return;

    try {
      data = await getAddressComponents(e.value.place_id);
    } catch (error) {
      console.log(error);
    }

    try {
      coords = await getPlaceCoordinates(e.value.place_id);
    } catch (error) {
      console.log(error);
    }

    try {
      gmt = await getGmt(e.value.place_id);
    } catch (error) {
      console.log(error);
    }
    try {
      coords = await getPlaceCoordinates(e.value.place_id);
    } catch (error) {
      console.log(error);
    }

    onSelect?.({
      ...e,
      address_components: { ...data },
      coordinates: { ...coords },
      gmt,
    });
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <GooglePlacesAutocomplete
      selectProps={{
        isClearable: true,
        value,
        onChange: (e: any) => {
          setValue(e);
          getAddressComp(e);
        },
      }}
      apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
    />
  );
}
