import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { iCollection, patchCollection } from "@/services/collection";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import { LargeText } from "../typography/Typography";
import { useTranslation } from "react-i18next";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";

const formSchema = z.object({
  feeOnCreation: z.number(),
  feeMonthly: z.number(),
  feeFixedOnCreation: z.number(),
  bonusCredits: z.number(),
});

export default function FormSettingsFees({
  collection,
  onRefresh,
}: {
  collection: iCollection;
  onRefresh(): void;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    form.reset({
      feeOnCreation: collection.feeOnCreation ?? 0,
      feeMonthly: collection.feeMonthly ?? 0,
      feeFixedOnCreation: collection.feeFixedOnCreation ?? 0,
      bonusCredits: collection.bonusCredits ?? 0,
    });
  }, [collection]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsSubmitting(true);

    try {
      await patchCollection({
        collectionId: collection._id,
        feeOnCreation: data.feeOnCreation,
        feeMonthly: data.feeMonthly,
        feeFixedOnCreation: data.feeFixedOnCreation,
        isActive: collection.isActive,
        isFree: collection.isFree,
        scriptEnabled: collection.scriptEnabled,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div>
          <LargeText>{t("collection.fees")}</LargeText>
          <p className="text-sm text-muted-foreground">
            {t("collection.fees_description")}
          </p>
        </div>
        <div className="space-y-3">
          <FormField
            control={form.control}
            name="feeOnCreation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("collection.feeOnCreation")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("collection.feeOnCreation_placeholder")}
                    type={"number"}
                    step={0.0001}
                    min={0}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    value={field.value}
                    // {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("collection.feeOnCreation_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="feeMonthly"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("collection.feeMonthly")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("collection.feeMonthly_placeholder")}
                    type={"number"}
                    step={0.01}
                    min={0}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    value={field.value}
                    // {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("collection.feeMonthly_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="feeFixedOnCreation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("collection.feeFixedOnCreation")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("collection.feeFixedOnCreation_placeholder")}
                    type={"number"}
                    step={0.01}
                    min={0}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    value={field.value}
                    // {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("collection.feeFixedOnCreation_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bonusCredits"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("collection.bonusCredits")}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t("collection.bonusCredits_placeholder")}
                    type={"number"}
                    step={1}
                    min={0}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    value={field.value}
                    // {...field}
                  />
                </FormControl>
                <FormDescription>
                  {t("collection.bonusCredits_description")}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row gap-4">
          <LoadingSubmitButton
            className="ml-auto"
            variant="takyon"
            type="submit"
            isLoading={isSubmitting}
          >
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
