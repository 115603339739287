import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { PAGE_DIM } from "@/utils/generic";
import {
  deleteDocument,
  getDocumentsPaginated,
  iDocument,
} from "@/services/documents";
import { Button } from "@/components/ui/button";
import CreateDocModal from "./forms/CreateDocModal";
import Document from "./forms/Document";
import { useModal } from "@/context/ModalContext";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { LucideEdit, Trash2 } from "lucide-react";
import DeleteModal from "@/components/ui/deleteModal";

export default function Documents() {
  const { t } = useTranslation();
  // set page title
  const { setTitle } = useSectionTitle();
  useEffect(() => {
    setTitle(`${t("documents.doc_title")}`);
  }, [setTitle]);

  const [, setIsLoading] = useState(false);

  const [, setTotal] = useState<number>(0);
  const [page] = useState(1);
  const [size] = useState<number>(PAGE_DIM);

  const [documents, setDocuments] = useState<iDocument[]>([]);
  const [isDetailsShowed, setIsDetailsShowed] = useState<
    iDocument | undefined
  >();
  const [isEditShowed, setIsEditShowed] = useState<iDocument | undefined>();
  const { showModal } = useModal();

  const loadDocs = async () => {
    setIsLoading(true);
    try {
      const data = await getDocumentsPaginated({ page, size });
      setDocuments(data.data.reverse());

      setTotal(data.total || 0);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadDocs();
    // eslint-disable-next-line
  }, []);

  const docsByLanguage = useMemo(() => {
    const groupedDocs: { [key: string]: iDocument[] } = {};
    documents.forEach((doc) => {
      const language = doc.lang;
      if (!groupedDocs[language]) {
        groupedDocs[language] = [];
      }
      groupedDocs[language].push(doc);
    });

    return groupedDocs;
  }, [documents]);

  if (isDetailsShowed)
    return (
      <div className="content">
        <Document
          onExit={() => setIsDetailsShowed(undefined)}
          doc={isDetailsShowed}
        />
      </div>
    );

  if (isEditShowed)
    return (
      <div className="content">
        <CreateDocModal
          onSuccess={() => {
            setIsEditShowed(undefined);
            loadDocs();
          }}
          doc={isEditShowed}
          onExit={() => setIsEditShowed(undefined)}
        />
      </div>
    );

  return (
    <main>
      <section id="documents-section" className="flex flex-col gap-4">
        <Button
          className="w-fit"
          variant="takyon"
          onClick={() =>
            showModal(<CreateDocModal onSuccess={() => loadDocs()} />, {
              title: " ",
              description: " ",
            })
          }
          // text={String(t("documents.create_doc"))}
        >
          {t("documents.create_doc")}
        </Button>

        {Object.keys(docsByLanguage).map((language) => (
          <div key={Math.random()} className="mb-4">
            <h4 className="font-semibold text-2xl mb-2">[{language}]</h4>
            <div>
              {docsByLanguage[language].map((doc: iDocument) => (
                <div
                  key={Math.random()}
                  onClick={() => {
                    setIsDetailsShowed(doc);
                  }}
                  className="support-item documents-item border rounded-md p-2"
                >
                  <div className="doc-name cursor-pointer">
                    <span>{"Document name : "}</span>
                    <span className="italic">{doc.name}</span>
                  </div>

                  <div className="cat-type cursor-pointer">
                    <span>{"Category : "}</span>
                    <span className="italic">{doc.type}</span>
                  </div>

                  <div
                    className="doc-name documents-item max-h-80 text-ellipsis overflow-hidden cursor-pointer"
                    dangerouslySetInnerHTML={{ __html: doc.text }}
                  ></div>
                  <p className="mt-6 font-semibold">
                    Clicca sul testo per visualizzare l'intero contenuto
                  </p>

                  <div className="flex">
                    <Button
                      variant="ghost"
                      title={t("documents.edit_doc")}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setIsEditShowed(doc);
                      }}
                    >
                      <LucideEdit size={16} />
                    </Button>

                    <Button
                      variant="ghost"
                      title={t("documents.delete_doc")}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        showModal(
                          <DeleteModal
                            onRemove={async () => {
                              await deleteDocument({
                                _id: doc._id,
                              });
                              loadDocs();
                            }}
                          />,
                          {
                            title: t("modals.remove_modal"),
                            description: t("modals.remove_modal_text"),
                          }
                        );
                      }}
                    >
                      <Trash2 size={16} />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {documents.length === 0 && <div>{t("support.no_docs")}</div>}
      </section>
    </main>
  );
}
