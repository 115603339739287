import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { t } from "i18next";

export default function EmailOrBlur(email: string) {
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return isValidEmail ? (
    <a
      href="mailto:${email}"
      className="block w-56 text-ellipsis overflow-hidden text-nowrap"
    >
      {email}
    </a>
  ) : (
    <Popover>
      <PopoverTrigger asChild>
        <div className="cursor-pointer blur block w-56 text-ellipsis overflow-hidden text-nowrap">
          {email}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto">
        <p>{t("error.crypted_email")}</p>
      </PopoverContent>
    </Popover>
  );
}
