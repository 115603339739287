import React from "react";
import { Separator } from "@/components/ui/separator";
import { Outlet } from "react-router-dom";
import Header from "@/components/layout/Header";
import Footer from "./Footer";

import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";

import AdminSidebar from "@/components/layout/AdminSidebar";
import { useSectionTitle } from "@/context/SectionTitleContext";

const AdminLayout: React.FC = () => {
  const { title } = useSectionTitle();

  return (
    <>
      <div className="z-20 fixed top-0 left-0 right-0">
        <Header showNavMenu />
      </div>
      <SidebarProvider defaultOpen={true}>
        <AdminSidebar />
        <main className="my-20 w-full px-10">
          <SidebarTrigger />
          <div>
            {title && (
              <div className="grid w-full max-w-6xl gap-2">
                <h1 className="text-3xl font-semibold">{title}</h1>
              </div>
            )}
            <div className="pe-8">
              <Outlet />
            </div>
          </div>
        </main>
      </SidebarProvider>
      <div className="fixed bottom-0 left-0 right-0 z-20 bg-white ">
        <Footer />
      </div>
    </>
  );
};

export default AdminLayout;
