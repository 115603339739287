import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { iCollection, patchCollection } from "@/services/collection";
import { useRbac } from "@/hooks/rbac";
import { iGroup } from "@/services/groups";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingSubmitButton } from "../form_fields/LoadingSubmitButton";
import { Checkbox } from "../ui/checkbox";

const formSchema = z.object({
  isActive: z.boolean(),
  isFree: z.boolean(),
  scriptEnabled: z.boolean(),
});

export default function FormSettingsStatus({
  collection,
  group,
  onRefresh,
}: {
  collection: iCollection;
  group: iGroup;
  onRefresh(): void;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const { t } = useTranslation();
  const { can } = useRbac();
  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log("collection", collection);
  useEffect(() => {
    form.reset({
      isActive: collection.isActive,
      isFree: collection.isFree,
      scriptEnabled: collection.scriptEnabled || false,
    });
  }, [collection]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setIsSubmitting(true);

    try {
      await patchCollection({
        collectionId: collection._id,
        isActive: data.isActive,
        isFree: data.isFree,
        scriptEnabled: data.scriptEnabled,
      });
      onRefresh();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="space-y-3">
          <FormField
            control={form.control}
            name="isActive"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl className="mt-1">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>{t("collection.active")}</FormLabel>
                  <FormDescription>
                    {t("collection.active_description")}
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isFree"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl className="mt-1">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>{t("collection.isFree")}</FormLabel>
                  <FormDescription>
                    {t("collection.isFree_description")}
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="scriptEnabled"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl className="mt-1">
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>{t("collection.scriptEnabled")}</FormLabel>
                  <FormDescription>
                    {t("collection.scriptEnabled_description")}
                  </FormDescription>
                </div>
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-row gap-4">
          <LoadingSubmitButton
            className="ml-auto"
            variant="takyon"
            type="submit"
            isLoading={isSubmitting}
          >
            {t("buttons.save")}
          </LoadingSubmitButton>
        </div>
      </form>
    </Form>
  );
}
