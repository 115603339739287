// FeedbackContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useModal } from "./ModalContext";

interface FeedbackContextProps {
  showFeedback: (feedback: FeedbackData) => void;
  hideFeedback: () => void;
  feedbackData: FeedbackData | null;
  isFeedbackOpen: boolean;
}

interface FeedbackData {
  title?: ReactNode | null;
  message?: ReactNode | null;
  onClose?: () => void;
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(
  undefined
);

export const FeedbackProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [feedbackData, setFeedbackData] = useState<FeedbackData | null>(null);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const { isModalOpen, hideModal } = useModal();

  const showFeedback = (feedbackData: FeedbackData) => {
    if (isModalOpen) {
      hideModal();
    }
    setFeedbackData(feedbackData);
    setIsFeedbackOpen(true);
  };

  const hideFeedback = () => {
    setFeedbackData(null);
    setIsFeedbackOpen(false);
    if (feedbackData?.onClose && isFeedbackOpen) {
      console.log("call custom onClosed callback");
      feedbackData.onClose();
    }
  };

  return (
    <FeedbackContext.Provider
      value={{ showFeedback, hideFeedback, feedbackData, isFeedbackOpen }}
    >
      {children}
      {isFeedbackOpen && (
        <Dialog open={isFeedbackOpen} onOpenChange={hideFeedback}>
          <DialogContent className="w-max">
            {feedbackData && (
              <DialogHeader>
                {feedbackData.title && (
                  <DialogTitle>{feedbackData.title}</DialogTitle>
                )}
                {feedbackData.message && (
                  <DialogDescription>{feedbackData.message}</DialogDescription>
                )}
              </DialogHeader>
            )}
          </DialogContent>
        </Dialog>
      )}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error("useFeedback must be used within a FeedbackProvider");
  }
  return context;
};
