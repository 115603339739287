import { Button } from "@/components/ui/button";
import iDataAnalysisMask, {
  // createAnalysisMask,
  getAnalysisMaskPaginate,
} from "@/services/dataanalysismask";
import { useEffect, useState } from "react";
import DataAnalysisMaskCard from "./DataAnalysisMaskCard";
import { useModal } from "@/context/ModalContext";
import DataAnalysisCreateModal from "./DataAnalysisCreateModal";
import DataAnalysisEditor from "./DataAnalysisEditor";
import { useSectionTitle } from "@/context/SectionTitleContext";

export default function DataAnalysisMasks() {
  const [masks, setMasks] = useState<iDataAnalysisMask[]>([]);
  const { showModal, hideModal } = useModal();
  const [maskData, setMaskData] = useState<iDataAnalysisMask>({
    _id: "",
    _updatedAt: "",
    name: "",
    modules: [],
  });
  const { setTitle } = useSectionTitle();
  const load = async () => {
    try {
      const data = await getAnalysisMaskPaginate({ page: 1, size: 500 });
      setMasks(data.data);
    } catch (error) {
      console.log("error fetching masks", error);
    }
  };

  // const create = async () => {
  //   try {
  //     await createAnalysisMask({});
  //     load();
  //   } catch (error) {
  //     console.log("error creating mask", error);
  //   }
  // };

  useEffect(() => {
    load();
    setTitle("");
  }, []);

  useEffect(() => {
    if (!maskData || Object.keys(maskData).length == 0) {
      window.scrollTo(0, 0);
      setTitle("");
    }
  }, [maskData]);

  return (
    <div id="DataAnalysisMasks" className="relative">
      <Button
        variant="takyon"
        className="absolute right-0 -mt-12"
        onClick={() =>
          showModal(
            <DataAnalysisCreateModal hideModal={hideModal} load={load} />,
            {
              title: `Aggiungi nuova maschera`,
              description: "",
            }
          )
        }
      >
        Create Mask
      </Button>

      <div className="flex flex-col gap-4 mt-8">
        {!maskData._id && masks ? (
          masks.map((mask, index) => {
            return (
              <div className="border rounded-md py-4 px-8" key={"mask" + index}>
                <DataAnalysisMaskCard
                  mask={mask}
                  load={load}
                  setMaskData={setMaskData}
                />
              </div>
            );
          })
        ) : (
          <DataAnalysisEditor setMaskData={setMaskData} maskData={maskData} />
        )}
      </div>
    </div>
  );
}
