import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import InfoStepper from "./InfoStepper";
import CollectionTeamOnboarding from "./onboarding/CollectionTeamOnboarding";
import Details from "./onboarding/Details";
import Prices from "./onboarding/Prices";
import CollectionTabsWrapper from "@/components/collection/CollectionTabsWrapper";
import {
  iCollection,
  getCollection,
  onBoardingCompleted,
} from "@/services/collection";
import { iGroup, getGroup } from "@/services/groups";
import { useState, useEffect } from "react";
import Images from "./onboarding/Images";
import CompletionMessage from "./onboarding/CompletionMessage";
import PaymentMethodsWrapper from "./onboarding/PaymentMethodsWrapper";
import getLink, { LINK_TYPE } from "@/lib/links";
import { Skeleton } from "@/components/ui/skeleton";
import ErrorPage from "@/components/errors/ErrorPage";
import { t } from "i18next";
import { useSectionTitle } from "@/context/SectionTitleContext";
import { useRbac } from "@/hooks/rbac";

const isOnboardingRoute = (pathname: string) => {
  return pathname.includes("infos");
};
export interface CollectionPageChildProps {
  collection?: iCollection;
  group?: iGroup;
  loading: boolean;
  onRefresh(): Promise<void>;
}

export default function CollectionPage() {
  const { collectionId } = useParams();

  const [collection, setCollection] = useState<iCollection>();

  const [group, setGroup] = useState<iGroup>();

  const { isSuperAdmin } = useRbac();

  const [isLoading, setIsLoading] = useState(true);

  const { setLogoHomeLink, homeLink } = useSectionTitle();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const load = async () => {
    if (!collectionId) return;
    setIsLoading(true);
    try {
      const [c, g] = await Promise.all([
        getCollection(collectionId),
        getCollection(collectionId).then((c) => getGroup(c.groupId)),
      ]);

      setCollection(c);
      setGroup(g);
    } catch (error) {
      console.log("collection not found - redirect to homepage");
      navigate(getLink(LINK_TYPE.HOME));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // REDIRECT FOR ONBOARDING INCOMPLETE
    if (!isSuperAdmin && collection && !isOnboardingRoute(pathname)) {
      const onboardingComplete = onBoardingCompleted(collection);
      //const canUpdate = can(COLLECTION_PERMISSIONS.update, collection);
      console.log("onboardingComplete", onboardingComplete);
      if (!onboardingComplete) {
        const redirectTo = getLink(LINK_TYPE.HOME);
        console.log(
          "CollectionPage redirecting for onboarding incomplete",
          redirectTo
        );
        navigate(redirectTo);
        return;
      }
    }
  }, [collection]);

  useEffect(() => {
    if (isSuperAdmin && collection) {
      // set logo home link to current collection group page
      setLogoHomeLink(`/group/${collection.groupId}`);
    }
  }, [collection, isSuperAdmin]);

  useEffect(() => {
    load();
  }, [collectionId]);

  if (isLoading)
    return <Skeleton className="bg-gray-200 h-[32px] rounded-lg" />;

  if (!collection || !group)
    return <ErrorPage message={t("error.not_found")} />;

  return (
    <Routes>
      <Route path="infos" element={<InfoStepper loading={isLoading} />}>
        <Route
          path="details"
          element={
            <Details
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="prices"
          element={
            <Prices
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="images"
          element={
            <Images
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="team"
          element={
            <CollectionTeamOnboarding
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        <Route
          path="payments"
          element={
            <PaymentMethodsWrapper
              collection={collection!}
              // group={group}
              nextPage={`/collection/${collection?._id}/infos/complete`}
              loading={isLoading}
              onRefresh={load}
            />
          }
        />
        <Route
          path="complete"
          element={
            <CompletionMessage
              collection={collection}
              group={group}
              onRefresh={load}
              loading={isLoading}
            />
          }
        />
        {/* <Route path="stats" element={<HotelStats />} /> */}
        {/* <Route path="payments" element={<PaymentMethods />} /> */}
      </Route>
      <Route
        path="*"
        element={
          <CollectionTabsWrapper
            collection={collection}
            group={group}
            onRefresh={load}
            loading={isLoading}
          />
        }
      ></Route>
    </Routes>
  );
}
