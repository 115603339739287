import { ReactNode, HTMLAttributes } from "react";

// H4 Component
export function H4({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { children: ReactNode }) {
  return (
    <h4
      {...props}
      className={`scroll-m-20 text-xl font-semibold tracking-tight ${props.className}`}
    >
      {children}
    </h4>
  );
}

// H3 Component
export function H3({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { children: ReactNode }) {
  return (
    <h3
      {...props}
      className={`scroll-m-20 text-2xl font-semibold tracking-tight ${props.className}`}
    >
      {children}
    </h3>
  );
}

// H2 Component
export function H2({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { children: ReactNode }) {
  return (
    <h3
      {...props}
      className={`scroll-m-20 text-3xl font-semibold tracking-tight ${props.className}`}
    >
      {children}
    </h3>
  );
}

// H1 Component
export function H1({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { children: ReactNode }) {
  return (
    <h1
      {...props}
      className={`scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl ${props.className}`}
    >
      {children}
    </h1>
  );
}

// Large Component
export function LargeText({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & { children: ReactNode }) {
  return (
    <div {...props} className={`text-lg font-semibold ${props.className}`}>
      {children}
    </div>
  );
}
